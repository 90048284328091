import { t } from "i18next";

const dbBoardNotice = {
  provider_type: {
    P: {
      label: t("eventBoard.active"),
      value: "P",
      key: "P",
    },
    C: {
      label: t("eventBoard.inactive"),
      value: "C",
      key: "C",
    },
  },
  type: {
    O: {
      label: t("eventBoard.post_always"),
      value: "O",
      key: "O",
    },
    A: {
      label: t("eventBoard.during_period"),
      value: "A",
      key: "A",
    },
    C: {
      label: t("eventBoard.end_always"),
      value: "C",
      key: "C",
    },
  },
  search_date_state: {
    O: {
      label: t("eventBoard.start_date"),
      value: "O",
      key: 1,
    },
    C: {
      label: t("eventBoard.end_date"),
      value: "C",
      key: 2,
    },
  },
};

export { dbBoardNotice };
