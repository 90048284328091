import { useEffect } from "react";
import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { EachCheckForm, EachCheckFormCustom, InputForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import api from "interceptor/api";
import { Column, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";

import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, TextBox } from "style/useTextStyles";
import { MainBtn } from "style/useButtonStyles";
import { usePopup } from "contexts/useWindow";
import { t } from "i18next";
import { checkboxYNEnum } from "enum/useCommonEnum";

const UserCommission = ({ userNo }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();
  const { closePopup } = usePopup();

  useEffect(() => {
    getTableItem();
  }, []);

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = `/v1/user/all/info/commission/${userNo}`;

    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
      },
    })
      .then((res) => {
        useSearch.setFormValue((prev) => ({
          ...prev,
          ...res.data.content,
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const putCommissionInfo = () => {
    setLoading(true);

    const apiType = api.put;
    const apiUrl = `/v1/user/all/info/commission/${userNo}`;

    apiType(apiUrl, {
      ...useSearch.formValue,
    })
      .then((res) => {
        setLoading(false);
        closePopup();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useSearch = useFormData({
    rolling_all_yn: "N",
    rolling_yn: "N",
    recommend_rolling_yn: "N",
    losing_all_yn: "N",
    losing_yn: "N",
    recommend_losing_yn: "N",
  });

  useEffect(() => {
    if (useSearch.formValue?.rolling_all_yn === "Y") {
      // Only update if needed to prevent unnecessary re-renders
      if (useSearch.formValue?.rolling_yn !== "Y" || useSearch.formValue?.recommend_rolling_yn !== "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          rolling_yn: "Y",
          recommend_rolling_yn: "Y",
          rolling_none_yn: "N",
        }));
      }
    } else {
      // Avoid setting all to "N" to prevent infinite cycles
      if (useSearch.formValue?.rolling_yn === "Y" && useSearch.formValue?.recommend_rolling_yn === "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          rolling_yn: "N",
          recommend_rolling_yn: "N",
          rolling_none_yn: "Y",
        }));
      }
    }
  }, [useSearch.formValue?.rolling_all_yn]);

  useEffect(() => {
    const rollingYn = useSearch.formValue?.rolling_yn === "Y";
    const recommendRollingYn = useSearch.formValue?.recommend_rolling_yn === "Y";

    if (rollingYn && recommendRollingYn) {
      if (useSearch.formValue?.rolling_all_yn !== "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          rolling_all_yn: "Y",
        }));
      }
    } else {
      if (useSearch.formValue?.rolling_all_yn === "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          rolling_all_yn: "N",
        }));
      }
    }
  }, [useSearch.formValue?.rolling_yn, useSearch.formValue?.recommend_rolling_yn]);

  useEffect(() => {
    if (useSearch.formValue?.losing_all_yn === "Y") {
      // Only update if needed to prevent unnecessary re-renders
      if (useSearch.formValue?.losing_yn !== "Y" || useSearch.formValue?.recommend_losing_yn !== "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          losing_yn: "Y",
          recommend_losing_yn: "Y",
          losing_none_yn: "N",
        }));
      }
    } else {
      // Avoid setting all to "N" to prevent infinite cycles
      if (useSearch.formValue?.losing_yn === "Y" && useSearch.formValue?.recommend_losing_yn === "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          losing_yn: "N",
          recommend_losing_yn: "N",
          losing_none_yn: "Y",
        }));
      }
    }
  }, [useSearch.formValue?.losing_all_yn]);

  useEffect(() => {
    const losingYn = useSearch.formValue?.losing_yn === "Y";
    const recommendLosingYn = useSearch.formValue?.recommend_losing_yn === "Y";

    if (losingYn && recommendLosingYn) {
      if (useSearch.formValue?.losing_all_yn !== "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          losing_all_yn: "Y",
        }));
      }
    } else {
      if (useSearch.formValue?.losing_all_yn === "Y") {
        useSearch.setFormValue((prev) => ({
          ...prev,
          losing_all_yn: "N",
        }));
      }
    }
  }, [useSearch.formValue?.losing_yn, useSearch.formValue?.recommend_losing_yn]);

  return (
    <>
      <Column className="area-box" $width="1200px">
        <SubTitleBox>
          <Row $gap="6px" $align="center">
            <AreaTitle>{t("userCommission.rolling_comp")}</AreaTitle>
          </Row>
        </SubTitleBox>

        <OptionBox>
          <Column $gap="12px" $width="100%">
            <Row $gap="24px" $align="center">
              <EachCheckForm
                labelFor="rolling_all_yn"
                name="rolling_all_yn"
                options={checkboxYNEnum}
                labelName={t("userCommission.all_yn_label")}
                {...useSearch}
              />
              <EachCheckForm
                labelFor="rolling_yn"
                name="rolling_yn"
                options={checkboxYNEnum}
                labelName={t("userCommission.user_comp_yn_label")}
                {...useSearch}
              />
              <EachCheckForm
                labelFor="recommend_rolling_yn"
                name="recommend_rolling_yn"
                options={checkboxYNEnum}
                labelName={t("userCommission.recommend_comp_yn_label")}
                {...useSearch}
              />
            </Row>
            <Table className="table-box">
              <thead>
                <TableRow className="table-title">
                  <TableHeader $width="100px">{t("userCommission.comp_type")}</TableHeader>
                  <TableHeader $width="230px">{t("common.casino_rolling_rate")}</TableHeader>
                  <TableHeader $width="230px">{t("common.slot_rolling_rate")}</TableHeader>
                  <TableHeader $width="230px">{t("common.mini_game_rolling_rate")}</TableHeader>
                  <TableHeader $width="230px">{t("common.sports_rolling_rate")}</TableHeader>
                </TableRow>
              </thead>
              <tbody>
                <TableRow className="table-content">
                  <TableDeps $height="42px" $tAlign="center">
                    {t("userCommission.member_comp")}
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_casino_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_slot_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_mini_game_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_sports_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                </TableRow>
                <TableRow className="table-content">
                  <TableDeps $height="42px" $tAlign="center">
                    {t("userCommission.recommend_comp")}
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_casino_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_slot_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_mini_game_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_sports_rolling_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                </TableRow>
              </tbody>
            </Table>
          </Column>
        </OptionBox>
        <SubTitleBox>
          <Row $gap="6px" $align="center">
            <AreaTitle>{t("userCommission.losing_comp")}</AreaTitle>
          </Row>
        </SubTitleBox>
        <OptionBox>
          <Column $gap="12px" $width="100%">
            <Row $gap="24px" $align="center">
              <EachCheckForm
                labelFor="losing_all_yn"
                name="losing_all_yn"
                options={checkboxYNEnum}
                labelName={t("userCommission.all_yn_label")}
                {...useSearch}
              />
              <EachCheckForm
                labelFor="losing_yn"
                name="losing_yn"
                options={checkboxYNEnum}
                labelName={t("userCommission.user_comp_yn_label")}
                {...useSearch}
              />
              <EachCheckForm
                labelFor="recommend_losing_yn"
                name="recommend_losing_yn"
                options={checkboxYNEnum}
                labelName={t("userCommission.recommend_comp_yn_label")}
                {...useSearch}
              />
            </Row>
            <Table className="table-box">
              <thead>
                <TableRow className="table-title">
                  <TableHeader $width="100px">{t("userCommission.comp_type")}</TableHeader>
                  <TableHeader $width="230px">{t("common.casino_losing_rate")}</TableHeader>
                  <TableHeader $width="230px">{t("common.slot_losing_rate")}</TableHeader>
                  <TableHeader $width="230px">{t("common.mini_game_losing_rate")}</TableHeader>
                  <TableHeader $width="230px">{t("common.sports_losing_rate")}</TableHeader>
                </TableRow>
              </thead>
              <tbody>
                <TableRow className="table-content">
                  <TableDeps $height="42px" $tAlign="center">
                    {t("userCommission.member_comp")}
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_casino_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_slot_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_mini_game_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_sports_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                </TableRow>
                <TableRow className="table-content">
                  <TableDeps $height="42px" $tAlign="center">
                    {t("userCommission.recommend_comp")}
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_casino_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_slot_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_mini_game_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                  <TableDeps $pad="0px 12px" $height="42px">
                    <InputForm
                      maxWidth="240px"
                      type="text"
                      name="site_recommend_sports_losing_prop"
                      height={"28px"}
                      placeholder="0%"
                      {...useSearch}
                      readOnly
                    />
                  </TableDeps>
                </TableRow>
              </tbody>
            </Table>
          </Column>
        </OptionBox>
        <Row $pad="12px 0 12px 0" $gap="6px" $align="center" $jus="flex-end">
          <MainBtn $pad="6px 12px" onClick={putCommissionInfo}>
            {t("common.check")}
          </MainBtn>
        </Row>
      </Column>
    </>
  );
};

export default UserCommission;
