"use client";

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "interceptor/api";
import useStyle from "style/useStyle";
import { IconLink } from "style/useLayoutStyles";

const WriteBtn = styled.button`
  color: #2e81ff;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);
  border-radius: 4px;
  padding: 0 12px;
  height: 40px;
  font-size: var(--s-caption);
  white-space: nowrap;
`;

const SelectBtn = styled.button`
  position: relative;

  box-sizing: border-box;
  border: 1px solid #e5e5e5;

  width: 100%;
  max-width: 120px;
  padding: 0 8px;
  height: 40px;
  border-radius: 4px;
`;

const ExchangePage = () => {
  const { Table, FlexBox, TextBox, TableDeps, TableHeader, InputTextBox, BorderTextBox } = useStyle();
  const [toggle, setToggle] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [currencyUnit, setCurrencyUnit] = useState(0);
  const [exchangeResult, setExchangeResult] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [currencyResults, setCurrencyResults] = useState([]);

  const toggleDropdown = () => {
    setToggle(!toggle);
  };

  const inputCurrencyUnit = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setCurrencyUnit(Number(inputValue));
    }
  };

  const setCurrencyCode = (currency) => {
    setSelectedCurrency(currency);
    setToggle(false);
  };

  const calculatePotAmount = () => {
    if (!selectedCurrency) {
      alert("화폐 종류를 입력해주세요.");
      return;
    }

    if (!currencyUnit) {
      alert("화폐 단위를 입력해주세요.");
      return;
    }

    exchangeRateCalculate(currencyUnit, selectedCurrency);
  };

  const exchangeRateCalculate = (currencyUnit, selectedCurrency) => {
    api
      .get(`/v1/api/exchange-rate/amount/${currencyUnit}/currencyCode/${selectedCurrency.country}`)

      .then((response) => {
        setExchangeResult(response.data);
      })

      .catch((error) => {
        alert("Pot 수 계산에 실패하였습니다.");
        console.error(error);
      });
  };

  useEffect(() => {
    const standardPotUrl = "/v1/api/exchange-rate/amount/1/currencyCode/USD";

    api
      .get(standardPotUrl)
      .then((res) => {
        const calculatedResult = res.data;
        setResultMessage(`1 USD = Pot ${calculatedResult} `);
      })
      .catch((error) => {
        alert("Pot 수 계산에 실패하였습니다.");
      });

    const getCurrencyUrl = "/v1/api/exchange-rate";

    api
      .get(getCurrencyUrl)
      .then((res) => {
        setCurrencyResults(res.data);
      })
      .catch((error) => {
        alert("현재 환율 정보들을 조회하는데 실패하였습니다.");
      });
  }, []);

  useEffect(() => {
    setCurrencyUnit("");
    setExchangeResult("");
  }, [selectedCurrency]);

  return (
    <>
      <FlexBox $gap='4px' $align='center'>
        <TextBox>교환 비율 입력 :</TextBox>
        <TextBox>{resultMessage}</TextBox>
      </FlexBox>
      <FlexBox $pad='12px 0' $align='center' $jus='space-between'>
        <FlexBox $gap='12px'>
          <TextBox $maxWidth='240px'>화폐 종류(예시 : USD)</TextBox>
          <TextBox $maxWidth='240px'>화폐 단위(예시 : 1, 100)</TextBox>
          <TextBox $maxWidth='240px'>Pot 수</TextBox>
        </FlexBox>
        <FlexBox $gap='12px'>
          <SelectBtn>
            <FlexBox $align='center' $jus='space-between' onClick={toggleDropdown}>
              <TextBox $size='var(--s-caption)' $font='var(--f-caption)'>
                {selectedCurrency ? selectedCurrency.name : "화폐 종류 선택"}
              </TextBox>
              <IconLink $url='/assets/svgfile/drop_icon.svg' $width='20px' $height='20px'></IconLink>
            </FlexBox>
            {/* TODO: 고쳐야함. */}
            {/* {toggle && (
                  <DropMenuBox>
                    {CurrencyCodeArray.map((currency) => (
                      <DropMenuList
                        key={currency.id}
                        onClick={() => setCurrencyCode(currency)}
                      >
                        <TextBox>{currency.name}</TextBox>
                      </DropMenuList>
                    ))}
                  </DropMenuBox>
                )} */}
          </SelectBtn>
          <InputTextBox
            $maxWidth='120px'
            $height='40px'
            value={currencyUnit}
            onChange={inputCurrencyUnit}
            placeholder='값을 입력해 주세요.'
          />

          <BorderTextBox $width='160px' $pad='10px 0'>
            {exchangeResult ? exchangeResult : "값이 출력됩니다."}
          </BorderTextBox>

          <WriteBtn onClick={() => calculatePotAmount(currencyUnit, selectedCurrency.value)}>
            Pot 계산
          </WriteBtn>
        </FlexBox>
      </FlexBox>
      <Table className='table-box'>
        <thead>
          <tr className='table-title'>
            <TableHeader>화폐 종류(예시 : USD)</TableHeader>
            <TableHeader>화폐 단위(예시 : 1, 100)</TableHeader>
            <TableHeader>현재 환율</TableHeader>
            <TableHeader>Pot 수</TableHeader>
          </tr>
        </thead>
        <tbody>
          {currencyResults.map((currency, currencyCode) => (
            <tr key={currencyCode} className='table-content'>
              <TableDeps>{currency.currencyCode}</TableDeps>
              <TableDeps>{currency.currencyUnit}</TableDeps>
              <TableDeps>{currency.basePrice}</TableDeps>
              <TableDeps>{currency.totalPot}</TableDeps>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ExchangePage;
