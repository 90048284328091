import api from "interceptor/api";
import { useEffect } from "react";

/**
 * alarm_check_yn 값 Y 로 변경
 * @param {*} pageName
 */
export function useAlarmCheck(pageName) {
  useEffect(() => {
    api
      .patch(`/v1/site/header/alarm-check/${pageName}`)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }, []);
}
