import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "interceptor/api";
import { useNavigate } from "react-router";
import useModal from "components/common/useModal";
import useToast from "components/common/useToast";
import useStyle from "style/useStyle";
import { SelectForm } from "components/common/CommonForm";
import useFormData from "common/useFormData";

const SubText = styled.p`
  padding-bottom: 12px;

  color: #1c1c1c;
`;

const InfoInsertBox = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 8px;
`;

const InfoInsertList = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const InfoInsertTitle = styled.p`
  width: 80px;

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  line-height: var(--l-caption);

  color: var(--c-black-op);
  white-space: nowrap;
`;

const CheckBtn = styled.button`
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  height: fit-content;
  border: 1px solid #aaa;

  font-family: var(--f-caption);
`;

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 280px;
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid #aaa;
`;

const userBlockSearchTypeEnum = {
  PHONE_NUMBER: "PHONE_NUMBER",
  ACCOUNT_NUMBER: "ACCOUNT_NUMBER",
};

const UserRegistration = () => {
  const { TextBox, FlexBox, MainBtn, PositionBox, InputTextBox } = useStyle();
  const TOAST_MSG = "계좌번호 및 휴대폰을 등록가능한지 체크하시거나 빈값을 입력해주세요.";

  const [userNo, setUserNo] = useState("");
  const [userPw, setUserPw] = useState("");
  const [userNickname, setUserNickname] = useState("");
  const [userAccountName, setUserAccountName] = useState("");
  const [userBank, setUserBank] = useState("");
  const [userAccountNum, setUserAccountNum] = useState("");
  const [userPhoneNum, setUserPhoneNum] = useState("");
  const [userExchangePw, setUserExchangePw] = useState("");
  const [userExchangePwCheck, setUserExchangePwCheck] = useState("");
  const [userState1, setUserState1] = useState("");
  const [userState2, setUserState2] = useState("");
  const [isPhoneNumRegisterable, setIsPhoneNumRegisterable] = useState(false);
  const [isAccountNumRegisterable, setIsAccountNumRegisterable] = useState(false);
  const [gameLaunchUsername, setGameLaunchUsername] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [gameList, setGameList] = useState([]);

  const navigate = useNavigate();

  const { Modal, isOpen, openModal, closeModal, modalData } = useModal();
  const { Toast, toastOpen, openToast } = useToast();

  function replaceEmptyWithNull(value) {
    return value === "" ? null : value;
  }

  const gameLaunchLinkApiUrl = "v1/game/game-launch-link";

  const useSearch = useFormData({
    vendor: "",
    game: "",
  });

  useEffect(() => {
    const getVendorApi = "/v1/game/vendor";
    api
      .get(getVendorApi, {})
      .then((res) => {
        setVendorList(
          res.data.map((vendor, index) => {
            return {
              label: vendor.name,
              value: vendor.name,
              key: index + 1,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getGameList(useSearch.formValue.vendor);
  }, [useSearch.formValue.vendor]);

  const getGameList = (vendor) => {
    console.log("vendor: " + vendor);
    if (vendor != "" || vendor) {
      const getVendorApi = "/v1/game/game-list";
      api
        .get(getVendorApi, {
          params: {
            vendor: vendor,
          },
        })
        .then((res) => {
          console.log(res.data);
          setGameList(
            res.data.map((data, index) => {
              return {
                label: data.title,
                value: data.id,
                key: index + 1,
              };
            })
          );
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const gameLaunchLink = () => {
    const gameLaunchLinkApi = api.get(gameLaunchLinkApiUrl, {
      params: {
        username: gameLaunchUsername,
        gameId: useSearch.formValue.game,
        vendor: useSearch.formValue.vendor,
      },
    });

    gameLaunchLinkApi
      .then((res) => {
        console.log("link: " + res.data.link);
        window.open(res.data.link, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const submitUserRegistration = () => {
    if (
      (isPhoneNumRegisterable === false && userPhoneNum !== "") ||
      (isAccountNumRegisterable === false && userAccountNum !== "")
    ) {
      openToast();
      return;
    }
    const apiUrl = "/v1/api/users/user";

    api
      .post(apiUrl, {
        id: replaceEmptyWithNull(userNo),
        password: replaceEmptyWithNull(userPw),
        nickname: replaceEmptyWithNull(userNickname),
        accountName: replaceEmptyWithNull(userAccountName),
        bank: replaceEmptyWithNull(userBank),
        accountNumber: replaceEmptyWithNull(userAccountNum),
        phoneNumber: replaceEmptyWithNull(userPhoneNum),
        exchangePassword: replaceEmptyWithNull(userExchangePw),
        userState1: replaceEmptyWithNull(userState1),
        userState2: replaceEmptyWithNull(userState2),
      })
      .then((response) => {
        alert("success");
        navigate("/");
      })
      .catch((error) => {
        alert("writing Error : " + error);
      });
  };

  const getRegisteredAndBlocked = (userBlockSearchTypeEnum) => {
    const apiUrl = "/v1/api/users/registered-and-blocked";
    api
      .get(apiUrl, {
        params: {
          userBlockSearchTypeEnum: userBlockSearchTypeEnum,
          searchValue: userBlockSearchTypeEnum === "PHONE_NUMBER" ? userPhoneNum : userAccountNum,
        },
      })
      .then((response) => {
        if (response.data.userDto === null && response.data.userBlockHistoryDtoList.length === 0) {
          if (userBlockSearchTypeEnum === "PHONE_NUMBER") {
            setIsPhoneNumRegisterable(true);
          } else {
            setIsAccountNumRegisterable(true);
          }
        }
        openModal(response.data);
      })
      .catch((error) => {
        alert("writing Error : ", error);
      });
  };

  return (
    <>
      <div className="user-info-con">
        <FlexBox $gap="12px">
          <SubText>1. 일반회원</SubText>
          <InfoInsertBox>
            <InfoInsertList>
              <InfoInsertTitle>아이디</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userNo}
                onChange={(e) => setUserNo(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>비밀번호</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userPw}
                onChange={(e) => setUserPw(e.target.value)}
              />{" "}
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>닉네임</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userNickname}
                onChange={(e) => setUserNickname(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>예금주</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userAccountName}
                onChange={(e) => setUserAccountName(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>계좌 은행</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userBank}
                onChange={(e) => setUserBank(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>계좌 번호</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userAccountNum}
                onChange={(e) => {
                  setUserAccountNum(e.target.value);
                  setIsAccountNumRegisterable(false);
                }}
              />
              <CheckBtn onClick={() => getRegisteredAndBlocked(userBlockSearchTypeEnum.ACCOUNT_NUMBER)}>체크</CheckBtn>
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>휴대폰</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userPhoneNum}
                onChange={(e) => {
                  setUserPhoneNum(e.target.value);
                  setIsPhoneNumRegisterable(false);
                }}
              />
              <CheckBtn onClick={() => getRegisteredAndBlocked(userBlockSearchTypeEnum.PHONE_NUMBER)}>체크</CheckBtn>
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>환전비밀번호</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userExchangePw}
                onChange={(e) => setUserExchangePw(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>비밀번호확인</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userExchangePwCheck}
                onChange={(e) => setUserExchangePwCheck(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>회원상태1</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userState1}
                onChange={(e) => setUserState1(e.target.value)}
              />
            </InfoInsertList>
            <InfoInsertList>
              <InfoInsertTitle>회원상태2</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={userState2}
                onChange={(e) => setUserState2(e.target.value)}
              />
            </InfoInsertList>
          </InfoInsertBox>
          <SubText>게임 실행할 유저네임</SubText>
          <InfoInsertBox>
            <InfoInsertList>
              <InfoInsertTitle>유저네임</InfoInsertTitle>
              <InputTextBox
                $maxWidth="240px"
                $border="1px solid #aaa"
                $backColor="var(--c-white)"
                value={gameLaunchUsername}
                onChange={(e) => setGameLaunchUsername(e.target.value)}
              />

              <SelectForm name="vendor" placeholder={"Vendor"} options={vendorList} {...useSearch} />

              <SelectForm name="game" placeholder={"Game"} options={gameList} {...useSearch} />
            </InfoInsertList>
            <CheckBtn
              className="use-btn"
              onClick={() => gameLaunchLink()}
              $width="100%"
              $pad="8px 0"
              $color="var(--c-white)"
              $backColor="var(--c-mint-600)"
            >
              게임 링크 실행
            </CheckBtn>
          </InfoInsertBox>
        </FlexBox>
      </div>
      <PositionBox $position="absolute" $bottom="20px" $left="50%" $trans="translate(-50%)">
        <MainBtn onClick={submitUserRegistration} $pad="4px" $radius="4px" $width="100px">
          회원 생성하기
        </MainBtn>
      </PositionBox>
      {toastOpen && <Toast toastText={TOAST_MSG} />}
      <Modal>
        {isOpen && (
          <ModalBox>
            <FlexBox $direct="column" $gap="20px">
              <FlexBox $direct="column" $gap="8px">
                <TextBox $size="var(--s-title)" $line="var(--l-title)" $font="var(--f-title)">
                  {modalData.userDto === null && modalData.userBlockHistoryDtoList.length === 0
                    ? "[등록/사용]된 이력이 없습니다."
                    : "[등록/사용]된 이력이 확인됩니다."}
                </TextBox>
                {modalData.userDto && (
                  <FlexBox $gap="8px">
                    <TextBox $size="var(--s-SubText)" $line="var(--l-SubText)" $font="var(--f-SubText)">
                      {modalData.userDto.createAt} - 이미 등록된 계정
                    </TextBox>
                  </FlexBox>
                )}
                {modalData.userBlockHistoryDtoList.map((userBlockHistory) => (
                  <FlexBox $gap="8px" $direct="column">
                    <TextBox $size="var(--s-caption)" $line="var(--l-SubText)" $font="var(--f-caption)">
                      {userBlockHistory.createAt} - 블랙회원 등록
                    </TextBox>
                  </FlexBox>
                ))}
              </FlexBox>
              <FlexBox $gap="12px" $jus="right">
                <CheckBtn
                  className="use-btn"
                  onClick={closeModal}
                  $width="100%"
                  $pad="8px 0"
                  $color="var(--c-white)"
                  $backColor="var(--c-mint-600)"
                >
                  확인
                </CheckBtn>
              </FlexBox>
            </FlexBox>
          </ModalBox>
        )}
      </Modal>
    </>
  );
};

export default UserRegistration;
