import useFormData from "common/useFormData";
import CommonCategoryTab from "components/common/CommonCategoryTab";
import { useEffect, useRef, useState } from "react";
import { AreaHeader, Column, FilterBox, FormBox, Row } from "style/custom/useCustomLayout";
import { EachCheckForm, InputForm, NoLabelCheckForm, RadioForm, SelectForm, TextareaForm } from "components/common/CommonForm";
import { InputTextBox } from "style/useInputStyles";
import { IconLink, InfoTabBox, InfoTabList, PositionBox } from "style/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import api from "interceptor/api";
import useTableData from "common/useTableData";
import { useCommonState } from "contexts/useCommonState";
import useStateData from "common/useStateData";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { FilterTitle, HoverTableText } from "style/custom/useCustomText";
import NoDataTable from "components/common/NoDataTable";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { getFormatDatetime, truncateString } from "utils/date/DateUtil";
import { dbUser, dbUserMessage } from "enum/db/useUserEnum";
import { useCountPerPage, userState1 } from "constants/select/useValue";
import { isEmpty } from "lodash";
import Pagination from "utils/Pagination";
import CalendarFilter from "components/common/CalendarFilter";
import { useAlert, usePopup } from "contexts/useWindow";
import styled from "styled-components";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import UserNameTab from "components/common/UserNameTab";
import AgentNameTab from "components/common/AgentNameTab";
import { ErrorMsgForm } from "utils/FormStateUtil";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import UserLevelFilter from "components/common/UserLevelFilter";
import { checkboxYNEnum, validationTypeEnum } from "enum/useCommonEnum";
import UserState2Hover from "components/common/UserState2Hover";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const SendMessage = () => {
  const [tabState, setTabState] = useState(tabEnum[0].key);

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.operate_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("sendMessage.send_message")}</AreaTitle>
      </AreaHeader>
      <CommonCategoryTab menus={tabEnum} tabEnum={tabState} tabMove={setTabState} />
      {tabState === tabEnum[0].key ? (
        <SendMessageTab />
      ) : tabState === tabEnum[1].key ? (
        <SendMessageHistory />
      ) : (
        tabState === tabEnum[2].key && <SendMessageMacro />
      )}
    </>
  );
};

const SendMessageTab = () => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();
  const { openAlert } = useAlert();

  const [senderCount, setSenderCount] = useState();
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [macroList, setMacroList] = useState([]);

  useEffect(() => {
    getMacroList();
    getUserCount();
  }, []);

  const getTableItem = () => {
    const apiUrl = "/v1/cs-management/send-message";

    api
      .get(apiUrl, {
        params: {
          ...useSearchForm.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserCount = () => {
    const apiUrl = "/v1/cs-management/send-message/count";
    api
      .get(apiUrl)
      .then((res) => {
        setSenderCount(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      });
  };

  const getMacroList = () => {
    const apiUrl = "/v1/cs-management/send-message/macro";
    api
      .get(apiUrl, {
        params: {
          use_yn: "Y", // 사용중인거만 가져오기
        },
      })
      .then((res) => {
        setMacroList(res.data.content.map((item) => ({ label: item.title, value: item.no, contents: item.contents })));
        setMacroList((prev) => {
          return [{ label: t("sendMessage.no_seleted_macro"), value: -1, contents: "" }, ...prev];
        });
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      });
  };

  const sendMessage = () => {
    api
      .post(`/v1/cs-management/send-message`, { ...useForm.formValue })
      .then((res) => {
        resetForm();
      })
      .catch((error) => {
        const errArr = error.response?.data.errors;
        openAlert({
          title: t("sendMessage.send_fail"),
          message:
            errArr === null || errArr === undefined ? null : (
              <Column>
                {errArr.map((err, index) => (
                  <ErrorMsgForm errorJson={err} key={index} />
                ))}
              </Column>
            ),
          iconURL: true,
        });
        console.error(error);
      });
  };

  const selectUser = () => {
    const checkedList = useTG.data.filter((item) => item.isChecked);

    setSelectedUserList((prev) => {
      // 필터링하여 prev에 없는 항목만 선택
      const newCheckedList = checkedList.filter((checkedItem) => !prev.some((prevItem) => prevItem.no === checkedItem.no));
      return [...prev, ...newCheckedList];
    });
    useForm.setFormValue({
      ...useForm.formValue,
      receiver_state1: "3",
    });
  };

  const resetForm = () => {
    setSelectedUserList([]);
    useForm.resetForm();
    useTG.data.forEach((item) => {
      item.isChecked = false;
    });
  };

  const useTG = useTableData(true);

  const useForm = useFormData({
    title: "",
    contents: "",
    macro: null,
    state: dbUserMessage.state.E.value,
    receiver_state1: null,
    receiver_user_list: [],
    receiver_nickname_list_to_string: [],
  });

  const useSearchForm = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_state1: "",

      search_manage_user_yn: "Y",
    },
    getTableItem,
    useTG
  );

  useEffect(() => {
    if (useForm.formValue.receiver_state1 === "3") {
      useForm.setFormValue({
        ...useForm.formValue,
        receiver_user_list: selectedUserList.map((item) => item),
        receiver_nickname_list_to_string: selectedUserList.map((item) => item.nickname).join("; "),
      });
    } else {
      useForm.setFormValue({
        ...useForm.formValue,
        receiver_user_list: [],
        receiver_nickname_list_to_string: "",
      });
    }
  }, [selectedUserList, useForm?.formValue.receiver_state1]);

  useEffect(() => {
    if (useForm.formValue.macro !== null) {
      const selectedMacro = macroList.find((item) => item.value === useForm.formValue.macro);
      if (selectedMacro) {
        if (selectedMacro.value === -1) {
          useForm.setFormValue({
            ...useForm.formValue,
            title: "",
            contents: "",
          });
        } else {
          useForm.setFormValue({
            ...useForm.formValue,
            title: selectedMacro.label,
            contents: selectedMacro.contents,
          });
        }
      }
    }
  }, [useForm?.formValue.macro]);

  return (
    <Row>
      <Column $gap="20px" $pad="20px">
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.message_title")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <InputForm width="600px" maxWidth="600px" name="title" {...useForm} />
          </Row>
        </Row>
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.select_macro")} :</AreaTitle>
          </Row>
          <Row $width="600px">
            <SelectForm
              width="600px"
              maxWidth="600px"
              maxMenuHeight="110px"
              placeholder={t("sendMessage.select_macro")}
              options={macroList}
              name="macro"
              {...useForm}
            />
          </Row>
        </Row>
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.receiver")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <RadioForm options={tmpRadio} name="receiver_state1" {...useForm} />
          </Row>
        </Row>
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.receivers")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <Column $gap="12px">
              <InputForm disabled={true} width="600px" maxWidth="600px" name="receiver_nickname_list_to_string" {...useForm} />
              <TextBox $size="var(--s-caption)" $line="var(--l-caption)" $color="var(--c-gray-700)">
                {"*" +
                  t("sendMessage.receivers") +
                  ": " +
                  (tmpRadio[useForm.formValue.receiver_state1]?.notiLabel ?? "") +
                  " " +
                  t("common.total") +
                  " " +
                  (useForm.formValue.receiver_state1 === "0"
                    ? senderCount?.total
                    : useForm.formValue.receiver_state1 === "1"
                    ? senderCount?.state1_S
                    : useForm.formValue.receiver_state1 === "2"
                    ? senderCount?.total - senderCount?.state1_S
                    : useForm.formValue.receiver_user_list?.length) +
                  " " +
                  t("common.count")}
              </TextBox>
            </Column>
          </Row>
        </Row>
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.message_content")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <TextareaForm width="600px" maxWidth="600px" name="contents" {...useForm} />
          </Row>
        </Row>
        <Row $gap="12px" $jus="flex-end">
          <MainBtn onClick={sendMessage}>{t("sendMessage.send")}</MainBtn>
          <WhiteBtn onClick={resetForm}>{t("sendMessage.reset_content")}</WhiteBtn>
        </Row>
      </Column>
      {/* 리스트 */}
      {useTG.error ? (
        <span>error</span>
      ) : (
        useForm.formValue.receiver_state1 === "3" && (
          <Column $width="100%">
            <FilterBox $pad="12px">
              <Column $gap="12px" $align="flex-start" $flexWrap="wrap">
                <AgentSearchFilter useSearch={useSearchForm} useStated={useStated} $mar="0 24px 0 0 " />

                <Row $gap="8px">
                  <Column $gap="4px">
                    <FilterTitle>{t("sendMessage.user")}</FilterTitle>
                    <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearchForm} />
                  </Column>
                  <Column $gap="4px">
                    <FilterTitle>{t("sendMessage.user_status")}</FilterTitle>
                    <SelectForm
                      name="search_user_state1"
                      placeholder={t("common.all_state")}
                      options={userState1}
                      {...useSearchForm}
                      {...useStated}
                    />
                  </Column>
                  <UserLevelFilter useSearch={useSearchForm} useStated={useStated} />
                  <Row $pad="28px 0 0 0">
                    <EachCheckForm
                      $size="var(--s-caption)"
                      name="search_manage_user_yn"
                      labelFor="search_manage_user_yn"
                      options={checkboxYNEnum}
                      labelName={t("filter.include_manage_user")}
                      {...useSearchForm}
                    />
                  </Row>
                </Row>
              </Column>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearchForm.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearchForm.resetForm()}>
                  {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
              <Row $gap="10px" $align="center">
                <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={selectUser}>
                  {t("sendMessage.select_user_as_receiver")}
                </MainBtn>
              </Row>
              <SelectForm
                {...useTG}
                name="countPerPage"
                placeholder={useCountPerPage[0].label}
                size={"var(--s-table)"}
                line={"var(--l-table)"}
                options={useCountPerPage}
                formValue={useTG.extendData}
              />
            </Row>

            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader width="160px">{t("sendMessage.agent")}</TableHeader>
                  <TableHeader width="160px">{t("sendMessage.user_id")}</TableHeader>
                  <TableHeader width="60px">{t("sendMessage.user_level")}</TableHeader>
                  <TableHeader width="100px">{t("sendMessage.user_status")}</TableHeader>
                  <TableHeader width="180px">{t("sendMessage.last_login_date")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>
                        <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                      </TableDeps>
                      <TableDeps>
                        <AgentNameTab
                          no={item.agent_no}
                          code={item.agent_code}
                          name={item.agent_name}
                          type_name={item.type_name}
                        />
                      </TableDeps>
                      <TableDeps>
                        <Row $gap="6px" $align="center">
                          <UserState2Hover state2List={item.state2_arr} />
                          <UserNameTab no={item.no} nickname={item.nickname} />
                        </Row>
                      </TableDeps>

                      <TableDeps>
                        <Row $gap="6px" $align="center" $jus="center">
                          <img
                            src={item.img_uuid ? process.env.REACT_APP_IMG_URL + item.img_uuid : ""}
                            alt=""
                            style={{ width: "20px", height: "20px" }}
                          />
                          <TextBox>{`${item.level_name || "-"} (${
                            item.lv !== undefined && item.lv !== null ? item.lv : "-"
                          })`}</TextBox>
                        </Row>
                      </TableDeps>
                      <TableDeps>
                        <TextBox
                          className={
                            item.state1 === dbUser.state1.S.value
                              ? "complete"
                              : item.state1 === dbUser.state1.B.value
                              ? "warn"
                              : item.state1 === dbUser.state1.O.value
                              ? "withdraw"
                              : item.state1 === dbUser.state1.W.value
                              ? "normal"
                              : item.state1 === dbUser.state1.T.value
                              ? "test"
                              : ""
                          }
                        >
                          {dbUser.state1[item.state1]?.label || "-"}
                        </TextBox>
                      </TableDeps>
                      <TableDeps>{getFormatDatetime(item?.last_login_at)}</TableDeps>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}

            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
              <Row $gap="8px">
                <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={selectUser}>
                  {t("sendMessage.select_user_as_receiver")}
                </MainBtn>{" "}
              </Row>
              <PositionBox $position="absolute" $left="75%" $trans="translateX(-50%)">
                {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
              </PositionBox>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  top="auto"
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useTG.extendData}
                />
              </Row>
            </Row>
          </Column>
        )
      )}
    </Row>
  );
};

const SendMessageHistory = () => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();
  const [hoverNo, setHoverNo] = useState();
  const [openInfoTab, setOpenInfoTab] = useState(false);
  const tabRef = useRef(null);

  const { openPopup } = usePopup();

  const showPopup = (item) => {
    openPopup({
      title: `${t("sendMessage.sent_message")} (${item?.title})`,
      content: SendMessageHistoryPopup,
      props: {
        item,
      },
    });
  };

  useEffect(() => {
    getTableItem();

    const onBlurEvent = (event) => {
      if (tabRef.current && !tabRef.current.contains(event.target)) {
        infoTabToggle();
      }
    };

    document.addEventListener("mousedown", onBlurEvent);
    return () => {
      document.removeEventListener("mousedown", onBlurEvent);
    };
  }, []);

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = "/v1/cs-management/send-message/history";
    api
      .get(apiUrl, {
        params: {
          ...useSearch.formValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const infoTabToggle = (no) => {
    setHoverNo(no);
    setOpenInfoTab(!openInfoTab);
  };

  const parseContent = (content) => {
    return content?.length > 20 ? `${content.slice(0, 20)}...` : `${content}` || "-";
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_no: "",
      search_user_state1: "",

      search_manage_user_yn: "Y",

      date_period: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  // 검색 조건이 변경되면 검색어 초기화 (InputForm 에서 SelectForm 으로 변경될 때 오류 방지)
  useEffect(() => {
    useSearch.setFormValue({
      ...useSearch.formValue,
      keyword: "",
    });
  }, [useSearch.formValue?.search_state]);

  return (
    <>
      <Column $gap="12px">
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 12px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("sendMessage.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("sendMessage.user_no")}</FilterTitle>
              <InputForm
                validationType={validationTypeEnum.NUMBER}
                name="search_user_no"
                placeholder={t("common.input")}
                {...useSearch}
              />
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("sendMessage.user_status")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all_state")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />
            <Row $pad="28px 0 0 12px">
              <EachCheckForm
                $size="var(--s-caption)"
                name="search_manage_user_yn"
                labelFor="search_manage_user_yn"
                options={checkboxYNEnum}
                labelName={t("filter.include_manage_user")}
                {...useSearch}
              />
            </Row>
            <Column $gap="4px">
              <FilterTitle>{t("sendMessage.send_date")}</FilterTitle>
              <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useSearch} end />
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? (
                  <span>error</span>
                ) : (
                  <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
                )}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>
          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
            />
          </Row>
        </Row>

        <Table className="table-box">
          <thead>
            <tr className="table-title">
              <TableHeader>NO</TableHeader>
              <TableHeader>{t("sendMessage.send_date")}</TableHeader>
              <TableHeader>{t("sendMessage.message_title")}</TableHeader>
              <TableHeader>{t("sendMessage.message_content")}</TableHeader>
              <TableHeader>{t("sendMessage.receiver")}</TableHeader>
              <TableHeader>{t("sendMessage.receive_status")}</TableHeader>
              <TableHeader>{t("sendMessage.sender_admin")}</TableHeader>
            </tr>
          </thead>
          <tbody>
            {useTG.data &&
              useTG.data.map((item, index) => (
                <TableRow key={item.no} className="table-content">
                  <TableDeps $width="60px">{useTG.data.length - index}</TableDeps>
                  <TableDeps $width="180px">{getFormatDatetime(item.create_at)}</TableDeps>
                  <TableDeps $width="330px" $tAlign="left" onClick={() => infoTabToggle(item.no)}>
                    <HoverTableText>
                      {parseContent(item.title)}
                      {openInfoTab && item.no === hoverNo && (
                        <InfoTabBox ref={tabRef}>
                          <InfoTabList onClick={() => showPopup(item)}>{t("sendMessage.view_content")}</InfoTabList>
                        </InfoTabBox>
                      )}
                    </HoverTableText>
                  </TableDeps>
                  <TableDeps
                    $width="330px"
                    $maxWidth="330px"
                    $tAlign="left"
                    $white="nowrap"
                    $overflow="hidden"
                    $textOverflow="ellipsis"
                  >
                    {item?.contents}
                  </TableDeps>
                  <TableDeps $width="180px">
                    <Row $gap="6px" $align="center">
                      <UserNameTab no={item.user_no} nickname={item.user_nickname} />
                      {item.total_count === 1
                        ? ""
                        : ` ${t("sendMessage.user_and")} ${item.total_count - 1} ${t("common.user_unit")}`}
                    </Row>
                  </TableDeps>
                  <TableDeps $width="130px">
                    {item?.total_count === item?.state_r_count ? (
                      item?.total_count === 1 ? (
                        <TextBox className="complete">{t("sendMessage.read")}</TextBox>
                      ) : (
                        <TextBox className="complete">{t("sendMessage.all_read")}</TextBox>
                      )
                    ) : item?.total_count === 1 ? (
                      <TextBox className="warn">{t("sendMessage.unread")}</TextBox>
                    ) : (
                      <Row $gap="8px" $jus="center" $align="center">
                        <TextBox className="complete">
                          {t("sendMessage.read")} : {item.state_r_count}{" "}
                        </TextBox>
                        <TextBox className="warn">
                          {t("sendMessage.unread")} : {item.state_e_count}
                        </TextBox>
                      </Row>
                    )}
                  </TableDeps>
                  <TableDeps $width="180px">{item?.send_nickname}</TableDeps>
                </TableRow>
              ))}
          </tbody>
        </Table>
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              top="auto"
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

const SendMessageMacro = () => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  const showPopup = (item, isModify) => {
    openPopup({
      title: `${t("sendMessage.macro")} ${isModify ? t("common.modify") : t("common.add")}`,
      content: MacroPopup,
      props: {
        item,
        isModify,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = "/v1/cs-management/send-message/macro";
    api
      .get(apiUrl, {
        params: {
          ...useSearch.formValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMacro = ({ no }) => {
    const checkedList = useTG.data.filter((item) => item.isChecked);
    const no_list = no ? [no] : checkedList.map((item) => item.no);
    if (no_list.length === 0) {
      return;
    }
    api
      .delete(`/v1/cs-management/send-message/macro`, { params: { no_list: no_list } })
      .then((res) => {
        getTableItem();
      })
      .catch((error) => {
        const errArr = error.response?.data.errors;
        openAlert({
          title: t("sendMessage.delete_fail"),
          message:
            errArr === null || errArr === undefined ? null : (
              <Column>
                {errArr.map((err, index) => (
                  <ErrorMsgForm errorJson={err} key={index} />
                ))}
              </Column>
            ),
        });
        console.error(error);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      keyword: "",
      search_state: macroSearchState.title.value,
    },
    getTableItem,
    useTG
  );
  return (
    <>
      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="center">
            <Column $gap="4px">
              <FilterTitle>{t("sendMessage.search_filter")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_state"
                  placeholder={t("common.all_state")}
                  options={macroSearchState}
                  {...useSearch}
                  {...useStated}
                />
                <InputForm maxWidth="200px" name="keyword" placeholder={t("common.input")} {...useSearch} {...useStated} />
              </Row>
            </Column>
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={getTableItem}>
              {t("filter.search")}
            </MainBtn>
          </Row>
        </FilterBox>
        <Row $pad="14px 0 0 0" $gap="10px" $align="center">
          <Row className="total-count" $gap="4px">
            <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
        </Row>

        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteMacro({ no: null })}>
            {t("common.delete")}
          </RedBtn>

          <Row $gap="8px">
            <MainBtn onClick={() => showPopup(null, false)}>{t("sendMessage.register_macro")}</MainBtn>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            {
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="34px" $maxWidth="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                    <TableHeader $width="60px">
                      <TextBox>No.</TextBox>
                    </TableHeader>
                    <TableHeader>{t("sendMessage.macro_title")}</TableHeader>
                    <TableHeader>{t("sendMessage.content")}</TableHeader>
                    <TableHeader $width="130px">{t("sendMessage.registration_date")}</TableHeader>
                    <TableHeader $width="130px">{t("sendMessage.registrar")}</TableHeader>
                    <TableHeader $width="60px">{t("common.manage")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo?.offset - index;
                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps $width="34px" $maxWidth="34px">
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                            <TableDeps $width="60px">{number || "-"}</TableDeps>
                            <TableDeps $width="20%" $tAlign="left">
                              {truncateString(item.title, 30)}
                            </TableDeps>
                            <TableDeps $width="30%" $tAlign="left">
                              {truncateString(item.contents, 40)}
                            </TableDeps>
                            <TableDeps>{getFormatDatetime(item.create_at)}</TableDeps>
                            <TableDeps>{item.nickname}</TableDeps>
                            <TableDeps $width="60px">
                              <Row $width="100%" $jus="center" $gap="6px">
                                {item.no ? (
                                  <>
                                    <ModifyBtn eventProps={() => showPopup(item, true)} />
                                    <DeleteBtn eventProps={() => deleteMacro({ no: item?.no })} />
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Row>
                            </TableDeps>
                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            }
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
            {t("common.delete")}
          </RedBtn>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <Row $gap="8px">
            <MainBtn onClick={() => showPopup(null, false)}>{t("sendMessage.register_macro")}</MainBtn>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              top="auto"
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

const StyledTextarea = styled.textarea`
  padding: 10px;
  min-height: 200px;
  max-height: 600px; /* 원하는 최대 높이 설정 */
  overflow: auto; /* 스크롤 가능하게 설정 */
  resize: none; /* 사용자가 크기를 조절하지 못하게 함 */
  width: 100%; /* 가로 길이 설정 */
`;

const SendMessageHistoryPopup = ({ item }) => {
  return (
    <>
      <Column $width="600px" $gap="30px" $pad="20px">
        <FormBox $align="flex-start">
          <Row $flex="1">
            <AreaTitle>{t("sendMessage.message_title")} : </AreaTitle>
          </Row>
          <Row $flex="5">
            <InputTextBox $width="100%" $maxWidth="100%" value={item.title} readOnly />
          </Row>
        </FormBox>
        <FormBox $gap="12px" $align="flex-start">
          <Row $flex="1">
            <AreaTitle>{t("sendMessage.receiver")} : </AreaTitle>
          </Row>
          <Row $flex="5">
            <InputTextBox $width="100%" $maxWidth="100%" value={item.user_nickname} readOnly />
          </Row>
        </FormBox>
        <FormBox $gap="12px" $align="flex-start">
          <Row $flex="1">
            <AreaTitle>{t("sendMessage.message_content")} : </AreaTitle>
          </Row>
          <Row $flex="5">
            <StyledTextarea readOnly value={item.contents}></StyledTextarea>
          </Row>
        </FormBox>
      </Column>
    </>
  );
};

const MacroPopup = ({ item, isModify, getTableItem }) => {
  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  const useSearch = useFormData({
    no: item?.no,
    title: item?.title,
    contents: item?.contents,
  });

  const confirmBtn = () => {
    if (isModify) {
      api
        .put(`/v1/cs-management/send-message/macro`, { ...useSearch.formValue })
        .then((res) => {
          closePopup();
          getTableItem();
        })
        .catch((error) => {
          const errArr = error.response?.data.errors;
          openAlert({
            title: t("sendMessage.save_fail"),
            message:
              errArr === null || errArr === undefined ? null : (
                <Column>
                  {errArr.map((err, index) => (
                    <ErrorMsgForm errorJson={err} key={index} />
                  ))}
                </Column>
              ),
          });
          console.error(error);
        });
    } else {
      api
        .post(`/v1/cs-management/send-message/macro`, { ...useSearch.formValue })
        .then((res) => {
          closePopup();
          getTableItem();
        })
        .catch((error) => {
          const errArr = error.response?.data.errors;
          openAlert({
            title: t("sendMessage.save_fail"),
            message:
              errArr === null || errArr === undefined ? null : (
                <Column>
                  {errArr.map((err, index) => (
                    <ErrorMsgForm errorJson={err} key={index} />
                  ))}
                </Column>
              ),
          });
          console.error(error);
        });
    }
  };

  return (
    <>
      <Column $width="600px" $gap="30px" $pad="20px">
        <FormBox $align="flex-start">
          <Row $flex="1">
            <AreaTitle>{t("sendMessage.macro_title")} : </AreaTitle>
          </Row>
          <Row $flex="5">
            <InputForm name="title" width="100%" minWidth="100%" maxWidth="100%" {...useSearch} />
          </Row>
        </FormBox>
        <FormBox $align="flex-start">
          <Row $flex="1">
            <AreaTitle>{t("sendMessage.macro_content")} : </AreaTitle>
          </Row>
          <Row $flex="5">
            <TextareaForm
              name="contents"
              width="100%"
              height="300px"
              minWidth="100%"
              maxWidth="100%"
              {...useSearch}
            ></TextareaForm>
          </Row>
        </FormBox>
        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={confirmBtn}>
              {isModify ? t("common.modify") : t("common.add")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};
const tabEnum = [
  {
    title: t("sendMessage.send_message"),
    key: 1,
  },
  {
    title: t("sendMessage.message_sending_history"),
    key: 2,
  },
  {
    title: t("sendMessage.macro_settings"),
    key: 3,
  },
];

const tmpRadio = {
  0: {
    label: t("sendMessage.all_members"),
    notiLabel: t("sendMessage.all_members"),
    key: 0,
    value: "0",
  },
  1: {
    label: t("sendMessage.normal_members_only"),
    notiLabel: t("sendMessage.normal_members"),
    key: 1,
    value: "1",
  },
  2: {
    label: t("sendMessage.admin_members_only"),
    notiLabel: t("sendMessage.admin_members"),
    key: 2,
    value: "2",
  },
  3: {
    label: t("sendMessage.select_directly"),
    notiLabel: "",
    key: 3,
    value: "3",
  },
};

const macroSearchState = {
  title: {
    label: t("sendMessage.macro_title"),
    key: 0,
    value: "title",
  },
  contents: {
    label: t("sendMessage.registrar"),
    key: 1,
    value: "nickname",
  },
};

export default SendMessage;
