import React, { useState } from "react";

import CommonCategoryTab from "components/common/CommonCategoryTab";
import { USER_BETTING_CATEGORY, USER_DETAIL_BETTING_CATEGORY } from "constants/useCategory";

import BettingDetail from "./BettingDetail";
import BettingGameInfo from "./BettingGameInfo";
import BettingTransactionFlow from "./BettingTransactionFlow";
import BettingUserList from "./BettingUserList";
import BettingGameResult from "./BettingGameResult";
import BettingRawData from "./BettingRawData";

const UserBettingTab = ({ transactionId, isDetail }) => {
  const [tabEnum, setTabEnum] = useState(isDetail ? 1 : 2);

  const tabMove = (enums) => {
    setTabEnum(enums);
  };

  return (
    <>
      <CommonCategoryTab
        menus={isDetail ? USER_DETAIL_BETTING_CATEGORY : USER_BETTING_CATEGORY}
        tabMove={tabMove}
        tabEnum={tabEnum}
      />
      {tabEnum === 1 && <BettingDetail transactionId={transactionId} />}
      {tabEnum === 2 && <BettingGameInfo transactionId={transactionId} />}
      {tabEnum === 3 && <BettingTransactionFlow transactionId={transactionId} />}
      {tabEnum === 4 && <BettingUserList transactionId={transactionId} />}
      {tabEnum === 5 && <BettingGameResult transactionId={transactionId} />}
      {tabEnum === 6 && <BettingRawData transactionId={transactionId} />}

      {tabEnum < 1 || (tabEnum > 6 && <p>X</p>)}
    </>
  );
};

export default UserBettingTab;
