import i18n from "locales/i18n";

const userStateLv1 = [
  {
    label: "없음",
    value: -1,
    key: -1,
  },
  {
    label: "정상회원",
    value: 0,
    key: 0,
  },
  {
    label: "차단 회원",
    value: 1,
    key: 1,
  },
  {
    label: "블랙 회원",
    value: 2,
    key: 2,
  },
  {
    label: "양방 의심 회원원원원",
    value: 3,
    key: 3,
  },
  {
    label: "탈퇴 회원",
    value: 4,
    key: 4,
  },
];

const userStateLv2 = [
  {
    label: "없음",
    value: "NONE",
    key: 1,
  },
  {
    label: "정상회원",
    value: "NORMAL_USER",
    key: 2,
  },
  {
    label: "고액회원",
    value: "HIGH_VALUE_USER",
    key: 3,
  },
  {
    label: "양방회원",
    value: "TWO_WAY_USER",
    key: 4,
  },
  {
    label: "통합회원",
    value: "THREAT_USER",
    key: 5,
  },
  {
    label: "진상회원",
    value: "FAKE_USER",
    key: 6,
  },
  {
    label: "적자회원",
    value: "DEFICIT_USER",
    key: 7,
  },
  {
    label: "기타",
    value: "OTHER_USER",
    key: 8,
  },
];

const useCountPerPage = [
  { label: `10${i18n.t("common.each")}`, value: "10", key: 1 },
  { label: `20${i18n.t("common.each")}`, value: "20", key: 2 },
  { label: `30${i18n.t("common.each")}`, value: "30", key: 3 },
];
const useFiveCountPerPage = [
  { label: `5${i18n.t("common.each")}`, value: "5", key: 1 },
  { label: `10${i18n.t("common.each")}`, value: "10", key: 2 },
  { label: `15${i18n.t("common.each")}`, value: "15", key: 3 },
];

const userState1 = [
  {
    label: "common.all",
    value: "",
    key: 0,
  },
  {
    label: i18n.t("userState1.S"),
    value: "S",
    key: 1,
  },
  {
    label: i18n.t("userState1.B"),
    value: "B",
    key: 2,
  },
  {
    label: i18n.t("userState1.O"),
    value: "O",
    key: 3,
  },
  {
    label: i18n.t("userState1.W"),
    value: "W",
    key: 4,
  },
];

const userInout = [
  {
    label: i18n.t("common.all"),
    value: "",
    key: 0,
  },
  {
    label: i18n.t("userInfo.deposit"),
    value: "IN",
    key: 1,
  },
  {
    label: i18n.t("userInfo.withdrawal"),
    value: "OUT",
    key: 2,
  },
];

const userPointType = [
  {
    label: i18n.t("common.all"),
    value: "",
    key: 0,
  },
  {
    label: i18n.t("userInfo.payment"),
    value: "IN",
    key: 1,
  },
  {
    label: i18n.t("userInfo.exchange"),
    value: "P",
    key: 2,
  },
  {
    label: i18n.t("userInfo.retrieval"),
    value: "OUT",
    key: 3,
  },
];

const userBetType = [
  {
    label: i18n.t("common.all"),
    value: "",
    key: 0,
  },
  {
    label: i18n.t("userInfo.betting"),
    value: "B",
    key: 1,
  },
  {
    label: i18n.t("userInfo.win"),
    value: "W",
    key: 2,
  },
  {
    label: i18n.t("common.cancel"),
    value: "C",
    key: 3,
  },
];

const userMessageState = [
  {
    label: i18n.t("common.all"),
    value: "",
    key: 0,
  },
  {
    label: i18n.t("userInfo.read"),
    value: "R",
    key: 1,
  },
  {
    label: i18n.t("userInfo.unread"),
    value: "N",
    key: 2,
  },
];

const userQnaState = [
  {
    label: i18n.t("common.all"),
    value: "",
    key: 0,
  },
  {
    label: i18n.t("userInfo.in_progress"),
    value: "P",
    key: 1,
  },
  {
    label: i18n.t("userInfo.answer_complet"),
    value: "C",
    key: 2,
  },
];

const inoutState = [
  {
    label: i18n.t("common.all"),
    value: "",
    key: 0,
  },
  {
    label: i18n.t("common.process"),
    value: "P",
    key: 1,
  },
  {
    label: i18n.t("inout.impossible"),
    value: "B",
    key: 2,
  },
  {
    label: i18n.t("inout.request"),
    value: "D",
    key: 3,
  },
];

const periodType = [
  {
    label: "일간",
    value: "D",
    key: 0,
  },
  {
    label: "월간",
    value: "M",
    key: 1,
  },
];

const chargeCalendarFilter = [
  {
    label: i18n.t("charge.demand_amount"),
    value: "demand_date",
    key: 1,
  },
  {
    label: i18n.t("charge.actual_date"),
    value: "process",
    key: 2,
  },
];

const agentQnaCalendarFilter = [
  {
    label: i18n.t("agentQna.date"),
    value: "create_at",
    key: 1,
  },
  {
    label: i18n.t("agentQna.answer_date"),
    value: "answer_at",
    key: 2,
  },
];

const userInoutState = [
  {
    label: "전체",
    value: "",
    key: 0,
  },
  {
    label: "완료",
    value: "IN",
    key: 1,
  },
  {
    label: "대기",
    value: "OUT",
    key: 2,
  },
];

const depositFilterCondition = {
  CREATE_AT: {
    label: i18n.t("deposit.deposit_request_at"),
    value: "create_at",
    key: "create_at",
  },
  PROCESS_AT: {
    label: i18n.t("deposit.deposit_process_at"),
    value: "process_at",
    key: "process_at",
  },
};

const withdrawalFilterCondition = {
  CREATE_AT: {
    label: i18n.t("withdrawal.withdrawal_request_at"),
    value: "create_at",
    key: "create_at",
  },
  PROCESS_AT: {
    label: i18n.t("withdrawal.withdrawal_process_at"),
    value: "process_at",
    key: "process_at",
  },
};

export {
  userStateLv1,
  userStateLv2,
  useCountPerPage,
  useFiveCountPerPage,
  userState1,
  userInout,
  userPointType,
  userBetType,
  userMessageState,
  userQnaState,
  inoutState,
  periodType,
  agentQnaCalendarFilter,
  chargeCalendarFilter,
  userInoutState,
  depositFilterCondition,
  withdrawalFilterCondition,
};
