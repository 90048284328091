import useFormData from "common/useFormData";
import { InputForm, TextareaForm, ToggleButtonForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { useAlert, usePopup } from "contexts/useWindow";
import { dbAdmin } from "enum/db/useAdminEnum";
import useStateData from "common/useStateData";
import { validationTypeEnum } from "enum/useCommonEnum";
import { t } from "i18next";

const AdminForm = ({ item, getTableItem }) => {
  const isModify = !!item;
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    useStated.setErrorData([], true);

    if (isModify) {
      getAdminInfo();
    } else {
      useForm.resetForm();
    }
  }, [item]);

  const getAdminInfo = () => {
    const apiUrl = `/v1/site/admin/${item}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue({ ...res.data.content, ip_arr: res.data.content.ip_arr.toString() });
      })
      .catch((err) => {});
  };

  const regisAndInsert = () => {
    const apiType = isModify ? api.put : api.post;
    const apiUrl = isModify ? `/v1/site/admin/${item}` : "/v1/site/admin";
    apiType(apiUrl, {
      ...useForm.formValue,
    })
      .then(() => {
        showFormSuccessAlert();
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const showFormSuccessAlert = () => {
    const title = isModify ? t("common.save_msg") : t("common.add_msg");
    openAlert({
      title,
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showFormFailAlert = () => {
    const title = isModify ? t("common.save_fail") : t("common.add_fail");
    openAlert({
      title,
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  /**
   * 중복체크
   * @param {String} key
   */
  const checkDuplicated = (key) => {
    const apiUrl = `/v1/agent/info/duplicate/${key}`;

    const data = {
      ...useForm.formValue,
      ...(isModify ? { no: item } : {}),
    };
    api
      .get(apiUrl, {
        params: data,
      })
      .then((res) => {
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        // showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };

  const useForm = useFormData({
    id: "",
    nickname: "",
    password: "",
    re_password: "",
    allow_ip_yn: "N",
    ip_arr: "",
  });

  return (
    <>
      <Column className="area-box" $gap="20px">
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("admin.admin_id")}
          </TextBox>
          {isModify ? (
            <TextBox $width="120px" $minWidth="120px">
              {useForm.formValue.id}
            </TextBox>
          ) : (
            <Column $gap="4px" $width="100%">
              <Row $gap="8px" $width="100%">
                <InputForm
                  maxWidth="100%"
                  type="text"
                  name="id"
                  maxLength={20}
                  validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                  {...useForm}
                  {...useStated}
                />
                <WhiteBtn
                  $minWidth="80px"
                  $height="30px"
                  $size="var(--s-table)"
                  $line="var(--l-table)"
                  onClick={() => checkDuplicated("id")}
                >
                  {t("common.ckech_duplicate")}
                </WhiteBtn>
              </Row>

              <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                * 6~20 {t("common.length")} {t("common.valid_eng_num")}
              </TextBox>
            </Column>
          )}
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("admin.nickname")}
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm maxWidth="100%" type="text" name="nickname" {...useForm} {...useStated} />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("admin-nickname")}
              >
                {t("common.ckech_duplicate")}
              </WhiteBtn>
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 4~50 {t("common.valid_length")}
            </TextBox>
          </Column>
        </FormBox>
        {isModify || (
          <>
            <FormBox $align="flex-start">
              <TextBox $width="120px" $minWidth="120px">
                {t("common.password")}
              </TextBox>
              <Column $gap="4px" $width="100%">
                <Row>
                  <InputForm
                    maxWidth="100%"
                    type="password"
                    name="password"
                    disabled={isModify}
                    maxLength={30}
                    validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                    {...useForm}
                    {...useStated}
                  />
                </Row>

                <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                  * 16~30{t("common.length")} {t("common.valid_eng_num")}
                </TextBox>
              </Column>
            </FormBox>

            <FormBox $align="flex-start">
              <TextBox $width="120px" $minWidth="120px">
                {t("common.re_password")}
              </TextBox>
              <InputForm maxWidth="100%" type="password" name="re_password" disabled={isModify} {...useForm} {...useStated} />
            </FormBox>
          </>
        )}

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("admin.block_ip")} 
          </TextBox>

          <ToggleButtonForm name="allow_ip_yn" options={dbAdmin.allow_ip_yn} {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("admin.regis_ip")} 
          </TextBox>
          <Column $width="100%" $gap="4px">
            <TextareaForm
              type="text"
              name="ip_arr"
              placeholder={t("admin.regis_ip_plh")}
              {...useForm}
              {...useStated}
            />
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              {t("admin.regis_msg")}
            </TextBox>
          </Column>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={regisAndInsert}>
              {isModify ? t("common.save") : t("common.add")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AdminForm;
