import { useEffect } from "react";

import useFormData from "common/useFormData";
import { useAlert, usePopup } from "contexts/useWindow";
import api from "interceptor/api";

import useStateData from "common/useStateData";
import { EachCheckForm, ImageForm, InputForm, SelectForm } from "components/common/CommonForm";
import { dbSiteBannerMng } from "enum/db/useSiteEnum";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import { checkboxYNEnum, validationTypeEnum } from "enum/useCommonEnum";
import { t } from "i18next";
import GameList from "./GameList";

const SiteBannerMngForm = ({ item, getTableItem }) => {
  const useStated = useStateData();
  const isModify = !!item;

  const { closePopup, openPopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    if (isModify) {
      getPopupInfo();
    }
  }, [item]);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      console.log(item);
      if (item?.msg?.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showFormAlert = (isSuccess) => {
    if (isModify) {
      openAlert({
        title: isSuccess ? t("common.save_msg") : t("common.save_fail"),
        message: isSuccess ? null : t("common.try_later"),
        iconURL: isSuccess ? false : true,
      });
    } else {
      openAlert({
        title: isSuccess ? t("common.add_msg") : t("common.add_fail"),
        message: isSuccess ? null : t("common.try_later"),
        iconURL: isSuccess ? false : true,
      });
    }
  };

  const getPopupInfo = () => {
    const apiUrl = `/v1/site/operational/banners/${item}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,
          site_banner_mng: {},
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const clickSubmitBtn = () => {
    let apiUrl = isModify ? `/v1/site/operational/banners/${item}` : "/v1/site/operational/banners";
    let apiType = isModify ? api.put : api.post;

    apiType(apiUrl, {
      ...useForm.formValue,
    })
      .then(() => {
        showFormAlert(true);
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showFormAlert(false);
      });
  };

  const useForm = useFormData({
    name: "",
    link_type: dbSiteBannerMng.link_type.NONE.value,
    use_yn: dbSiteBannerMng.use_yn.Y.value,
    site_banner_mng: {},
    use_yn_pc: checkboxYNEnum.Y.value,
    use_yn_mobile: checkboxYNEnum.Y.value,
  });

  const setGame = (game) => {
    useForm.formValue.game_no = game.no;
    useForm.formValue.game_title = game.title;
  };

  const showPopup = () => {
    openPopup({
      title: t("banner.select_game"),
      content: GameList,
      props: {
        setGame,
        item,
        getTableItem,
      },
    });
  };

  return (
    <>
      <Column className="area-box" $gap="20px">
        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("banner.name")}
          </TextBox>
          <InputForm maxWidth="100%" type="text" name="name" maxLength={150} {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("banner.banner_no")}
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm
                maxWidth="100%"
                name="banner_no"
                validationType={validationTypeEnum.NUMBER}
                {...useForm}
                {...useStated}
              />
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              {t("banner.banner_no_msg")}
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("banner.use_yn")}
          </TextBox>

          <SelectForm name="use_yn" options={dbSiteBannerMng.use_yn} {...useForm} {...useStated} />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("banner.display_type")}
          </TextBox>
          <Row $align="center" $gap="8px">
            <EachCheckForm
              $size="var(--s-caption)"
              name="use_yn_pc"
              labelFor="use_yn_pc"
              options={checkboxYNEnum}
              labelName={t("common.web")}
              {...useForm}
            />
            <EachCheckForm
              $size="var(--s-caption)"
              name="use_yn_mobile"
              labelFor="use_yn_mobile"
              options={checkboxYNEnum}
              labelName={t("common.mobile")}
              {...useForm}
            />
          </Row>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("banner.image")}
          </TextBox>
          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <ImageForm name="img_uuid" groupNm="site_banner_mng" height={"28px"} {...useForm} {...useStated} />
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              {t("banner.banner_img_msg")}
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="135px" $minWidth="135px">
            {t("banner.link_type")}
          </TextBox>

          <SelectForm name="link_type" options={dbSiteBannerMng.link_type} top="top" {...useForm} {...useStated} />
        </FormBox>

        {useForm?.formValue?.link_type === dbSiteBannerMng.link_type.U.value && (
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("banner.link")}
            </TextBox>

            <Column $gap="4px" $width="100%">
              <Row $gap="8px" $width="100%">
                <InputForm maxWidth="100%" type="text" name="link" maxLength={150} {...useForm} {...useStated} />
              </Row>
              <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                {t("banner.banner_link_msg")}
              </TextBox>
            </Column>
          </FormBox>
        )}

        {useForm?.formValue?.link_type === dbSiteBannerMng.link_type.G.value && (
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("banner.game_name")}
            </TextBox>

            <Column $gap="4px" $width="100%">
              <Row $gap="8px" $width="100%">
                <Column>
                  <InputForm maxWidth="100%" type="text" name="game_title" disabled {...useForm} {...useStated} />
                  <InputForm type="hidden" name="game_no" disabled {...useForm} {...useStated} />
                </Column>

                <WhiteBtn
                  $minWidth="80px"
                  $height="30px"
                  $size="var(--s-table)"
                  $line="var(--l-table)"
                  onClick={() => showPopup()}
                >
                  {t("banner.select_game")}
                </WhiteBtn>
              </Row>

              <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                {t("banner.select_game_msg")}
              </TextBox>
            </Column>
          </FormBox>
        )}

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={clickSubmitBtn}>
              {isModify ? t("common.save") : t("common.add")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default SiteBannerMngForm;
