/** @format */

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

import { usePopup } from "contexts/useWindow";
// 셀렉트 벨류 constant
import { periodType, useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import { RadioForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import { useCommonState } from "contexts/useCommonState";
import { dbUserHistoryAccount, dbUserHistoryPoint } from "enum/db/useUserEnum";
import { periodTypeEnum } from "enum/statistics/useTableEnum";
import { t } from "i18next";
import { useState } from "react";
import { FilterTitle } from "style/custom/useCustomText";
import { yyyymmToDateFormat, yyyymmddToDateFormat, yyyymmddToYearMonth } from "utils/date/DateUtil";
import UserChangePointApproveList from "./UserChangePointApproveList";
import UserChangePointRejectList from "./UserChangePointRejectList";
import UserInMoneyApproveList from "./UserInMoneyApproveList";
import UserInMoneyRejectList from "./UserInMoneyRejectList";
import UserOutMoneyApproveList from "./UserOutMoneyApproveList";
import UserOutMoneyRejectList from "./UserOutMoneyRejectList";

const UserCashStatistics = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();

  const [period, setPeriod] = useState(periodTypeEnum.D.value);

  const showUserInMoneyListPopup = (itemDate, yyyymmdd, state) => {
    const isApproveList = state === dbUserHistoryAccount.state.P.value;
    const status = isApproveList ? t("cashStatistics.deposit_complete") : t("cashStatistics.deposit_reject");
    const content = isApproveList ? UserInMoneyApproveList : UserInMoneyRejectList;
    let date = period === periodTypeEnum.D.value ? itemDate : yyyymmddToYearMonth(yyyymmdd);
    openPopup({
      title: `${t("cashStatistics.cash_statistics")} - ${status}`,
      content: content,
      props: {
        date,
        state,
        period,
        search: useSearchState,
      },
    });
  };

  const showUserOutMoenyListPopup = (itemDate, yyyymmdd, state) => {
    const isApproveList = state === dbUserHistoryAccount.state.P.value;
    const status = isApproveList ? t("cashStatistics.withdrawal_complete") : t("cashStatistics.withdrawal_reject");
    const content = isApproveList ? UserOutMoneyApproveList : UserOutMoneyRejectList;
    let date = period === periodTypeEnum.D.value ? itemDate : yyyymmddToYearMonth(yyyymmdd);
    openPopup({
      title: `${t("cashStatistics.cash_statistics")} - ${status}`,
      content: content,
      props: {
        date,
        state,
        period,
        search: useSearchState
      },
    });
  };

  const showUserChangePointListPopup = (itemDate, yyyymmdd, state) => {
    const isApproveList = state === dbUserHistoryPoint.state.P.value;
    const status = isApproveList ? t("cashStatistics.point_change_complete") : t("cashStatistics.point_change_reject");
    const content = isApproveList ? UserChangePointApproveList : UserChangePointRejectList;
    let date = period === periodTypeEnum.D.value ? itemDate : yyyymmddToYearMonth(yyyymmdd);
    openPopup({
      title: `${t("cashStatistics.cash_statistics")} - ${status}`,
      content: content,
      props: {
        date,
        state,
        period,
        search: useSearchState,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/statistics/user-cash/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setPeriod(useSearch.formValue.period);
        setUseSearchState(useSearch);
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_val: "",
      search_agent_category: "",
      search_agent_name: "",

      period: periodTypeEnum.D.value,
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  const [useSearchState, setUseSearchState] = useState(useSearch);

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.stats_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.user_cash_states")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap='4px'>
              <FilterTitle>{t("statistics.select_method_period")}</FilterTitle>
              <Row $align='center' $gap='12px' $height='34px'>
                <RadioForm name='period' options={periodType} {...useSearch} />
                <CalendarFilter endName='end_date' startName='start_date' {...useSearch} end />
              </Row>
            </Column>

          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="8px">
            <SelectForm
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="130px">{t("statistics.date")}</TableHeader>
                  <TableHeader>{t("cashStatistics.profit_and_loss")}</TableHeader>
                  <TableHeader>{t("cashStatistics.deposit_request")}</TableHeader>
                  <TableHeader>{t("cashStatistics.deposit_complete")}</TableHeader>
                  <TableHeader>{t("cashStatistics.deposit_reject")}</TableHeader>
                  <TableHeader>{t("cashStatistics.withdrawal_request")}</TableHeader>
                  <TableHeader>{t("cashStatistics.withdrawal_complete")}</TableHeader>
                  <TableHeader>{t("cashStatistics.withdrawal_reject")}</TableHeader>
                  <TableHeader>{t("cashStatistics.point_change_request")}</TableHeader>
                  <TableHeader>{t("cashStatistics.point_change_complete")}</TableHeader>
                  <TableHeader>{t("cashStatistics.point_change_reject")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      const accountInOutTotal = (item.account_in_money || 0) - (item.account_out_money || 0);
                      const accountInOutClassName = accountInOutTotal > 0 ? "complete" : accountInOutTotal < 0 ? "warn" : "";

                      const accountInWaitMoney = item.account_in_wait_money ? parseInt(item.account_in_wait_money) : 0;
                      const accountInMoney = item.account_in_money ? parseInt(item.account_in_money) : 0;
                      const accountInWaitRejectMoney = item.account_in_reject_money ? parseInt(item.account_in_reject_money) : 0;

                      const accountOutWaitMoney = item.account_out_wait_money ? parseInt(item.account_out_wait_money) : 0;
                      const accountOutMoney = item.account_out_money ? parseInt(item.account_out_money) : 0;
                      const accountOutRejectMoney = item.account_out_reject_money ? parseInt(item.account_out_reject_money) : 0;

                      const changePointWait = item.change_point_wait ? parseInt(item.change_point_wait) : 0;
                      const changePoint = item.change_point ? parseInt(item.change_point) : 0;
                      const changePointReject = item.change_point_reject ? parseInt(item.change_point_reject) : 0;

                      return (
                        <TableRow key={item.date} className="table-content">
                          <TableDeps $width="60px">{number || "-"}</TableDeps>

                          <TableDeps>
                            {period === periodTypeEnum.D.value
                              ? yyyymmddToDateFormat(item.yyyymmdd) || "-"
                              : period === periodTypeEnum.M.value
                                ? yyyymmToDateFormat(item.yyyymmdd) || "-"
                                : "-"}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            <TextBox className={accountInOutClassName}>
                              {accountInOutTotal.toLocaleString(undefined, {
                                style: "currency",
                                currency: "KRW",
                                signDisplay: "exceptZero",
                              })}
                            </TextBox>
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {accountInWaitMoney ? (
                              `${accountInWaitMoney.toLocaleString(undefined, {
                                style: "currency",
                                currency: "KRW",
                              })}(${item.account_in_wait_count})`
                            ) : (
                              "-"
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            <TextBox
                              as="button"
                              $textDeco="underline"
                              onClick={() => showUserInMoneyListPopup(item.date, item.yyyymmdd, dbUserHistoryAccount.state.P.value)}
                            >
                              {accountInMoney ? (
                                `${accountInMoney.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "KRW",
                                })}(${item.account_in_count})`
                              ) : (
                                "-"
                              )}
                            </TextBox>
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            <TextBox
                              as="button"
                              $textDeco="underline"
                              onClick={() => showUserInMoneyListPopup(item.date, item.yyyymmdd, dbUserHistoryAccount.state.B.value)}
                            >
                              {accountInWaitRejectMoney ? (
                                `${accountInWaitRejectMoney.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "KRW",
                                })}(${item.account_in_reject_count})`
                              ) : (
                                "-"
                              )}
                            </TextBox>
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            {accountOutWaitMoney ? (
                              `${accountOutWaitMoney.toLocaleString(undefined, {
                                style: "currency",
                                currency: "KRW",
                              })}(${item.account_out_wait_count})`
                            ) : (
                              "-"
                            )}
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            <TextBox
                              as="button"
                              $textDeco="underline"
                              onClick={() => showUserOutMoenyListPopup(item.date, item.yyyymmdd, dbUserHistoryAccount.state.P.value)}
                            >
                              {accountOutMoney ? (
                                `${accountOutMoney.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "KRW",
                                })}(${item.account_out_count})`
                              ) : (
                                "-"
                              )}
                            </TextBox>
                          </TableDeps>

                          <TableDeps $tAlign="right">
                            <TextBox
                              as="button"
                              $textDeco="underline"
                              onClick={() => showUserOutMoenyListPopup(item.date, item.yyyymmdd, dbUserHistoryAccount.state.B.value)}
                            >
                              {accountOutRejectMoney ? (
                                `${accountOutRejectMoney.toLocaleString(undefined, {
                                  style: "currency",
                                  currency: "KRW",
                                })}(${item.account_out_reject_count})`
                              ) : (
                                "-"
                              )}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $tAlign="right">
                            {changePointWait ? (
                              `${changePointWait.toLocaleString()}(P)(${item.change_point_wait_count}${t("common.count")})`
                            ) : (
                              "-"
                            )}
                          </TableDeps>
                          <TableDeps $tAlign="right">
                            <TextBox
                              as="button"
                              $textDeco="underline"
                              onClick={() => showUserChangePointListPopup(item.date, item.yyyymmdd, dbUserHistoryPoint.state.P.value)}
                            >
                              {changePoint ? (
                                `${changePoint.toLocaleString()}(P)(${item.change_point_count}${t("common.count")})`
                              ) : (
                                "-"
                              )}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $tAlign="right">
                            <TextBox
                              as="button"
                              $textDeco="underline"
                              onClick={() => showUserChangePointListPopup(item.date, item.yyyymmdd, dbUserHistoryPoint.state.B.value)}
                            >
                              {changePointReject ? (
                                `${changePointReject.toLocaleString()}(P)(${item.change_point_reject_count}${t("common.count")})`
                              ) : (
                                "-"
                              )}
                            </TextBox>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default UserCashStatistics;
