import React, { useEffect, useState } from "react";
import { Column, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { SelectForm } from "./CommonForm";
import api from "interceptor/api";
import { t } from "i18next";

const AgentTypeFilter = ({ useSearch, useStated }) => {
  const [agentTypeList, setAgentTypeList] = useState([]);

  useEffect(() => {
    getAgentTypeList();
  }, []);

  const createAgentTypeEnum = (data) => {
    const newEnum = [];

    let allItem = {
      label: t("common.all"),
      value: "",
      key: 0,
    };
    newEnum.push(allItem);

    data.forEach((item) => {
      // 새로운 객체 생성
      let newItem = {
        label: item.name,
        value: item.type,
        key: item.depth,
      };

      // 변환된 객체를 새로운 형식의 객체에 추가
      newEnum.push(newItem);
    });

    // 새로운 형식의 객체 반환
    setAgentTypeList(newEnum);
  };

  const getAgentTypeList = () => {
    const apiUrl = "/v1/agent/info/agent-types";
    api
      .get(apiUrl, {})
      .then((res) => {
        createAgentTypeEnum(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  return (
    <Column $gap="4px">
      <FilterTitle>{t("agent.type")}</FilterTitle>
      <Row $gap="4px">
        {agentTypeList && (
          <SelectForm name="search_agent_type" placeholder={t("common.all")} options={agentTypeList} {...useSearch} {...useStated} />
        )}
      </Row>
    </Column>
  );
};

export default AgentTypeFilter;
