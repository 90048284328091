import { dbAgentAdmin, dbAgentConfig } from "enum/db/useAgentEnum";
import { dbLogCommissionUpdate, dbLogInfoUpdate } from "enum/db/useLogEnum";
import { dbUser, dbUserState2 } from "enum/db/useUserEnum";
import i18n from "locales/i18n";

/**
 * enum에 전체 추가
 * @param {*} enums
 * @param {boolean} condition
 * @returns
 */
export const addAllFromEnum = (enums, labelTxt) => {
  return { ALL: { label: labelTxt || i18n.t("common.all"), value: "", key: "" }, ...enums };
};

/**
 * AgentAdmin의 updated value
 *
 * @param {dbLogInfoUpdate.updated_type} type
 * @param {*} value
 * @returns
 */
export const getLogInfoUpdateMeaningByAgentAdmin = (type, value) => {
  switch (type) {
    case dbLogInfoUpdate.updated_type.TYPE.value:
      return dbAgentAdmin.lv[value]?.label || value;

    case dbLogInfoUpdate.updated_type.STATE.value:
      return dbAgentAdmin.state[value]?.label || value;

    default:
      return value;
  }
};

/**
 * log_commission_update 의 updated value
 *
 * @param {dbLogCommissionUpdate.updated_type} type
 * @param {*} value
 * @returns
 */
export const getLogCommissionUpdateMeaningByAgentAdmin = (type, value) => {
  switch (type) {
    case dbLogCommissionUpdate.updated_type.AGENT_COMMISSION_YN.value:
      return dbAgentConfig.agent_commission_yn[value]?.label || value;

    case dbLogCommissionUpdate.updated_type.AGENT_COMMISSION_TYPE.value:
      return dbAgentConfig.agent_commission_type[value]?.label || value;

    default:
      return value;
  }
};

/**
 * UserModifyHistory의 updated value
 *
 * @param {dbLogInfoUpdate.updated_type} type
 * @param {*} value
 * @returns
 */
export const getLogInfoUpdateMeaningByUserModifyHistory = (type, value, userLevelList) => {
  let result;
  switch (type) {
    case dbLogInfoUpdate.user_updated_type.STATE1.value:
      result = dbUser.state1[value]?.label || value;
      break;

    case dbLogInfoUpdate.user_updated_type.STATE2.value:
      const arr = value !== "" ? JSON.parse(value || "[]") : "";
      result = arr ? arr.map((state) => dbUserState2.state2[state]?.label || "-").join(", ") : "-";
      break;

    case dbLogInfoUpdate.user_updated_type.LV.value:
      const intValue = parseInt(value, 10);
      const userLevel = userLevelList.find(item => item.value === intValue);
      result = userLevel ? userLevel.label : value;
      break;

    case dbLogInfoUpdate.user_updated_type.FIRST_CHARGE_POINT_YN.value:
      result = dbUser.first_charge_point_yn[value]?.label || value;
      break;

    case dbLogInfoUpdate.user_updated_type.EVERY_CHARGE_POINT_YN.value:
      result = dbUser.every_charge_point_yn[value]?.label || value;
      break;

    default:
      result = value;
  }

  return result.length > 20 ? `${result.slice(0, 20)}...` : result;
};
