import { useEffect, useState } from "react";
import api from "interceptor/api";
import { useAlert } from "contexts/useWindow";
import { t } from "i18next";
import { useCommonState } from "contexts/useCommonState";

function useMenuFavorites() {
  const { favorites, setFavorites } = useCommonState();

  const { openAlert } = useAlert();
  const MAX_MENU_FAVORITES_COUNT = 9;

  const deleteFavorites = (menu_code) => {
    const apiUrl = `/v1/menu-favorites/${menu_code}`;
    api
      .delete(apiUrl, {})
      .then((res) => {
        getFavorite();
      })
      .catch(() => {});
  };

  const showInsertMenuFavoritesConditionFail = () => {
    openAlert({
      title: t("common.menu_favorites_length_condition_fail", { count: MAX_MENU_FAVORITES_COUNT }),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const insertFavorites = (menu_code) => {
    if (favorites.length >= MAX_MENU_FAVORITES_COUNT) {
      showInsertMenuFavoritesConditionFail();
    } else {
      const apiUrl = `/v1/menu-favorites/${menu_code}`;

      api
        .post(apiUrl, {})
        .then(() => {
          getFavorite();
        })
        .catch((err) => {});
    }
  };

  const getFavorite = () => {
    const apiUrl = `/v1/menu-favorites/list`;
    api
      .get(apiUrl, {})
      .then((res) => {
        setFavorites(res.data.content);
      })
      .catch(() => {
        setFavorites([]);
      });
  };
  useEffect(() => {
    getFavorite();
  }, []);

  return {
    deleteFavorites,
    favorites,
    insertFavorites,
  };
}

export default useMenuFavorites;
