import useFormData from "common/useFormData";
import { TextareaForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { usePopup } from "contexts/useWindow";
import { t } from "i18next";
import { getFormatDatetime } from "./../../../../utils/date/DateUtil";

const BlockUserAgentChangeInfo = ({ no }) => {
  const { closePopup } = usePopup();

  useEffect(() => {
    getChangeInfo();
  }, [no]);

  const getChangeInfo = () => {
    const apiUrl = `/v1/user-agent-change/info/${no}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue(res.data.content);
      })
      .catch((err) => {});
  };

  const useForm = useFormData({});

  return (
    <>
      <Column className="area-box" $gap="20px" $width="650px">
        <FormBox $align="flex-start">
          <TextBox $width="200px" $minWidth="120px">
            {t("user.agent_requst_at")}
          </TextBox>
          <TextBox $font="var(--f-subB)"> {getFormatDatetime(useForm.formValue?.create_at)}</TextBox>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="200px" $minWidth="120px">
            {t("user.agent_block_at")}
          </TextBox>
          <TextBox $font="var(--f-subB)"> {getFormatDatetime(useForm.formValue?.modify_at) || "-"}</TextBox>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="200px" $minWidth="120px">
            {t("user.block_admin")}
          </TextBox>
          <TextBox $font="var(--f-subB)">{`${useForm.formValue?.modify_name || "-"} (${
            useForm.formValue?.modify_no || "-"
          })`}</TextBox>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="200px" $minWidth="120px">
            {t("user.info_block_memo")}
          </TextBox>
          <Column $gap="4px">
            <TextareaForm height="250px" width="450px" type="text" name="memo" {...useForm} disabled />
          </Column>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default BlockUserAgentChangeInfo;
