import i18n from "locales/i18n";

const dbAgent = {
  type: {
    A: {
      label: "부 본사",
      value: "A",
      key: "A",
    },
    B: {
      label: "총판",
      value: "B",
      key: "B",
    },
    C: {
      label: "매장",
      value: "C",
      key: "C",
    },
    D: {
      label: "영업",
      value: "D",
      key: "D",
    },
    E: {
      label: "E",
      value: "E",
      key: "E",
    },
    F: {
      label: "F",
      value: "F",
      key: "F",
    },
    G: {
      label: "G",
      value: "G",
      key: "G",
    },
    H: {
      label: "H",
      value: "H",
      key: "H",
    },
    I: {
      label: "I",
      value: "I",
      key: "I",
    },
    J: {
      label: "J",
      value: "J",
      key: "J",
    },
  },
  state: {
    Y: {
      label: i18n.t("common.use_y"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.stop"),
      value: "N",
      key: "N",
    },
  },
};

const dbAgentAdmin = {
  state: {
    N: {
      label:i18n.t("common.normal"),
      value: "N",
      key: "N",
    },
    B: {
      label:i18n.t("common.block"),
      value: "B",
      key: "B",
    },
  },

  lv: {
    S: {
      label:i18n.t("common.rep_agent_admin"), 
      value: "S",
      key: "S",
    },
    N: {
      label:i18n.t("common.normal_agent_admin"),
      value: "N",
      key: "N",
    },
  },
};

const dbAgentBoardQna = {
  state: {
    P: {
      label: i18n.t("agentQna.no_answer"),
      value: "P",
      key: "P",
    },
    C: {
      label: i18n.t("agentQna.complete_answer"),
      value: "C",
      key: "C",
    },
  },
};

const dbAgentConfig = {
  agent_commission_yn: {
    Y: {
      label: i18n.t("common.use_y"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.use_n"),
      value: "N",
      key: "N",
    },
  },
  agent_commission_type: {
    T: {
      label: "Total",
      value: "T",
      key: "T",
    },
    L: {
      label: "Losing",
      value: "L",
      key: "L",
    },
    R: {
      label: "Rolling",
      value: "R",
      key: "R",
    },
  },
};

const dbAgentPaymentsHistory = {
  agent_commission_yn: {
    Y: {
      label: i18n.t("common.use_y"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.use_n"),
      value: "N",
      key: "N",
    },
  },
  agent_commission_type: {
    T: {
      label: "Total",
      value: "T",
      key: "T",
    },
    L: {
      label: "Losing",
      value: "L",
      key: "L",
    },
    R: {
      label: "Rolling",
      value: "R",
      key: "R",
    },
  },

};

const dbAgentCashChargeHistory = {
  state: {
    C: {
      label: i18n.t("charge.complete_state"),
      value: "C",
      key: "C",
    },
    D: {
      label: i18n.t("charge.wait_state"),
      value: "D",
      key: "D",
    },
    B: {
      label: i18n.t("charge.block_state"),
      value: "B",
      key: "B",
    },
  },
};

export { dbAgent, dbAgentAdmin, dbAgentConfig, dbAgentBoardQna, dbAgentPaymentsHistory, dbAgentCashChargeHistory };
