import React, { useState } from "react";

import CommonCategoryTab from "components/common/CommonCategoryTab";
import { AGENT_INFO_CATEGORY, AGENT_INFO_CATEGORY_AGENT } from "constants/useCategory";
import AgentInfo from "./AgentInfo";
import AgentAdminInfo from "./AgentAdminInfo";
import AgentBoardQnaList from "../qna/AgentBoardQnaList";
import AgentUserList from "../users/AgentUserList";
import AgentPayment from "../payment/AgentPayment";
import { useCommonState } from "contexts/useCommonState";

const AgentInfoTab = ({ agentNo, tabNm = 1, refresh }) => {
  const [tabEnum, setTabEnum] = useState(tabNm);

  const { isAdmin, isAgent } = useCommonState();

  const tabMove = (enums) => {
    setTabEnum(enums);
  };

  return (
    <>
      {isAdmin ? (
        <>
          <CommonCategoryTab menus={AGENT_INFO_CATEGORY} tabMove={tabMove} tabEnum={tabEnum} />
          {tabEnum === 1 && <AgentInfo agentNo={agentNo} />}
          {tabEnum === 2 && <AgentAdminInfo agentNo={agentNo} />}
          {tabEnum === 3 && <AgentPayment agentNo={agentNo} refreshTable={refresh} />}
          {tabEnum === 4 && <AgentUserList agentNo={agentNo} />}
          {tabEnum === 5 && <AgentBoardQnaList agentNo={agentNo} refresh={refresh} />}
          {tabEnum < 1 || (tabEnum > 5 && <p>X</p>)}
        </>
      ) : null}

      {isAgent ? (
        <>
          <CommonCategoryTab menus={AGENT_INFO_CATEGORY_AGENT} tabMove={tabMove} tabEnum={tabEnum} />
          {tabEnum === 1 && <AgentInfo agentNo={agentNo} />}
          {tabEnum === 2 && <AgentAdminInfo agentNo={agentNo} />}
          {tabEnum === 3 && <AgentUserList agentNo={agentNo} />}
          {tabEnum < 1 || (tabEnum > 3 && <p>X</p>)}
        </>
      ) : null}
    </>
  );
};

export default AgentInfoTab;
