import React from "react";
import { Column, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { EachCheckForm, InputForm, SelectForm } from "./CommonForm";
import { agentFilterCondition } from "enum/agent/userAgentUserEnum";
import { checkboxYNEnum } from "enum/useCommonEnum";
import { t } from "i18next";

const AgentSearchFilter = ({ useSearch, useStated, $mar }) => {
  const searchAgentName = useSearch.formValue?.search_agent_name;

  const searchSubAgentYn = useSearch.formValue?.search_sub_agent_yn;

  return (
    <Row $mar={$mar || ""} $gap="12px">
      {searchSubAgentYn !== undefined && (
        <Column $gap="4px">
          <FilterTitle>{t("agent.search_agent")}</FilterTitle>
          <Row $gap="4px" $align="center">
            <SelectForm
              name="search_agent_category"
              placeholder={t("common.select")}
              options={agentFilterCondition}
              {...useSearch}
              {...useStated}
            />
            <InputForm type="text" name="search_agent_val" placeholder={t("common.input")} {...useSearch} {...useStated} />
            <EachCheckForm
              $size="var(--s-caption)"
              name="search_sub_agent_yn"
              labelFor="search_sub_agent_yn"
              options={checkboxYNEnum}
              labelName={t("agent.include_sub_agent")}
              {...useSearch}
            />
          </Row>
        </Column>
      )}

      {searchAgentName !== undefined && (
        <Column $gap="4px">
          <FilterTitle>{t("agent.name")}</FilterTitle>
          <Row $gap="4px">
            <InputForm type="text" name="search_agent_name" placeholder={t("common.input")} {...useSearch} {...useStated} />
          </Row>
        </Column>
      )}
    </Row>
  );
};

export default AgentSearchFilter;
