import React, { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";

// style import
import { AreaTitle, TextBox } from "style/useTextStyles";
import { Column, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import NoDataTable from "components/common/NoDataTable";
import { LineSection } from "style/useLayoutStyles";
import { t } from "i18next";

const BettingGameResult = ({ transactionId }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const [result, setResult] = useState();

  useEffect(() => {
    setLoading(true);

    const apiUrl = `/v1/user/all/betting/info/${transactionId}`;
    api
      .get(apiUrl, {
        params: {},
      })
      .then((res) => {
        setResult(JSON.parse(res.data?.content?.result_json_data));
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response?.data?.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getOutcomeText = (outcome) => {
    if (result?.external?.detail?.data?.result.outcome === outcome) {
      return result.external.detail.data.result.outcome;
    }
    return "";
  };

  const OutcomeBox = ({ width, outcome }) => (
    <OptionBox $flex="1" $align="center">
      <TextBox $width={width} $line="var(--l-sub)"></TextBox>
      <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
        {getOutcomeText(outcome)}
      </TextBox>
    </OptionBox>
  );

  return (
    <>
      <Column $width="1600px" $height="600px">
        {result?.details?.game?.type === "baccarat" && (
          <Column className="area-box">
            <SubTitleBox>
              <Row $gap="6px" $align="center">
                <AreaTitle>{t("betting.game_result")}</AreaTitle>
              </Row>
            </SubTitleBox>

            <Row $width="100%">
              <OptionBox flex="1" $align="center" $width="100%">
                <Column>
                  <TextBox>{`player ( ${t("betting.score")} : ${result?.external?.detail?.data?.result?.player?.score || "0"} )`}</TextBox>

                  <Row $gap="40px" $pad="40px">
                    {result?.external?.detail?.data?.result?.player?.cards.map((card, index) => (
                      <img key={index} src={`/assets/img/trump/${card}.png`} alt={card} />
                    ))}
                  </Row>
                </Column>
              </OptionBox>

              <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

              <OptionBox flex="1" $align="center" $width="100%">
                <Column>
                  <TextBox>{`banker ( ${t("betting.score")} : ${result?.external?.detail?.data?.result?.banker?.score || "0"} )`}</TextBox>
                  <Row $gap="40px" $pad="40px">
                    {result?.external?.detail?.data?.result?.banker?.cards.map((card, index) => (
                      <img key={index} src={`/assets/img/trump/${card}.png`} alt={card} />
                    ))}
                  </Row>
                </Column>
              </OptionBox>
            </Row>

            <Row>
              <OptionBox $align="center">
                <TextBox width="30px" $line="var(--l-sub)">
                  {t("betting.result")}
                </TextBox>
              </OptionBox>
              <OutcomeBox width="280px" outcome="Player" />
              <OutcomeBox width="180px" outcome="Tie" />
              <OutcomeBox width="60px" outcome="Banker" />
            </Row>
          </Column>
        )}

        {result?.details?.game?.type !== "baccarat" && <NoDataTable />}
      </Column>
    </>
  );
};

export default BettingGameResult;
