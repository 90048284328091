import { useEffect, useState } from "react";
import { t } from "i18next";

import useFormData from "common/useFormData";
import { useCountPerPage, userState1 } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import api from "interceptor/api";
import { getFormatDatetime } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";

import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import CalendarFilter from "components/common/CalendarFilter";
import CommonCategoryTab from "components/common/CommonCategoryTab";
import { InputForm, NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import UserLevelFilter from "components/common/UserLevelFilter";
import UserNameTab from "components/common/UserNameTab";
import UserState2Hover from "components/common/UserState2Hover";
import { dbLogGameInfoUpdate } from "enum/db/useLogEnum";
import { dbUser } from "enum/db/useUserEnum";
import { isEmpty } from "lodash";
import { Column, FilterBox, FormBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { TextBox } from "style/useTextStyles";
import { ErrorMsgForm } from "utils/FormStateUtil";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import UserBettingTab from "components/admin/user/betting/UserBettingTab";

const GameMngPopupForm = ({ item, getTableItem }) => {
  const [tabState, setTabState] = useState(tabEnum[0].key);

  return (
    <>
      <Column className="area-box" $gap="20px">
        <CommonCategoryTab menus={tabEnum} tabEnum={tabState} tabMove={setTabState} />
        {tabState === tabEnum[0].key ? (
          <BasicInfo item={item} />
        ) : tabState === tabEnum[1].key ? (
          <BanUser item={item} />
        ) : tabState === tabEnum[2].key ? (
          <BettingInfo item={item} />
        ) : (
          <UpdateLog item={item} />
        )}
      </Column>
    </>
  );
};

const BasicInfo = ({ item }) => {
  const { openAlert } = useAlert();

  const [modifyState, setModifyState] = useState(false);
  const [initData, setInitData] = useState({});

  useEffect(() => {
    getBasicInfo();
  }, []);

  const showFormAlert = (isSuccess, errorMsg) => {
    openAlert({
      title: isSuccess ? t("common.success") : t("common.fail"),
      message: isSuccess
        ? t("common.save_msg")
        : (
            <Column>
              {errorMsg.map((err) => (
                <ErrorMsgForm errorJson={err} />
              ))}
            </Column>
          ) ?? t("common.save_fail"),
      iconURL: isSuccess ? false : true,
    });
  };

  const modifyStateChange = () => {
    setModifyState(!modifyState);
    useForm.setFormValue(initData);
  };

  const getBasicInfo = () => {
    const apiUrl = `/v1/game-management/detail/${item.no}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,
          create_at: getFormatDatetime(res.data.content.create_at),
          bet_minus_win: (res.data.content.bet_money ?? 0) - (res.data.content.win_money ?? 0),
          income_rate: (!res.data.content.bet_money || !res.data.content.win_money
            ? 0
            : (res.data.content.bet_money / res.data.content.win_money) * 100
          ).toFixed(2),
          use_yn_label: activeStateEnum[res.data.content.use_yn].label,
          rolling_rate_label: res.data.content.rolling_rate,
          losing_rate_label: res.data.content.losing_rate,
        });
        setInitData({
          ...res.data.content,
          create_at: getFormatDatetime(res.data.content.create_at),
          bet_minus_win: (res.data.content.bet_money ?? 0) - (res.data.content.win_money ?? 0),
          income_rate: (!res.data.content.bet_money || !res.data.content.win_money
            ? 0
            : (res.data.content.bet_money / res.data.content.win_money) * 100
          ).toFixed(2),
          use_yn_label: activeStateEnum[res.data.content.use_yn].label,
          rolling_rate_label: res.data.content.rolling_rate,
          losing_rate_label: res.data.content.losing_rate,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const clickSubmitBtn = () => {
    if (useForm.formValue === initData) {
      setModifyState(false);
      return;
    }
    api
      .put(`/v1/game-management/${item.no}`, {
        rolling_rate: useForm.formValue.rolling_rate,
        losing_rate: useForm.formValue.losing_rate,
        use_yn: useForm.formValue.use_yn,
      })
      .then((res) => {
        getBasicInfo();
        setModifyState(false);
      })
      .catch((error) => {
        showFormAlert(false, error.response.data.errors);
        console.error(error);
      });
  };

  const useForm = useFormData({});

  return (
    <Column $gap="40px">
      <Row $gap="60px">
        <Column $width="350px" $gap="20px" $flex="1">
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.game_name")} :
            </TextBox>
            <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.title}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.game_vendor")} :
            </TextBox>
            <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.provider}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.vendor")} :
            </TextBox>
            <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.vendor}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.rolling")} :
            </TextBox>
            {modifyState ? (
              <InputForm width="100%" maxWidth="100%" height={"32px"} name="rolling_rate" {...useForm}></InputForm>
            ) : (
              <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
                {useForm.formValue.rolling_rate_label ?? "0"}
              </TextBox>
            )}
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.losing")} :
            </TextBox>
            {modifyState ? (
              <InputForm width="100%" maxWidth="100%" height={"32px"} name="losing_rate" {...useForm}></InputForm>
            ) : (
              <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
                {useForm.formValue.losing_rate_label ?? "0"}
              </TextBox>
            )}
          </FormBox>
        </Column>
        <Column $width="350px" $gap="20px" $flex="1">
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.total_bet_amount")} :
            </TextBox>
            <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.bet_money?.toLocaleString() ?? "-"}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.total_win_amount")} :
            </TextBox>
            <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.win_money?.toLocaleString() ?? "-"}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.total_bet_win_amount")} :
            </TextBox>
            <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.bet_minus_win?.toLocaleString() ?? "-"}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.income_rate")} :
            </TextBox>
            <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.income_rate ?? "-"}
            </TextBox>
          </FormBox>
        </Column>
      </Row>
      <Row $gap="60px">
        <Column $width="350px" $gap="20px" $flex="1">
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.registered_date")} :
            </TextBox>
            <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.create_at}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.game_type")} :
            </TextBox>
            <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.type}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.game_status")} :
            </TextBox>
            {modifyState ? (
              <SelectForm width="100%" name="use_yn" options={activeStateEnum} {...useForm} top="top"></SelectForm>
            ) : (
              <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
                {useForm.formValue.use_yn_label}
              </TextBox>
            )}
          </FormBox>
        </Column>
        <Column $width="350px" $gap="20px" $flex="1">
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.max_bet_amount")} :
            </TextBox>
            <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.max_bet_money?.toLocaleString() ?? "-"}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.max_win_amount")} :
            </TextBox>
            <TextBox $tAlign="right" $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.max_win_money?.toLocaleString() ?? "-"}
            </TextBox>
          </FormBox>
          <FormBox $align="flex-start">
            <TextBox $width="135px" $minWidth="135px">
              {t("gameManagement.max_win_user_nickname")} :
            </TextBox>
            <TextBox $width="100%" $pad="4px 8px" $border="1px solid #aaa" $white="nowrap">
              {useForm.formValue.max_win_user_nickname ?? "-"}
            </TextBox>
          </FormBox>
        </Column>
      </Row>

      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <Row $gap="10px" $jus="right" $width="100%">
          {modifyState ? (
            <RedBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={modifyStateChange}>
              {t("common.cancel")}
            </RedBtn>
          ) : (
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={modifyStateChange}>
              {t("common.modify")}
            </WhiteBtn>
          )}
          <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={clickSubmitBtn}>
            {t("common.save")}
          </MainBtn>
        </Row>
      </PositionBox>
    </Column>
  );
};

const BanUser = ({ item }) => {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();

  const banUserPopup = () => {
    openPopup({
      title: t("gameManagement.add_ban_user"),
      content: <BanUserPopup gameNo={item.no} getTableItem={getBanUserList} />,
      onClose: getBanUserList,
    });
  };

  const getBanUserList = () => {
    setLoading(true);
    const apiUrl = `/v1/game-management/ban-user/${item.no}`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteBanUser = ({ userNo = null }) => {
    const apiType = api.delete;
    const apiUrl = `/v1/game-management/ban-user/${item.no}`;
    // user_no_list 생성
    const userNoList = userNo ? [userNo] : useTG.data.filter((item) => item.isChecked).map((item) => item.no);
    // URLSearchParams 객체 생성
    const params = new URLSearchParams();
    userNoList.forEach((userNo) => params.append("user_no_list", userNo));
    apiType(`${apiUrl}?${params.toString()}`)
      .then((res) => {
        setLoading(false);
        getBanUserList();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useSearch = useFormData(
    {
      keyword: "",
      search_state: searchStateEnum.round.value,
      start_date: "",
      end_date: "",
    },
    getBanUserList,
    useTG
  );

  // 검색 조건이 변경되면 검색어 초기화 (TextBox 에서 SelectForm 으로 변경될 때 오류 방지)
  useEffect(() => {
    useSearch.setFormValue({
      ...useSearch.formValue,
      keyword: "",
    });
  }, [useSearch.formValue?.search_state]);

  return (
    <Column $width="1200px">
      <Row className="total-count" $gap="4px" $pad="0 0 14px">
        <TextBox $font="var(--f-subB)">{t("gameManagement.total_banned_users")}</TextBox>
        <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
          {useTG.error ? (
            <span>error</span>
          ) : (
            <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
          )}
        </TextBox>
        <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
      </Row>
      <Row $jus="space-between" $width="100%" $align="center" $pad="0 0 14px">
        <RedBtn onClick={deleteBanUser}>{t("gameManagement.remove_ban_user")}</RedBtn>
        <Row $gap="10px" $align="center">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={banUserPopup}>
              {t("gameManagement.add_ban_user")}
            </MainBtn>
          </Row>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>

      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $width="34px">
                  <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                </TableHeader>
                <TableHeader $minWidth="60px" $width="60px">
                  <TextBox>{t("common.no")}</TextBox>
                </TableHeader>
                <TableHeader width="160px">{t("gameManagement.agent")}</TableHeader>
                <TableHeader width="160px">{t("gameManagement.user_id")}</TableHeader>
                <TableHeader width="60px">{t("gameManagement.user_level")}</TableHeader>
                <TableHeader $minWidth="200px" $width="200px">
                  {t("gameManagement.ban_date")}
                </TableHeader>
                <TableHeader $width="100px">{t("gameManagement.user_status")}</TableHeader>
                <TableHeader $width="80px">{t("common.manage")}</TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo?.offset - index;
                    return (
                      <TableRow key={number} className="table-content">
                        <TableDeps>
                          <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                        </TableDeps>
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps>
                          <AgentNameTab
                            no={item.agent_no}
                            code={item.agent_code}
                            name={item.agent_code}
                            type_name={item.type_name}
                          />
                        </TableDeps>
                        <TableDeps>
                          <UserNameTab no={item.no} nickname={item.nickname} />
                        </TableDeps>

                        <TableDeps>
                          <Row $gap="6px" $align="center" $jus="center">
                            <img
                              src={item.img_uuid ? process.env.REACT_APP_IMG_URL + item.img_uuid : ""}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <TextBox>{`${item.level_name || "-"} (${
                              item.lv !== undefined && item.lv !== null ? item.lv : "-"
                            })`}</TextBox>
                          </Row>
                        </TableDeps>
                        <TableDeps>
                          <TextBox
                            className={
                              item.state1 === dbUser.state1.S.value
                                ? "complete"
                                : item.state1 === dbUser.state1.B.value
                                ? "warn"
                                : item.state1 === dbUser.state1.O.value
                                ? "withdraw"
                                : item.state1 === dbUser.state1.W.value
                                ? "normal"
                                : item.state1 === dbUser.state1.T.value
                                ? "test"
                                : ""
                            }
                          >
                            {dbUser.state1[item.state1]?.label || "-"}
                          </TextBox>
                        </TableDeps>
                        <TableDeps>{getFormatDatetime(item.create_at)}</TableDeps>
                        <TableDeps>
                          <Row $width="100%" $jus="center">
                            <DeleteBtn
                              $size="var(--s-table)"
                              $line="var(--l-table)"
                              name={t("gameManagement.remove_ban_user")}
                              eventProps={() => deleteBanUser({ userNo: item?.no })}
                            />
                          </Row>
                        </TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row $jus="space-between" $width="100%" $align="center" $pad="0 0 14px">
          <RedBtn onClick={deleteBanUser}>{t("gameManagement.remove_ban_user")}</RedBtn>
          <Row $gap="10px" $align="center">
            <Row $gap="8px">
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={banUserPopup}>
                {t("gameManagement.add_ban_user")}
              </MainBtn>
            </Row>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Row>
    </Column>
  );
};

const BettingInfo = ({ item }) => {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { openPopup } = usePopup();

  const getBettingInfo = () => {
    setLoading(true);
    const apiUrl = `/v1/game-management/betting/${item.no}`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showBettingDetail = (transaction_id) => {
    openPopup({
      title: t("betting.detail_history"),
      content: UserBettingTab,
      props: {
        transactionId: transaction_id,
      },
    });
  };

  const useSearch = useFormData(
    {
      keyword: "",
      search_state: searchStateEnum.round.value,
      start_date: "",
      end_date: "",
    },
    getBettingInfo,
    useTG
  );

  // 검색 조건이 변경되면 검색어 초기화 (TextBox 에서 SelectForm 으로 변경될 때 오류 방지)
  useEffect(() => {
    useSearch.setFormValue({
      ...useSearch.formValue,
      keyword: "",
    });
  }, [useSearch.formValue?.search_state]);

  return (
    <Column $width="1200px">
      <FilterBox $pad="12px">
        <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
          <Column $gap="4px">
            <FilterTitle>{t("gameManagement.search_filter")}</FilterTitle>
            <Row $gap="4px">
              <SelectForm
                name="search_state"
                placeholder={t("common.all_state")}
                options={searchStateEnum}
                {...useSearch}
                {...useStated}
              />
              {useSearch.formValue.search_state === searchStateEnum.transaction_type.value ? (
                <SelectForm
                  name="keyword"
                  placeholder={t("common.all_state")}
                  options={transactionTypeEnum}
                  {...useSearch}
                  {...useStated}
                />
              ) : (
                <InputForm maxWidth="200px" name="keyword" placeholder={t("common.enter_value")} {...useSearch} {...useStated} />
              )}
            </Row>
          </Column>
          <Column $gap="4px">
            <FilterTitle>{t("common.date")}</FilterTitle>
            <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useSearch} end />
          </Column>
        </Row>
        <Row $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
          </MainBtn>
          <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
          </WhiteBtn>
        </Row>
      </FilterBox>

      <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
        <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
          <TextBox $font="var(--f-subB)">{t("gameManagement.total_betting_info")}</TextBox>
          <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
            )}
          </TextBox>
          <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
        </Row>
        <SelectForm
          {...useTG}
          name="countPerPage"
          placeholder={useCountPerPage[0].label}
          size={"var(--s-table)"}
          line={"var(--l-table)"}
          options={useCountPerPage}
          formValue={useTG.extendData}
        />
      </Row>

      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $minWidth="60px" $width="60px">
                  <TextBox>{t("common.no")}</TextBox>
                </TableHeader>
                <TableHeader $minWidth="80px" $width="80px">
                  {t("gameManagement.type")}
                </TableHeader>
                <TableHeader $minWidth="200px" $width="200px">
                  {t("gameManagement.occurred_at")}
                </TableHeader>
                <TableHeader $minWidth="200px" $width="200px">
                  {t("gameManagement.round_id")}
                </TableHeader>
                <TableHeader $minWidth="130px" $width="130px">
                  {t("gameManagement.user_id")}
                </TableHeader>
                <TableHeader $minWidth="130px" $width="130px">
                  {t("gameManagement.amount")}
                </TableHeader>
                <TableHeader $minWidth="80px" $width="80px">
                  {t("gameManagement.game_datail")}
                </TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo?.offset - index;
                    return (
                      <TableRow key={number} className="table-content">
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps>
                          <TextBox $tAlign="left">
                            {item.transaction_type ? transactionTypeEnum[item.transaction_type].label : "-"}
                          </TextBox>
                        </TableDeps>
                        <TableDeps>{getFormatDatetime(item.create_at)}</TableDeps>
                        <TableDeps>{item.round ?? "-"}</TableDeps>
                        <TableDeps>
                          <Row $gap="6px" $align="center">
                            <UserNameTab no={item.user_no} nickname={item.user_nickname} refresh={getBettingInfo} />
                          </Row>
                        </TableDeps>
                        <TableDeps $tAlign="right">{item.amount?.toLocaleString()}</TableDeps>
                        <TableDeps $tAlign="center">
                          <Row $width="100%" $jus="center">
                            <ModifyBtn
                              $size="var(--s-table)"
                              $line="var(--l-table)"
                              name={t("gameManagement.game_datail")}
                              eventProps={() => showBettingDetail(item.transaction_id)}
                            />
                          </Row>
                        </TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row />
        <Row $gap="8px">
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            top="top"
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
    </Column>
  );
};

const UpdateLog = ({ item }) => {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const getUpdateLog = () => {
    setLoading(true);
    const apiUrl = `/v1/game-management/update-log/${item.no}`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const useSearch = useFormData(
    {
      start_date: "",
      end_date: "",
    },
    getUpdateLog,
    useTG
  );

  return (
    <Column>
      <FilterBox $pad="12px">
        <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
          <Column $gap="4px">
            <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
              <Column $gap="4px">
                <FilterTitle>{t("common.date")}</FilterTitle>
                <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useSearch} end />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
          </MainBtn>
          <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
          </WhiteBtn>
        </Row>
      </FilterBox>

      <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
        <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
          <TextBox $font="var(--f-subB)">{t("gameManagement.total_update_logs")}</TextBox>
          <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
            )}
          </TextBox>
          <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
        </Row>
        <SelectForm
          {...useTG}
          name="countPerPage"
          placeholder={useCountPerPage[0].label}
          size={"var(--s-table)"}
          line={"var(--l-table)"}
          options={useCountPerPage}
          formValue={useTG.extendData}
        />
      </Row>

      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $minWidth="60px" $width="60px">
                  <TextBox>{t("common.no")}</TextBox>
                </TableHeader>
                <TableHeader $minWidth="130px" $width="130px">
                  {t("gameManagement.update_date")}
                </TableHeader>
                <TableHeader $minWidth="200px" $width="200px">
                  {t("gameManagement.updated_by")}
                </TableHeader>
                <TableHeader $minWidth="200px" $width="200px">
                  {t("gameManagement.update_type")}
                </TableHeader>
                <TableHeader $minWidth="130px" $width="130px">
                  {t("gameManagement.before_update")}
                </TableHeader>
                <TableHeader $minWidth="130px" $width="130px">
                  {t("gameManagement.after_update")}
                </TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo?.offset - index;
                    return (
                      <TableRow key={number} className="table-content">
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps>{getFormatDatetime(item.update_at ?? "-") ?? "-"}</TableDeps>
                        <TableDeps>{item.nickname}</TableDeps>
                        <TableDeps>
                          {item.updated_type === dbLogGameInfoUpdate.updated_type.U.value
                            ? dbLogGameInfoUpdate.updated_type.U.label
                            : item.updated_type === dbLogGameInfoUpdate.updated_type.R.value
                            ? dbLogGameInfoUpdate.updated_type.R.label
                            : dbLogGameInfoUpdate.updated_type.L.label}
                        </TableDeps>
                        <TableDeps>{item.before_update ?? "-"}</TableDeps>
                        <TableDeps>{item.after_update ?? "-"}</TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row />
        <Row $gap="8px">
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            top="top"
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
    </Column>
  );
};

const BanUserPopup = ({ gameNo }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = `/v1/game-management/not-ban-user/${gameNo}"`;
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const postBanUser = ({ userNo = null }) => {
    const apiType = api.post;
    const apiUrl = `/v1/game-management/ban-user/${gameNo}`;
    apiType(apiUrl, {
      user_no_list: userNo ? [userNo] : useTG.data.filter((item) => item.isChecked).map((item) => item.no),
    })
      .then((res) => {
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      state1: "",
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",
      lv: "",
      nickname: "",
    },
    getTableItem,
    useTG
  );
  return (
    <Column $width="1200px">
      <FilterBox $pad="12px">
        <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
          <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

          <Column $gap="4px">
            <FilterTitle>{t("gameManagement.user")}</FilterTitle>
            <InputForm type="text" name="nickname" placeholder={t("common.input")} {...useSearch} />
          </Column>

          <Column $gap="4px">
            <FilterTitle>{t("gameManagement.user_status")}</FilterTitle>
            <SelectForm name="state1" placeholder={t("common.all")} options={userState1} {...useSearch} {...useStated} />
          </Column>

          <UserLevelFilter useSearch={useSearch} useStated={useStated} />
        </Row>

        <Row $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
          </MainBtn>
          <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
          </WhiteBtn>
        </Row>
      </FilterBox>

      <Row $jus="space-between" $align="center" $pad="14px 0">
        <Row className="total-count" $gap="4px">
          <TextBox $font="var(--f-subB)">{t("gameManagement.total_search_users")}</TextBox>
          <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
            )}
          </TextBox>
          <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
        </Row>
        <Row $align="center" $gap="4px">
          <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={postBanUser}>
            {t("gameManagement.add_ban_user")}
          </MainBtn>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>

      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $width="34px">
                  <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                </TableHeader>
                <TableHeader $width="60px">{t("common.no")}</TableHeader>
                <TableHeader>{t("gameManagement.agent")}</TableHeader>
                <TableHeader>{t("gameManagement.user_id")}</TableHeader>
                <TableHeader>{t("gameManagement.user_level")}</TableHeader>
                <TableHeader $width="90px">{t("gameManagement.user_status")}</TableHeader>
                <TableHeader $width="90px">{t("gameManagement.add_ban_user")}</TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo.offset - index;
                    return (
                      <TableRow key={item.no} className="table-content">
                        <TableDeps>
                          <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                        </TableDeps>
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps $tAlign="left">
                          <AgentNameTab no={item.agent_no} code={item.code} name={item.code} type_name={item.type} />
                        </TableDeps>
                        <TableDeps $tAlign="left">
                          <Row $gap="6px" $align="center">
                            <UserState2Hover state2List={item.state2_arr} />
                            <UserNameTab no={item.no} nickname={item.nickname} useState2Hover={true} refresh={getTableItem} />
                          </Row>
                        </TableDeps>
                        <TableDeps>
                          <Row $gap="6px" $align="center" $jus="center">
                            <img
                              src={item.img_uuid ? process.env.REACT_APP_IMG_URL + item.img_uuid : ""}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <TextBox>{`${item.level_name || "-"} (${
                              item.lv !== undefined && item.lv !== null ? item.lv : "-"
                            })`}</TextBox>
                          </Row>
                        </TableDeps>
                        <TableDeps>
                          <TextBox
                            className={
                              item.state1 === dbUser.state1.S.value
                                ? "complete"
                                : item.state1 === dbUser.state1.B.value
                                ? "warn"
                                : item.state1 === dbUser.state1.O.value
                                ? "withdraw"
                                : item.state1 === dbUser.state1.W.value
                                ? "normal"
                                : item.state1 === dbUser.state1.T.value
                                ? "test"
                                : ""
                            }
                          >
                            {dbUser.state1[item.state1]?.label || "-"}
                          </TextBox>
                        </TableDeps>
                        <TableDeps $width="90px">
                          <Row $align="center">
                            <ModifyBtn
                              $size="var(--s-table)"
                              $line="var(--l-table)"
                              name={t("gameManagement.add_ban_user")}
                              eventProps={() => postBanUser({ userNo: item?.no })}
                            ></ModifyBtn>
                          </Row>
                        </TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}

      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row />
        <Row $align="center" $gap="4px">
          <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={postBanUser}>
            {t("gameManagement.add_ban_user")}
          </MainBtn>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
    </Column>
  );
};

const tabEnum = [
  {
    title: t("gameManagement.basic_info"),
    key: 1,
  },
  {
    title: t("gameManagement.ban_user"),
    key: 2,
  },
  {
    title: t("gameManagement.betting_info"),
    key: 3,
  },
  {
    title: t("gameManagement.update_log"),
    key: 4,
  },
];

const activeStateEnum = {
  Y: {
    label: t("common.use_y"),
    value: "Y",
    key: 1,
  },
  N: {
    label: t("common.use_n"),
    value: "N",
    key: 2,
  },
};

const searchStateEnum = {
  round: {
    label: t("gameManagement.round_id"),
    value: "round",
    key: 0,
  },
  user_nickname: {
    label: t("gameManagement.user_id"),
    value: "user_nickname",
    key: 1,
  },
  transaction_type: {
    label: t("gameManagement.type"),
    value: "transaction_type",
    key: 2,
  },
};

const transactionTypeEnum = {
  B: {
    label: t("gameManagement.betting"),
    value: "B",
    key: 1,
  },
  W: {
    label: t("gameManagement.win"),
    value: "W",
    key: 2,
  },
  C: {
    label: t("gameManagement.cancel"),
    value: "C",
    key: 3,
  },
};

export default GameMngPopupForm;
