import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import {
  depositAndwithdrawalManageEnum,
  gameManagerEnum,
  menu,
  operateManagerEnum,
  siteConfigManagerEnum,
  statesAgentEnum,
  statsManagerEnum,
  userManagerEnum,
} from "enum/admin/useAdminMenuEnum";
import { IconLink, FlexBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import { Between, Column, Row } from "style/custom/useCustomLayout";
import { useCommonState } from "contexts/useCommonState";
import { t } from "i18next";
import useMenuFavorites from "common/useMenuFavorites";

const MenuListBox = styled.div`
  width: 200px;

  top: 0;
  position: sticky;
  height: 100%;
`;

const Aside = styled.aside`
  width: 200px;
  min-width: 200px;

  gap: 28px;
  display: flex;
  position: relative;
  height: 100%;

  overflow: hidden;
  color: #fff;
  background: var(--c-white);
`;

const MenuUL = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: #fbfbfb;

  position: relative;
  top: 0px;
  margin-top: 0;
  margin-bottom: 5px;
  padding-left: 0;

  overflow: hidden;
`;

const MenuList = styled.li`
  padding: 6px 20px 6px 48px;

  cursor: pointer;
  color: var(--c-gray-700);
  font-size: var(--s-sub);
  line-height: var(--l-sub);
  font-family: var(--f-subB);

  &:hover,
  &.active {
    color: var(--c-mint-600);
    position: relative;
  }
  &:last-child {
    margin-bottom: 6px;
  }
`;

const TitleRow = styled(FlexBox)`
  padding: 12px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--c-white);
  width: 100%;
`;

const MenuTitle = styled(TextBox)`
  font-size: var(--s-sub);
  line-height: var(--l-sub);
  font-family: var(--f-subB);
  &.active {
    color: var(--c-mint-600);
  }
`;

const AdminSide = ({ toggleAside, addToFavorites }) => {
  const movePage = useNavigate();
  const { pathname } = useLocation();
  const { roleType } = useCommonState();
  const { favorites, deleteFavorites, insertFavorites } = useMenuFavorites();

  const [dropdownState, setDropdownState] = useState({});

  const baseMenuLists = [
    { title: t("menu.user_manager"), enums: userManagerEnum, icon: "menu_user", type: ["A", "G", "D"] },
    { title: t("menu.states_agent"), enums: statesAgentEnum, icon: "menu_agent", type: ["A", "G", "D"] },
    {
      title: t("menu.deposit_and_withdrawal_manage"),
      enums: depositAndwithdrawalManageEnum,
      icon: "menu_deposit_withdrawal",
      type: ["A"],
    },
    { title: t("menu.stats_manager"), enums: statsManagerEnum, icon: "menu_statistics", type: ["A", "G", "D"] },
    { title: t("menu.game_manager"), enums: gameManagerEnum, icon: "menu_game", type: ["A", "G", "D"] },
    { title: t("menu.operate_manager"), enums: operateManagerEnum, icon: "menu_cs", type: ["A"] },
    { title: t("menu.site_config_manager"), enums: siteConfigManagerEnum, icon: "menu_setting", type: ["A"] },
  ];

  const handleDropdownToggle = (title) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [title]: !prevState[title],
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const trimmedPathname = pathname.startsWith("/") ? pathname.substring(1) : pathname;

    baseMenuLists.forEach((menu) => {
      Object.values(menu.enums).forEach((enumItem) => {
        if (enumItem.route === trimmedPathname) {
          setDropdownState((prevState) => ({
            ...prevState,
            [menu.title]: true,
          }));
        }
      });
    });
  }, [pathname]);

  // 메뉴 권한 필터링
  const menuLists = baseMenuLists.filter((menu) => menu.type.includes(roleType));

  const isMenuFavorites = (menuEnum) => {
    return favorites.some((favorite) => favorite?.menu_code === menuEnum);
  };

  return (
    <>
      {toggleAside && (
        <>
          <Aside>
            <MenuListBox $direct="column">
              {Object.values(menu).map((menu) => (
                <Between $cursor="pointer" $pad="12px 20px" key={menu.enum} onClick={() => movePage("/betting")}>
                  <Row $gap="8px" $align="center" $width="100%">
                    <IconLink $url={`/assets/svg/${menu.route}.svg`} $width="20px" $height="20px"></IconLink>
                    <MenuTitle className={pathname === `/${menu.route}` ? "active" : ""}>{t(menu.title)}</MenuTitle>
                  </Row>
                  {isMenuFavorites(menu.enum) ? (
                    <IconLink
                      $trans="rotate(-180deg)"
                      $url="/assets/svg/star.svg"
                      $width="22px"
                      $height="22px"
                      onClick={() => deleteFavorites(menu.enum)}
                    ></IconLink>
                  ) : (
                    <IconLink
                      $trans="rotate(-180deg)"
                      $url="/assets/svg/star_off.svg"
                      $width="22px"
                      $height="22px"
                      onClick={() => insertFavorites(menu.enum)}
                    ></IconLink>
                  )}
                </Between>
              ))}

              {menuLists.map((main) => {
                const isDropdown = dropdownState[main.title];
                return (
                  <Column key={main.title}>
                    <TitleRow onClick={() => handleDropdownToggle(main.title)}>
                      <Row $gap="8px" $align="center">
                        <IconLink $url={`/assets/svg/${main.icon}.svg`} $width="20px" $height="20px"></IconLink>
                        <MenuTitle>{main.title}</MenuTitle>
                      </Row>

                      <IconLink
                        className={isDropdown && "active"}
                        $trans="rotate(-180deg)"
                        $url="/assets/svg/menu_toggle.svg"
                        $width="22px"
                        $height="22px"
                      ></IconLink>
                    </TitleRow>
                    {isDropdown && (
                      <MenuUL>
                        {Object.values(main.enums).map((menu) => {
                          const isActive = pathname === `/${menu.route}` || (pathname === "/" && menu.route === "");
                          const shouldDisplay = menu.type ? menu.type === roleType : true;

                          return shouldDisplay ? (
                            <MenuList
                              key={menu.key}
                              className={isActive ? "active" : ""}
                              onClick={() => {
                                movePage(`/${menu.route}`);
                                addToFavorites(menu);
                              }}
                            >
                              <Between>
                                <TextBox
                                  $font="var(--f-subB)"
                                  $size="var(--s-sub)"
                                  $line="var(--l-sub)"
                                  $color={isActive ? "var(--c-mint-600)" : "var(--c-gray-700)"}
                                >
                                  {menu.title}
                                </TextBox>

                                {isMenuFavorites(menu.enum) ? (
                                  <IconLink
                                    $trans="rotate(-180deg)"
                                    $url="/assets/svg/star.svg"
                                    $width="22px"
                                    $height="22px"
                                    onClick={() => deleteFavorites(menu.enum)}
                                  ></IconLink>
                                ) : (
                                  <IconLink
                                    $trans="rotate(-180deg)"
                                    $url="/assets/svg/star_off.svg"
                                    $width="22px"
                                    $height="22px"
                                    onClick={() => insertFavorites(menu.enum)}
                                  ></IconLink>
                                )}
                              </Between>
                            </MenuList>
                          ) : null;
                        })}
                      </MenuUL>
                    )}
                  </Column>
                );
              })}
            </MenuListBox>
          </Aside>
        </>
      )}
    </>
  );
};

export default AdminSide;
