
import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import { t } from "i18next";
import api from "interceptor/api";
import { Column } from "style/custom/useCustomLayout";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";

const UserRollingPointInfo = ({ date, period, search }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = `/v1/statistics/user-point/info/rolling`;

    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      ...search.formValue,
      date: date,
      period: period
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column className="area-box" $gap="48px" $minWidth="1200px">
        <Column $width="100%">
          {useTG.error ? (
            <span>error</span>
          ) : (
            <>
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader>{t("pointStatistics.total_rolling")}</TableHeader>
                    <TableHeader>{t("pointStatistics.table_game_rolling")}</TableHeader>
                    <TableHeader>{t("pointStatistics.slot_game_rolling")}</TableHeader>
                    <TableHeader>{t("pointStatistics.mini_game_rolling")}</TableHeader>
                    <TableHeader>{t("pointStatistics.sports_game_rolling")}</TableHeader>
                    <TableHeader>{t("pointStatistics.recommend_rolling_amount")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo.offset - index;

                        const rollingAmount = item.total_rolling_amount ? parseInt(item.total_rolling_amount) : 0;
                        const recommendRollingAmount = item.total_recommend_rolling ? parseInt(item.total_recommend_rolling) : 0;
                        const rollingTotal = rollingAmount + recommendRollingAmount;
                        const tableGameRollingAmount = item.total_table_game_rolling ? parseInt(item.total_table_game_rolling) : 0;
                        const slotGameRollingAmount = item.total_slot_game_rolling ? parseInt(item.total_slot_game_rolling) : 0;
                        const miniGameRollingAmount = item.total_mini_game_rolling ? parseInt(item.total_mini_game_rolling) : 0;
                        const sportsGameRollingAmount = item.total_sports_game_rolling ? parseInt(item.total_sports_game_rolling) : 0;

                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps $tAlign="right">{rollingTotal ? `${rollingTotal.toLocaleString()}(P)` : "-"}</TableDeps>
                            <TableDeps $tAlign="right">{tableGameRollingAmount ? `${tableGameRollingAmount.toLocaleString()}(P)` : "-"}</TableDeps>
                            <TableDeps $tAlign="right">{slotGameRollingAmount ? `${slotGameRollingAmount.toLocaleString()}(P)` : "-"}</TableDeps>
                            <TableDeps $tAlign="right">{miniGameRollingAmount ? `${miniGameRollingAmount.toLocaleString()}(P)` : "-"}</TableDeps>
                            <TableDeps $tAlign="right">{sportsGameRollingAmount ? `${sportsGameRollingAmount.toLocaleString()}(P)` : "-"}</TableDeps>
                            <TableDeps $tAlign="right">{recommendRollingAmount ? `${recommendRollingAmount.toLocaleString()}(P)` : "-"}</TableDeps>
                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
              {useTG && useTG.data?.length === 0 && <NoDataTable />}
            </>
          )}
        </Column>
      </Column>
    </>
  );
};


export default UserRollingPointInfo;
