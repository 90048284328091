/** @format */
import { useState } from "react";

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

import { usePopup } from "contexts/useWindow";
// 셀렉트 벨류 constant
import { useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { EachCheckForm, InputForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, LineSection, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import UserLevelFilter from "components/common/UserLevelFilter";
import UserNameTab from "components/common/UserNameTab";
import UserState2Hover from "components/common/UserState2Hover";
import { useCommonState } from "contexts/useCommonState";
import { dbUser } from "enum/db/useUserEnum";
import { checkboxYNEnum } from "enum/useCommonEnum";
import { t } from "i18next";
import { getFormatDatetime } from "utils/date/DateUtil";
import AllUserForm from "./AllUserForm";

const AllUser = () => {
  const useStated = useStateData();
  const { setLoading, isAdmin } = useCommonState();

  const { openPopup } = usePopup();

  const [userTotalPoint, setUserTotalPoint] = useState(0);
  const [userTotalMoney, setUserTotalMoney] = useState(0);

  const showNewUserRegistrationPopup = () => {
    openPopup({
      title: t("user.user_regis"),
      content: AllUserForm,
      props: {
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/user/all/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setUserTotalPoint(res.data.userTotalPoint);
        setUserTotalMoney(res.data.userTotalMoney);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_state1: "",

      search_manage_user_yn: "Y",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.user_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.all_user")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder="입력" {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.search_user_state")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />
            <Row $pad="28px 0 0 0">
              <EachCheckForm
                $size="var(--s-caption)"
                name="search_manage_user_yn"
                labelFor="search_manage_user_yn"
                options={checkboxYNEnum}
                labelName={t("filter.include_manage_user")}
                {...useSearch}
              />
            </Row>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-partner" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("user.total_user_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? (
                  <span>error</span>
                ) : (
                  <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords : 0}</>
                )}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("user.total_user_money_balance")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{userTotalMoney?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.won")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("user.total_user_point_balance")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{userTotalPoint?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.won")}</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            {isAdmin ? (
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showNewUserRegistrationPopup()}>
                {t("user.user_regis")}
              </MainBtn>
            ) : null}

            <SelectForm
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader>{t("user.user")}</TableHeader>
                  <TableHeader>{t("user.user_level")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_state")}</TableHeader>
                  <TableHeader $width="60px">{t("user.join_code")}</TableHeader>
                  <TableHeader>{t("user.point")}</TableHeader>
                  <TableHeader>{t("user.money")}</TableHeader>
                  <TableHeader>{t("user.total_deposit")}</TableHeader>
                  <TableHeader>{t("user.total_withdrawal")}</TableHeader>
                  <TableHeader>{t("user.net_profit_loss")}</TableHeader>
                  <TableHeader $width="130px">{t("user.last_login_at")}</TableHeader>
                  <TableHeader $width="90px">{t("user.total_logins")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      const accountInOutTotal = (item.account_in_money || 0) - (item.account_out_money || 0);
                      const accountInOutClassName =
                        accountInOutTotal > 0 ? "complete" : accountInOutTotal < 0 ? "warn" : "";
                      return (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>
                          <TableDeps $tAlign="left">
                            <Row $gap="6px" $align="center">
                              <UserState2Hover state2List={item.state2_arr} />
                              <UserNameTab no={item.no} nickname={item.nickname} refresh={getTableItem} />
                            </Row>
                          </TableDeps>
                          <TableDeps>
                            <Row $gap="6px" $align="center" $jus="center">
                              <img
                                src={item.img_uuid ? process.env.REACT_APP_IMG_URL + item.img_uuid : ""}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <TextBox>{`${item.level_name || "-"} (${item.lv !== undefined && item.lv !== null ? item.lv : "-"})`}</TextBox>
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <TextBox
                              className={
                                item.state1 === dbUser.state1.S.value
                                  ? "complete"
                                  : item.state1 === dbUser.state1.B.value
                                    ? "warn"
                                    : item.state1 === dbUser.state1.O.value
                                      ? "withdraw"
                                      : item.state1 === dbUser.state1.W.value
                                        ? "normal"
                                        : item.state1 === dbUser.state1.T.value
                                          ? "test"
                                          : ""
                              }
                            >
                              {dbUser.state1[item.state1]?.label || "-"}
                            </TextBox>
                          </TableDeps>

                          <TableDeps>{item.join_code || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{(item.point?.toLocaleString() || "0") + "(P)"}</TableDeps>
                          <TableDeps $tAlign="right">{(item.money?.toLocaleString() || "0") + "(C)"}</TableDeps>
                          <TableDeps $tAlign="right">
                            {(item.account_in_money?.toLocaleString() || "0") + "(C)"}
                          </TableDeps>
                          <TableDeps $tAlign="right">
                            {(item.account_out_money?.toLocaleString() || "0") + "(C)"}
                          </TableDeps>
                          <TableDeps $tAlign="right">
                            <TextBox className={accountInOutClassName}>
                              {accountInOutTotal.toLocaleString(undefined, {
                                signDisplay: "exceptZero",
                              }) + "(C)"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps $width="120px">{getFormatDatetime(item.last_login_at) || "-"}</TableDeps>
                          <TableDeps $width="100px" $tAlign="right">{item.login_count || 0}</TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          {isAdmin ? (
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showNewUserRegistrationPopup()}>
              {t("user.user_regis")}
            </MainBtn>
          ) : null}

          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default AllUser;
