import React, { useEffect } from "react";

import useFormData from "common/useFormData";
import { AreaTitle, SubText } from "style/useTextStyles";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import { getFormatDatetime } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { dbAgentCashChargeHistory } from "enum/db/useAgentEnum";
import { chargeCalendarFilter, useCountPerPage } from "constants/select/useValue";
import { addAllFromEnum } from "utils/common/EnumUtil";
import useStateData from "common/useStateData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import { statesAgentEnum } from "enum/admin/useAdminMenuEnum";
import { BlockBtn, ModifyBtn } from "components/common/TableStatus";
import AgentChargeInfo from "./AgentChargeInfo";
import { useTranslation } from "react-i18next";
import AgentChargeStateBlockForm from "./AgentChargeStateBlockForm";
import AgentChargeForm from "./AgentChargeForm";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import AgentTypeFilter from "components/common/AgentTypeFilter";
import CalendarFilter from "components/common/CalendarFilter";
import AgentNameTab from "components/common/AgentNameTab";

const AgentChargeList = () => {
  const useStated = useStateData();

  const { setLoading, isAdmin, isAgent } = useCommonState();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/cash-charge`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "acch.no-desc",
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_state: "",
      search_name: "",
      search_charge_id: "",

      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",
      search_agent_type: "",

      search_calendar_category: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );
  const getCheckedAgentChargeArr = () => {
    return useTG.data.filter((item) => item.isChecked);
  };

  const showUpdateStateAgentChargeConditionFail = () => {
    openAlert({
      title: t("charge.only_wait"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showUpdateAgentAdminsStatePopup = () => {
    const historyNoArr = getCheckedAgentChargeArr();

    const hasInvalidState = historyNoArr.some((item) => item.state !== dbAgentCashChargeHistory.state.D.value);

    if (hasInvalidState) {
      showUpdateStateAgentChargeConditionFail();
      return false;
    }

    if (historyNoArr.length > 0) {
      openPopup({
        title: t("charge.block_state"),
        content: AgentChargeStateBlockForm,
        height: "835px",
        props: {
          historyNoArr: historyNoArr.map((item) => item.no),
          getTableItem,
        },
      });
    } else {
      showNoSelectAgentFail();
    }
  };

  const showCompeteStateSuccessAlert = () => {
    openAlert({
      title: t("charge.update_complete"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showCompleteStateFailAlert = () => {
    openAlert({
      title: t("charge.update_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showNoSelectAgentFail = () => {
    openAlert({
      title: t("charge.no_select"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showAgentChargeInfoPopup = ({ no, state }) => {
    const title = `${t("charge.charge")} ${
      state === dbAgentCashChargeHistory.state.D.value ? t("common.manage") : t("common.history")
    }`;

    openPopup({
      title,
      content: AgentChargeInfo,
      props: {
        no,
        getTableItem,
      },
    });
  };
  const showChargeCompleteConfirm = () => {
    const checkedArr = getCheckedAgentChargeArr();

    const hasInvalidState = checkedArr.some((item) => item.state !== dbAgentCashChargeHistory.state.D.value);

    if (hasInvalidState) {
      showUpdateStateAgentChargeConditionFail();
      return false;
    }

    if (checkedArr.length > 0) {
      openConfirm({
        title: t("charge.complete_state"),
        message: t("charge.apply_comfirm"),
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () =>
          updateAgentChargeCompleteState(
            checkedArr.map((item) => item.no),
            dbAgentCashChargeHistory.state.C.value
          ),
      });
    } else {
      showNoSelectAgentFail();
    }
  };

  const updateAgentChargeCompleteState = (history_no, state) => {
    setLoading(true);
    const apiUrl = `v1/agent/cash-charge/states`;
    api
      .put(apiUrl, {
        history_no,
        state,
      })
      .then((res) => {
        showCompeteStateSuccessAlert();
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showCompleteStateFailAlert();
      })
      .finally(() => {
        getTableItem();

        setLoading(false);
      });
  };

  const showAgentFormPopup = () => {
    openPopup({
      title: t("charge.apply"),
      content: AgentChargeForm,
      props: {
        getTableItem,
      },
    });
  };
  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.states_agent")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{statesAgentEnum.AGENT_CHARGE.title}</AreaTitle>
      </AreaHeader>
      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="center">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} />

            <AgentTypeFilter useSearch={useSearch} useStated={useStated} />

            <Column $gap="4px">
              <FilterTitle>{t("charge.state")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_state"
                  placeholder={t("common.all_state")}
                  options={addAllFromEnum(dbAgentCashChargeHistory.state, t("common.all_state"))}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("charge.search_date")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_calendar_category"
                  placeholder={t("common.select")}
                  options={chargeCalendarFilter}
                  {...useSearch}
                  {...useStated}
                />
                <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
              </Row>
            </Column>
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        {isAdmin ? (
          <>
            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-count" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
                </Row>
              </Row>
            </Row>
            <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
              <Row $gap="8px">
                <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showChargeCompleteConfirm}>
                  {`${t("charge.complete_state")}${t("common.process")}`}
                </WhiteBtn>
                <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showUpdateAgentAdminsStatePopup}>
                  {`${t("charge.block_state")}${t("common.process")}`}
                </RedBtn>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>
          </>
        ) : null}

        {isAgent ? (
          <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
            <Row $gap="10px" $align="center">
              <Row className="total-count" $gap="4px">
                <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                  {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
                </TextBox>
                <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
              </Row>
            </Row>

            <Row $gap="8px">
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showAgentFormPopup}>
                {t("charge.apply")}
              </MainBtn>
              <SelectForm
                name="countPerPage"
                placeholder={useCountPerPage[0].label}
                {...useTG}
                size={"var(--s-table)"}
                line={"var(--l-table)"}
                options={useCountPerPage}
                formValue={useCountPerPage.value}
              />
            </Row>
          </Row>
        ) : null}

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  {isAdmin ? (
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                  ) : null}
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="180px">{t("agent.code")} </TableHeader>
                  <TableHeader $width="180px">{t("agent.agent")} </TableHeader>
                  <TableHeader $width="200px">{t("charge.demand_date")}</TableHeader>
                  <TableHeader $width="200px">{t("charge.demand_amount")}</TableHeader>
                  <TableHeader $width="200px">{t("charge.actual_amount")}</TableHeader>
                  <TableHeader $width="200px">{t("charge.actual_date")} </TableHeader>
                  <TableHeader $width="90px">{t("charge.state")}</TableHeader>
                  <TableHeader>{t("charge.charge_admin")}</TableHeader>
                  <TableHeader $width="100px">{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow
                      key={item.no}
                      className={
                        item.state === dbAgentCashChargeHistory.state.C.value
                          ? "complete"
                          : item.state === dbAgentCashChargeHistory.state.B.value
                          ? "warn"
                          : "wait"
                      }
                    >
                      {isAdmin ? (
                        <TableDeps>
                          <NoLabelCheckForm eventType="each" keys={item.no} base={useTG} eachData={item} />
                        </TableDeps>
                      ) : null}

                      <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>

                      <TableDeps $tAlign="left">{item.code}</TableDeps>
                      <TableDeps $tAlign="left">
                        <AgentNameTab no={item.agent_no} code={item.code} name={item.name} type_name={item.type_name} />
                      </TableDeps>
                      <TableDeps>
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {getFormatDatetime(item.demand_date) || "-"}
                        </TextBox>
                      </TableDeps>
                      <TableDeps $tAlign="right">{(item?.demand_amount || 0).toLocaleString()}</TableDeps>

                      <TableDeps $tAlign={item.state !== dbAgentCashChargeHistory.state.D.value ? "right" : "center"}>
                        {item.state !== dbAgentCashChargeHistory.state.D.value
                          ? (item?.actual_amount || 0)?.toLocaleString()
                          : "-"}
                      </TableDeps>

                      <TableDeps>
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {(() => {
                            let datetime = "-";
                            const { state, actual_date, modify_at } = item;

                            const { C, B } = dbAgentCashChargeHistory.state;

                            switch (state) {
                              case C.value:
                                datetime = getFormatDatetime(actual_date);
                                break;
                              case B.value:
                                datetime = getFormatDatetime(modify_at);
                                break;

                              default:
                                break;
                            }

                            return datetime;
                          })()}
                        </TextBox>
                      </TableDeps>

                      <TableDeps>
                        <TextBox
                          className={
                            item.state === dbAgentCashChargeHistory.state.C.value
                              ? "complete"
                              : item.state === dbAgentCashChargeHistory.state.B.value
                              ? "warn"
                              : "wait"
                          }
                        >
                          {dbAgentCashChargeHistory.state[item.state]?.label}
                        </TextBox>
                      </TableDeps>
                      <TableDeps $tAlign="left">{item?.admin_no ? `${item?.admin_name} (${item?.admin_no})` : "-"}</TableDeps>
                      <TableDeps>
                        <Row $width="100%" $jus="center" $gap="6px">
                          {item.state === dbAgentCashChargeHistory.state.D.value ? (
                            isAdmin ? (
                              <BlockBtn
                                name={`${t("charge.charge")} ${t("common.manage")}`}
                                eventProps={() => showAgentChargeInfoPopup(item)}
                              />
                            ) : (
                              "-"
                            )
                          ) : (
                            <ModifyBtn name={t("charge.detail_list")} eventProps={() => showAgentChargeInfoPopup(item)} />
                          )}
                        </Row>
                      </TableDeps>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        {isAdmin ? (
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>

            <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
              <Row $gap="8px">
                <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showChargeCompleteConfirm}>
                  {`${t("charge.complete_state")}${t("common.process")}`}
                </WhiteBtn>
                <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showUpdateAgentAdminsStatePopup}>
                  {`${t("charge.block_state")}${t("common.process")}`}
                </RedBtn>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                  top="top"
                />
              </Row>
            </Row>
          </Row>
        ) : null}

        {isAgent ? (
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={showAgentFormPopup}>
              {t("charge.apply")}
            </MainBtn>
            <SelectForm
              top="top"
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        ) : null}
      </Column>
    </>
  );
};

export default AgentChargeList;
