import React, { useEffect } from "react";
import useStateData from "common/useStateData";
import useFormData from "common/useFormData";
import api from "interceptor/api";

import { TextBox } from "style/useTextStyles";
import { PositionBox } from "style/useLayoutStyles";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { InputForm, SelectForm, ToggleButtonForm } from "components/common/CommonForm";

import { useAlert, usePopup } from "contexts/useWindow";
import { dbAgentConfig } from "enum/db/useAgentEnum";
import { validationTypeEnum } from "enum/useCommonEnum";
import { t } from "i18next";

const AgentForm = ({ item, isSite, refreshAgentAdmin }) => {
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    useStated.setErrorData([], true);
    if (isSite) {
      getSiteInfo();
    } else {
      getAgentInfo();
    }
  }, []);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showConditionFailAlert(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showConditionFailAlert = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn:t("common.check"),
    });
  };
  const getAgentInfo = () => {
    const apiUrl = `/v1/agent/info/form/${item}`;
    api
      .get(apiUrl, {})
      .then((res) => {
        const { type } = res.data.content;
        if (!type) {
          showFailAlert();
          closePopup();
        }
        useForm.setFormValue({
          ...res.data.content,
        });
      })
      .catch((err) => {});
  };

  const getSiteInfo = () => {
    const apiUrl = "/v1/agent/info/site-info-form";

    api
      .get(apiUrl, {})
      .then((res) => {
        const { type } = res.data.content;
        if (!type) {
          showFailAlert();
          closePopup();
        }
        useForm.setFormValue({
          ...res.data.content,
          parent_type_name: t("agent.head_agent"),
        });
      })
      .catch((err) => {});
  };

  const showFormSuccessAlert = () => {
    openAlert({
      title: t("common.add_msg"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: "확인",
    });
  };

  const showFormFailAlert = () => {
    openAlert({
      title: t("common.add_fail"),
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showFailAlert = () => {
    openAlert({
      title: t("agent.regis_sub_agent_fail"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const useForm = useFormData({
    parent_code: "",
    parent_name: "",
    parent_type: "",
    type: "",
    code: "",

    id: "",
    phone: "",
    password: "",
    re_password: "",
    join_code: "",

    agent_commission_yn: "Y",
    agent_commission_type: "T",

    casino_rolling_rate: 0,
    casino_losing_rate: 0,
    slot_rolling_rate: 0,
    slot_losing_rate: 0,
    mini_game_rolling_rate: 0,
    mini_game_losing_rate: 0,
    sports_rolling_rate: 0,
    sports_losing_rate: 0,
  });

  /**
   * 중복체크
   * @param {String} key
   */
  const checkDuplicated = (key) => {
    const apiUrl = `/v1/agent/info/duplicate/${key}`;

    api
      .get(apiUrl, {
        params: {
          ...useForm.formValue,
        },
      })
      .then((res) => {
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        // showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const insertAgent = () => {
    const apiUrl = "/v1/agent/info";
    api
      .post(apiUrl, {
        ...useForm.formValue,
      })
      .then((res) => {
        showFormSuccessAlert();
        refreshAgentAdmin();
        closePopup();
      })
      .catch((err) => {
        showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };
  return (
    <>
      <Column className="area-box" $gap="20px" $width="700px">
        <FormBox $align="center">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.parent_agent")}
          </TextBox>
          <TextBox $width="120px" $minWidth="120px" $font="var(--f-subB)">
            {`${useForm.formValue.parent_name}(${useForm.formValue.parent_type_name})`}
          </TextBox>
        </FormBox>

        <FormBox $align="center">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.type")}
          </TextBox>
          <TextBox $width="120px" $minWidth="120px" $font="var(--f-subB)">
            {useForm.formValue.type_name}
          </TextBox>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.rep_agent_admin")}
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm
                maxWidth="100%"
                type="text"
                name="id"
                maxLength={20}
                validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                {...useForm}
                {...useStated}
              />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("id")}
              >
                {t("common.ckech_duplicate")}
              </WhiteBtn>
            </Row>

            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 6~20{t("common.length")} {t("common.valid_eng_num")}
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("defaultPath.nickname")}
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm maxWidth="100%" type="text" name="nickname" maxLength={50} {...useForm} {...useStated} />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("nickname")}
              >
                {t("common.ckech_duplicate")}
              </WhiteBtn>
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 4~50{t("common.valid_length")}
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("defaultPath.phone")}
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm
                maxWidth="100%"
                type="text"
                name="phone"
                placeholder="000-0000-0000"
                maskType="phone"
                {...useForm}
                {...useStated}
              />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("phone")}
              >
                {t("common.ckech_duplicate")}
              </WhiteBtn>
            </Row>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("common.password")}
          </TextBox>
          <Column $gap="4px" $width="100%">
            <Row>
              <InputForm
                maxWidth="100%"
                type="password"
                name="password"
                maxLength={30}
                validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                {...useForm}
                {...useStated}
              />
            </Row>
            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 16~30{t("common.length")} {t("common.valid_eng_num")}
            </TextBox>
          </Column>
        </FormBox>
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("common.re_password")}
          </TextBox>
          <InputForm maxWidth="100%" type="password" name="re_password" {...useForm} {...useStated} />
        </FormBox>
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.join_code")}
          </TextBox>

          <Column $gap="4px" $width="100%">
            <Row $gap="8px" $width="100%">
              <InputForm
                maxWidth="100%"
                type="text"
                name="join_code"
                maxLength={4}
                validationType={validationTypeEnum.ENGLISH_AND_NUMBER}
                {...useForm}
                {...useStated}
              />
              <WhiteBtn
                $minWidth="80px"
                $height="30px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => checkDuplicated("join-code")}
              >
                {t("common.ckech_duplicate")}
              </WhiteBtn>
            </Row>

            <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
              * 4{t("common.length")} {t("common.valid_eng_num")}
            </TextBox>
          </Column>
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("agent.use_commission")}
          </TextBox>
          <ToggleButtonForm name="agent_commission_yn" trueValue="Y" falseValue="N" {...useForm} />

          <Row $align="center" $gap="8px">
            <TextBox>{t("agent.commission_type")}</TextBox>

            <SelectForm
              name="agent_commission_type"
              placeholder={dbAgentConfig.agent_commission_type["T"].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={dbAgentConfig.agent_commission_type}
              {...useForm}
            />
          </Row>
        </FormBox>

        <Row $gap="48px">
          <Column $gap="20px">
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.casino_rolling_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="casino_rolling_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.casino_losing_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="casino_losing_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.slot_rolling_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="slot_rolling_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.slot_losing_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="slot_losing_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
          </Column>

          <Column $gap="20px">
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.mini_game_rolling_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="mini_game_rolling_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.mini_game_losing_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="mini_game_losing_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.sports_rolling_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="sports_rolling_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
            <FormBox $align="center">
              <TextBox $width="120px" $minWidth="120px">
                {t("defaultPath.sports_losing_rate")}
              </TextBox>
              <InputForm maxWidth="100%" tAlign="right" type="number" name="sports_losing_rate" {...useForm} {...useStated} />
              <TextBox>%</TextBox>
            </FormBox>
          </Column>
        </Row>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={insertAgent}>
              {t("common.save")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentForm;
