import i18n from "locales/i18n";

const formNewUser = {
  join_state: {
    S: {
      label: i18n.t("joinState.S"),
      value: "S",
      key: "S",
    },
    T: {
      label: i18n.t("joinState.T"),
      value: "T",
      key: "T",
    },
  },
  state1: {
    S: {
      label: i18n.t("userState1.S"),
      value: "S",
      key: "S",
    },
    B: {
      label: i18n.t("userState1.B"),
      value: "B",
      key: "B",
    },
    W: {
      label: i18n.t("userState1.W"),
      value: "W",
      key: "W",
    },
  },
};

const formManagedUser = {
  state2: {
    B: {
      label: "양방회원",
      value: "B",
      key: 2,
    },
    T: {
      label: "통협회원",
      value: "T",
      key: 3,
    },
    O: {
      label: "진상회원",
      value: "O",
      key: 4,
    },
    E: {
      label: "기타",
      value: "E",
      key: 6,
    },
  }
};

const formModifyUser = {
  state1: {
    S: {
      label: "정상회원",
      value: "S",
      key: "S",
    },
    B: {
      label: "차단회원",
      value: "B",
      key: "B",
    },
    O: {
      label: "탈퇴회원",
      value: "O",
      key: "O",
    },
  },
};


export { formNewUser, formManagedUser, formModifyUser };
