import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import useFormData from "common/useFormData";
import { InputForm } from "components/common/CommonForm";
import { FlexBox, LineSection } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import useStateData from "common/useStateData";
import axios from "axios";
import { useAlert } from "contexts/useWindow";
import { t } from "i18next";
import { Column, Row } from "style/custom/useCustomLayout";
import OauthLogin from "./OauthLogin";
import { useCommonState } from "contexts/useCommonState";
import { LngValue } from "enum/locale/LugValue";
import i18n, { changeLanguage } from "locales/i18n";

const LoginWrap = styled.div`
  background-color: var(--c-input);
  width: 100%;
  height: 100vh;

  position: relative;
`;

const LoginBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 555px;

  padding: 70px 40px;

  background-color: var(--c-white);
`;

const LoginLogo = styled.div`
  width: 160px;
  height: 54px;

  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  background-color: var(--c-input);
`;

const BottomLayoutBox = styled.div`
  padding-top: 40px;
`;

const LoginBtn = styled.button`
  width: 100%;
  padding: 12px 0;
  border-radius: 4px;

  color: var(--c-white);
  font-size: var(--s-title);
  font-family: var(--f-title);
  line-height: var(--l-title);
  background-color: var(--c-mint-600);
`;

const Login = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const { siteName } = useCommonState();

  const useStated = useStateData();

  useEffect(() => {
    useStated.errors?.forEach((item) => {
      if (item.path === "useAlert") {
        showLoginFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  useEffect(() => {
    if (location.state?.error_code) {
      showLoginFail(location.state?.error_code);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, []);

  const showLoginFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const handleLogin = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    localStorage.removeItem("auth-admin");

    let url = `${process.env.REACT_APP_BASE_URL}/v1/auth/login-admin`;
    axios
      .post(url, {
        ...useSaveForm.formValue,
      })

      .then((res) => {
        const { accessToken, refreshToken, authAdmin } = res.data.content;

        if (accessToken && refreshToken) {
          localStorage.setItem("access-token", accessToken);
          localStorage.setItem("refresh-token", refreshToken);
          localStorage.setItem("auth-admin", JSON.stringify(authAdmin));
          navigate("/");
        } else {
          useStated.setErrorData([
            {
              msg: {
                code: "A007",
              },
              path: "useAlert",
            },
          ]);
          window.location.reload();
          return false;
        }
      })

      .catch((err) => {
        console.log(err);
        useStated.setErrorData(err.response?.data.errors, true);
      });
  };

  const useSaveForm = useFormData(
    {
      id: "",
      password: "",
    },
    handleLogin
  );
  return (
    <LoginWrap>
      <LoginBox className="login-modal">
        <Row $gap="8px" $pad="10px 20px 0 0" $position="absolute" style={{ top: "-40px", right: "0px" }}>
          {LngValue.map((lng) => {
            const isActive = i18n.language === lng.enum;
            return (
              <button key={lng.enum} onClick={() => changeLanguage(lng.enum)}>
                <TextBox $font={isActive && "var(--f-subB)"}>{lng.title}</TextBox>
              </button>
            );
          })}
        </Row>
        <FlexBox $gap="8px" $jus="center" $align="center" $direct="column" className="logo-title-box">
          <LoginLogo>LOGO</LoginLogo>
          <TextBox $size="var(--s-header)" $line="var(--l-header)" $fontType="var(--f-header)">
            {siteName} {process.env.REACT_APP_TITLE}
          </TextBox>
        </FlexBox>
        <BottomLayoutBox>
          <FlexBox $gap="16px" $direct="column" className="not-login">
            <FlexBox $gap="8px" $direct="column">
              <InputForm placeholder={t("common.id")} name="id" type="text" maxWidth="auto" {...useSaveForm} {...useStated} />
              <InputForm
                placeholder={t("common.password")}
                name="password"
                type="password"
                maxWidth="auto"
                {...useSaveForm}
                {...useStated}
              />
            </FlexBox>

            <LoginBtn onClick={handleLogin}>{t("common.login")}</LoginBtn>
            <FlexBox $gap="8px" $align="center" $jus="center">
              <TextBox $size="var(--s-subB)" $line="var(--l-subB)" $fontType="var(--f-subB)">
                {t("common.find_id")}
              </TextBox>
              <TextBox $size="var(--s-subB)" $line="var(--l-subB)" $color="var(--c-black-op)" $fontType="var(--f-subB)"></TextBox>
              <TextBox $size="var(--s-subB)" $line="var(--l-subB)" $fontType="var(--f-subB)">
                {t("common.find_pwd")}
              </TextBox>
            </FlexBox>
          </FlexBox>
        </BottomLayoutBox>
        <Column $mar="40px 0 0 0 ">
          <Row $gap="12px" $align="center">
            <LineSection $width="100%" $height="1px" $borT="1px solid var(--c-gray-300)" />
            <TextBox $color="var(--c-gray-700)" $white="nowrap">
              {t("common.login_right_away")}
            </TextBox>
            <LineSection $width="100%" $height="1px" $borT="1px solid var(--c-gray-300)" />
          </Row>
          <Row $gap="8px" $pad="12px 0 0" $jus="center">
            <OauthLogin />
          </Row>
        </Column>
      </LoginBox>
    </LoginWrap>
  );
};

export default Login;
