const gamePeriodEnum = {
  ALL: {
    title: "전체보기",
    enum: "ALL",
    key: "1",
  },
  WEEK: {
    title: "지난 일주일",
    enum: "WEEK",
    key: "2",
  },

  MONTH: {
    title: "지난 한달",
    enum: "MONTH",
    key: "3",
  },

  THREE_MONTH: {
    title: "지난 3개월",
    enum: "THREE_MONTH",
    key: "4",
  },

  SIX_MONTH: {
    title: "지난 6개월",
    enum: "SIX_MONTH",
    key: "5",
  },
  YEAR: {
    title: "지난 1년",
    enum: "YEAR",
    key: "6",
  },

  CUSTOM: {
    title: "사용자 설정",
    enum: "CUSTOM",
    key: "7",
  },
};

const gameTypeEnum = {
  ALL: {
    title: "전체보기",
    enum: "ALL",
    key: "1",
    typeNo: 0,
  },
  CASINO: {
    title: "카지노",
    enum: "CASINO",
    key: "2",
    typeNo: 2,
  },
  SLOT: {
    title: "슬롯",
    enum: "SLOT",
    key: "3",
    typeNo: 1,
  },
};

const periodTypeEnum = {
  D: {
    label: "Day",
    value: "D",
    key: "D",
  },
  M: {
    label: "Month",
    value: "M",
    key: "M",
  },
};

export { gamePeriodEnum, gameTypeEnum, periodTypeEnum };

