
import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import AgentNameTab from "components/common/AgentNameTab";
import { SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import UserNameTab from "components/common/UserNameTab";
import { useCountPerPage } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { t } from "i18next";
import api from "interceptor/api";
import { isEmpty } from "lodash";
import { Column, Row } from "style/custom/useCustomLayout";
import { PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { TextBox } from "style/useTextStyles";
import Pagination from "utils/Pagination";
import { getFormatDatetime } from "utils/date/DateUtil";

const UserFirstChargePointList = ({ date, period, search }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = `/v1/statistics/user-point/list/first-charge`;

    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      ...search.formValue,
      date: date,
      period: period
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column className="area-box" $gap="48px" $minWidth="1200px">
        <Column $width="100%">
          <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
            <SelectForm
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>

          {useTG.error ? (
            <span>error</span>
          ) : (
            <>
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="60px">{t("common.no")}</TableHeader>
                    <TableHeader>{t("user.agent_code")}</TableHeader>
                    <TableHeader>{t("user.agent")}</TableHeader>
                    <TableHeader>{t("user.user")}</TableHeader>
                    <TableHeader $width="130px">{t("user.user_level")}</TableHeader>
                    <TableHeader $width="150px">{t("pointStatistics.first_charge_at")}</TableHeader>
                    <TableHeader>{t("pointStatistics.first_charge_amount")}</TableHeader>
                    <TableHeader>{t("pointStatistics.payment_point")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo.offset - index;

                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps $width="60px">{number || "-"}</TableDeps>
                            <TableDeps $tAlign="left">{item.code || "-"}</TableDeps>
                            <TableDeps $tAlign="left">
                              <AgentNameTab
                                no={item.agent_no}
                                code={item.code}
                                name={item.name}
                                type_name={item.type_name}
                              />
                            </TableDeps>
                            <TableDeps $tAlign="left">
                              <Row $gap="6px" $align="center">
                                <UserNameTab no={item.user_no} nickname={item.nickname} refresh={getTableItem} />
                              </Row>
                            </TableDeps>
                            <TableDeps>
                              <Row $gap="6px" $align="center" $jus="center">
                                <img
                                  src={item.img_uuid ? process.env.REACT_APP_IMG_URL + item.img_uuid : ""}
                                  alt=""
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <TextBox>{`${item.level_name || "-"} (${item.lv !== undefined && item.lv !== null ? item.lv : "-"})`}</TextBox>
                              </Row>
                            </TableDeps>
                            <TableDeps>{getFormatDatetime(item.process_at) || "-"}</TableDeps>
                            <TableDeps $tAlign="right">
                              {item.ref_money ? item.ref_money.toLocaleString(undefined, {
                                style: "currency",
                                currency: "KRW",
                              }) : "-"}
                            </TableDeps>
                            <TableDeps $tAlign="right">{`${item.amount?.toLocaleString() || 0}P`}</TableDeps>

                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
              {useTG && useTG.data?.length === 0 && <NoDataTable />}
            </>
          )}
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%" $position='relative'>
            <PositionBox $position='absolute' $left="50%" $trans="translateX(-50%)">
              {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>
            <SelectForm
              top="top"
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Column>
      </Column>
    </>
  );
};


export default UserFirstChargePointList;
