import { useLocation, Navigate } from "react-router-dom";
import queryString from "query-string";

function Oauth() {
  const { search } = useLocation();
  const { accessToken, refreshToken, error_code } = queryString.parse(search) || {};

  if (error_code) {
    return <Navigate to="/login" state={{ error_code }} />;
  }
  if (accessToken && refreshToken) {
    localStorage.setItem("access-token", accessToken);
    localStorage.setItem("refresh-token", refreshToken);
  } else {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");

    return <Navigate to="/login" />;
  }
  return <Navigate to="/" />;
}

export default Oauth;
