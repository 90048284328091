import React, { useCallback, useState } from "react";
import styled from "styled-components";

const Dimmed = styled.div`
  background-color: var(--c-black-op);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [value, setValue] = useState("");

  const openModal = useCallback((num) => {
    setIsOpen(true);
    setModalData(num);
    document.body.classList.add("scroll-lock");
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalData(null);
    document.body.classList.remove("scroll-lock");
  }, []);

  const Modal = ({ children }) => {
    if (isOpen) {
      return <Dimmed>{children}</Dimmed>;
    }
    return null;
  };
  return {
    value,
    setValue,

    Modal,
    isOpen,
    openModal,
    modalData,
    closeModal,
  };
};

export default useModal;
