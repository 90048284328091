import i18n from "locales/i18n";

export const userManagerEnum = {
  // REFERENCE: {
  //   title: "레퍼런스",
  //   enum: "REFERENCE",
  //   route: "u.all",
  //   key: "1",
  // },
  ALL_USER: {
    title: i18n.t("menu.all_user"),
    enum: "ALL_USER",
    route: "",
    key: "2",
  },
  JOIN_REQUEST: {
    title: i18n.t("menu.join_request"),
    enum: "JOIN_REQUEST",
    route: "u.join",
    key: "3",
    type: "A",
  },
  USER_MANAGED: {
    title: i18n.t("menu.user_managed"),
    enum: "USER_MANAGED",
    route: "u.managed",
    key: "4",
  },
  ACCESS_USER: {
    title: i18n.t("menu.access_user"),
    enum: "ACCESS_USER",
    route: "u.access",
    key: "5",
  },
  AGENT_CHANGE_REQUEST: {
    title: i18n.t("menu.agent_change_request"),
    enum: "AGENT_CHANGE_REQUEST",
    route: "u.agent-chage-request",
    key: "6",
    type: "A",
  },
};

export const statsManagerEnum = {
  USER_CASH_STATS: {
    title: i18n.t("menu.user_cash_states"),
    enum: "USER_CASH_STATS",
    route: "s.user-cash",
    key: "1",
  },
  USER_POINT_STATS: {
    title: i18n.t("menu.user_point_states"),
    enum: "USER_POINT_STATS",
    route: "s.user-point",
    key: "2",
  },
  AGENT_STATS: {
    title: i18n.t("menu.agent_states"),
    enum: "AGENT_STATS",
    route: "s.agent",
    key: "3",
  },
  GAME_STATS: {
    title: i18n.t("menu.game_states"),
    enum: "GAME_STATS",
    route: "s.game",
    key: "4",
  },
};

export const statesAgentEnum = {
  ALL_AGENT: {
    title: i18n.t("menu.all_agent"),
    enum: "ALL_AGENT",
    route: "agent/list",
    key: "1",
  },

  AGENT_CHARGE: {
    title: i18n.t("menu.agent_charge"),
    enum: "AGENT_CHARGE",
    route: "agent/charge",
    key: "2",
  },

  AGENT_QNA: {
    title: i18n.t("menu.agent_qna"),
    enum: "AGENT_QNA",
    route: "agent/qna",
    key: "3",
  },
};

export const depositAndwithdrawalManageEnum = {
  DEPOSIT_MANAGE: {
    title: i18n.t("menu.deposit_manage"),
    enum: "DEPOSIT_MANAGE",
    route: "deposit",
    key: "1",
  },

  WITHDRAWAL_MANAGE: {
    title: i18n.t("menu.withdrawal_manage"),
    enum: "WITHDRAWAL_MANAGE",
    route: "withdrawal",
    key: "2",
  },

  POINT_CHANGE_MANAGE: {
    title: i18n.t("menu.point_change_manage"),
    enum: "POINT_CHANGE_MANAGE",
    route: "point-change",
    key: "3",
  },

  POINT_MANAGE: {
    title: i18n.t("menu.point_manage"),
    enum: "POINT_MANAGE",
    route: "point-paid",
    key: "4",
  },
};

export const gameManagerEnum = {
  SPORT_MENU: {
    title: i18n.t("menu.sport_menu"),
    enum: "SPORT_MENU",
    route: "sport",
    key: "1",
  },
  CASINO_MENU: {
    title: i18n.t("menu.casino_menu"),
    enum: "CASINO_MENU",
    route: "menu",
    key: "2",
  },
  SLOT_MENU: {
    title: i18n.t("menu.slot_menu"),
    enum: "SLOT_MENU",
    route: "slot",
    key: "3",
  },
  MINI_GAME_MENU: {
    title: i18n.t("menu.mini_game_menu"),
    enum: "MINI_GAME_MENU",
    route: "mini",
    key: "4",
  },
};

export const operateManagerEnum = {
  MESSAGE: {
    title: i18n.t("menu.message"),
    enum: "MESSAGE",
    route: "csmanagement/message",
    key: "1",
  },
  UserQnA: {
    title: i18n.t("menu.user_qna"),
    enum: "USERQNA",
    route: "csmanagement/userqna",
    key: "2",
  },
  EVNET: {
    title: i18n.t("menu.event"),
    enum: "EVENT",
    route: "csmanagement/event",
    key: "3",
  },
  NOTICE: {
    title: i18n.t("menu.notice"),
    enum: "NOTICE",
    route: "csmanagement/notice",
    key: "4",
  },
};

export const dummyMenuEnum = {
  USER_INFO: {
    title: i18n.t("menu.user_info"),
    enum: "USER_INFO",
    route: "userbalancemanagement",
    key: "1",
  },
  USERS_BETTING: {
    title: i18n.t("menu.users_betting"),
    enum: "USERS_BETTING",
    route: "usersbetting",
    key: "2",
  },
  TRANSACTIONS: {
    title: i18n.t("menu.transactions"),
    enum: "TRANSACTIONS",
    route: "transactions",
    key: "3",
  },

  STYLES: {
    title: "styles",
    enum: "STYLES_MENU",
    route: "styles",
    key: "4",
  },
  EXCHANGE: {
    title: i18n.t("menu.exchange"),
    enum: "EXCHANGE",
    route: "exchange",
    key: "5",
  },
  SETTING: {
    title: i18n.t("menu.setting"),
    enum: "SETTING",
    route: "setting",
    key: "6",
  },

  GAME: {
    title: i18n.t("menu.game"),
    enum: "GAME",
    route: "user/registration",
    key: "7",
  },
};

export const siteConfigManagerEnum = {
  SITE_CONFIG: {
    title: i18n.t("menu.site_config"),
    enum: "SITE_CONFIG",
    route: "site/config",
    key: "1",
  },
  SITE_BONUS: {
    title: i18n.t("menu.site_bonus"),
    enum: "SITE_BONUS",
    route: "site/bonus",
    key: "2",
  },
  ADMIN_CONFIG: {
    title: i18n.t("menu.admin_config"),
    enum: "ADMIN_CONFIG",
    route: "site/admin",
    key: "3",
  },
  OPERATIONAL_CONFIG: {
    title: i18n.t("menu.operational_config"),
    enum: "OPERATIONAL_CONFIG",
    route: "site/operational",
    key: "4",
  },
  MENU_CATEGORY_CONFIG: {
    title: i18n.t("menu.menu_category_config"),
    enum: "MENU_CATEGORY_CONFIG",
    route: "site/menucategory",
    key: "5",
  },
};

export const menu = {
  BETTING: {
    title: i18n.t("menu.betting_list"),
    enum: "BETTING",
    route: "betting",
    key: "1",
  },
};
