import React from "react";
import { GoogleIcon, KakaoIcon, LineIcon, SteamIcon, TelegramIcon, WhatsAppIcon } from "assets/components/IconComponent";
import styled from "styled-components";

const Oauth = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--c-white);
  border: 1px solid var(--c-gray-300);
`;

function OauthLogin() {
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");

  const serverUrl = `${process.env.REACT_APP_BASE_URL}`;

  const currentUrl = new URL(window.location.href);
  const webUrl = `${currentUrl.protocol}//${currentUrl.host}`;

  return (
    <>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/kakao?redirect_uri=${webUrl}/oauth/redirect`}>
        <KakaoIcon width="22" height="22" />
      </Oauth>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/google?redirect_uri=${webUrl}/oauth/redirect`}>
        <GoogleIcon width="22" height="22" />
      </Oauth>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/google?redirect_uri=${webUrl}/oauth/redirect`}>
        <TelegramIcon width="22" height="22" />
      </Oauth>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/google?redirect_uri=${webUrl}/oauth/redirect`}>
        <WhatsAppIcon width="22" height="22" />
      </Oauth>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/google?redirect_uri=${webUrl}/oauth/redirect`}>
        <SteamIcon width="22" height="22" />
      </Oauth>
      <Oauth as="a" href={`${serverUrl}/v1/auth/login/google?redirect_uri=${webUrl}/oauth/redirect`}>
        <LineIcon width="22" height="22" />
      </Oauth>
    </>
  );
}

export default OauthLogin;
