import React, { useState } from "react";
import styled from "styled-components";

const ToastBox = styled.div`
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;
  text-align: center;

  width: fit-content;
  max-width: 728px;
  padding: 0 20px;

  transition: ease-in-out 0.3;
  padding: 12px 20px;
  background-color: var(--c-toastBg);
  border-radius: 16px;
`;

const ToastText = styled.p`
  color: var(--c-white);
  font-size: var(--s-subB);
`;

const useToast = () => {
  const [toastOpen, setToastOpen] = useState(false);

  const openToast = () => {
    setToastOpen(true);

    // 3초 후에 모달을 자동으로 닫기
    setTimeout(() => {
      setToastOpen(false);
    }, 3000);
  };

  const Toast = ({ toastText, toastText2 }) =>
    toastOpen && (
      <ToastBox>
        <ToastText>{toastText}</ToastText>
        <ToastText>{toastText2}</ToastText>
      </ToastBox>
    );

  return {
    Toast,
    toastOpen,
    openToast,
  };
};

export default useToast;
