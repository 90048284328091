import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { InputForm, TextareaForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import api from "interceptor/api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

const PointManagementForm = ({ isPayment, checkedUserNos, nickName, userNo, getTableItem }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showPointPaymentOrRetrievalFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showPointPaymentOrRetrievalFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showPointAlert = (isSuccess) => {
    if (isPayment) {
      openAlert({
        title: isSuccess ? t("point.payment_complete") : t("point.payment_fail"),
        message: isSuccess ? t("point.payment_complete_msg") : t("point.payment_fail_msg"),
        iconURL: isSuccess ? false : true,
      });
    } else {
      openAlert({
        title: isSuccess ? t("point.retrieval_complete") : t("point.retrieval_fail"),
        message: isSuccess ? t("point.retrieval_complete_msg") : t("point.retrieval_fail_msg"),
        iconURL: isSuccess ? false : true,
      });
    }
  };

  /**
   * 포인트 (지급 / 회수)
   */
  const pointPaymentOrRetrieval = () => {
    setLoading(true);

    let apiUrl = isPayment ? `/v1/inout/point-paid/payment` : "/v1/inout/point-paid/retrieval";
    api
      .put(apiUrl, {
        user_no_arr: checkedUserNos,
        point: useForm.formValue.point,
        memo: useForm.formValue.memo,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showPointAlert(true);
        closePopup();
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showPointAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({
    point: 0,
    memo: "",
  });

  const updatePoint = (amount) => {
    let currentPoint = useForm.formValue.point;

    if (typeof currentPoint !== 'number') {
      currentPoint = parseInt(currentPoint, 10);

      if (isNaN(currentPoint)) {
        currentPoint = 0;
      }
    }

    const newPoint = isPayment ? currentPoint + amount : currentPoint - amount;

    useForm.setFormValue({
      ...useForm.formValue,
      point: newPoint,
    });
  };

  const resetPoint = () => {
    useForm.setFormValue({
      ...useForm.formValue,
      point: 0,
    });
  };

  return (
    <Column className="area-box" $gap="20px">
      <FormBox $align="flex-start">
        <IconLink
          $url={!isPayment ? "/assets/svg/warnicon.svg" : "/assets/svg/infoicon.svg"}
          $width="24px"
          $height="24px"
        ></IconLink>
        <TextBox>
          {isPayment
            ? userNo
              ? t("point.point_payment_single_confirm", { nickName: nickName || "-", userNo: userNo || "-" })
              : t("point.point_payment_confirm", { number: checkedUserNos.length })
            : userNo
              ? t("point.point_retrieval_single_confirm", { nickName: nickName || "-", userNo: userNo || "-" })
              : t("point.point_retrieval_confirm", { number: checkedUserNos.length })}
        </TextBox>
      </FormBox>
      <FormBox $align="flex-start">
        <InputForm
          name="point"
          type="number"
          tAlign="right"
          width="100%"
          maxWidth="100%"
          placeholder={`${isPayment ? t("point.payment") : t("point.retrieval")} ${t("point.point")}`}
          {...useForm}
          {...useStated}
        />
      </FormBox>

      <Row $gap="8px" $jus="flex-end" $width="100%">
        <WhiteBtn $height="36px" onClick={() => updatePoint(1000)}>
          {`${isPayment ? "+" : "-"} 1,000(P)`}
        </WhiteBtn>
        <WhiteBtn $height="36px" onClick={() => updatePoint(5000)}>
          {`${isPayment ? "+" : "-"} 5,000(P)`}
        </WhiteBtn>
        <WhiteBtn $height="36px" onClick={() => updatePoint(10000)}>
          {`${isPayment ? "+" : "-"} 10,000(P)`}
        </WhiteBtn>
        <WhiteBtn $height="36px" onClick={() => updatePoint(50000)}>
          {`${isPayment ? "+" : "-"} 50,000(P)`}
        </WhiteBtn>
        <WhiteBtn $height="36px" onClick={() => resetPoint()}>
          <IconLink $url='/assets/svg/reset_icon.svg' $width='20px' $height='20px'></IconLink>
        </WhiteBtn>
      </Row>

      <FormBox $align="flex-start">
        <Column $width="100%" $gap="4px">
          <TextareaForm
            type="text"
            name="memo"
            placeholder={`${isPayment ? t("point.payment") : t("point.retrieval")} ${t("point.reason_input")}`}
            height="200px"
            {...useForm}
            {...useStated}
          />
        </Column>
      </FormBox>

      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <Row $gap="10px" $jus="right" $width="100%">
          <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </WhiteBtn>
          <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={pointPaymentOrRetrieval}>
            {t("common.check")}
          </MainBtn>
        </Row>
      </PositionBox>
    </Column>
  );
};

export default PointManagementForm;
