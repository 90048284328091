import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useStyle from "style/useStyle";
import i18n, { changeLanguage } from "locales/i18n";
import { LngValue } from "enum/locale/LugValue";
import { IconLink } from "style/useLayoutStyles";
import { Column, Row } from "style/custom/useCustomLayout";
import { TextBox } from "style/useTextStyles";
import { isEqual } from "lodash";
import {
  menu,
  depositAndwithdrawalManageEnum,
  dummyMenuEnum,
  gameManagerEnum,
  operateManagerEnum,
  siteConfigManagerEnum,
  statesAgentEnum,
  statsManagerEnum,
  userManagerEnum,
} from "enum/admin/useAdminMenuEnum";
import { EventSourcePolyfill } from "event-source-polyfill";
import AudioPlayer from "react-h5-audio-player";

import api from "interceptor/api";
import { useAlert } from "contexts/useWindow";
import { useCommonState } from "contexts/useCommonState";
import useMenuFavorites from "common/useMenuFavorites";

const HeaderWrap = styled.header`
  border-radius: 4px;

  display: flex;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;

  padding: 12px 20px;
  border-radius: 4px;
  box-shadow: var(--shadow);
  background-color: var(--c-white);
`;

const HeaderInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTable = styled.table`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  table-layout: fixed;

  border-spacing: 0;
  border-radius: 4px;
  border-style: hidden;
  border-collapse: collapse;
  box-shadow: var(--shadow2);
  background-color: var(--c-white);
`;

const TableHeader = styled.th`
  height: 30px;
  vertical-align: middle;
  border: 1px solid var(--c-gray-300);
  font-size: var(--s-caption);
  font-family: var(--f-caption);
`;

const TableDeps = styled.td`
  height: 30px;
  vertical-align: middle;
  text-decoration: ${(props) => (props.$underline ? "underline" : "")};
  font-size: var(--s-caption);
  font-family: var(--f-caption);
  border: 1px solid var(--c-gray-300);
  background: ${(props) => props.$background || "initial"};
`;

const ProfileAlert = styled.span`
  font-size: 12px;
  width: 16px;
  height: 16px;
  text-align: center;
  color: var(--c-white);

  border-radius: 50%;
  background-color: #aaa;
`;

const MyMenuBox = styled.ul`
  position: relative;

  display: flex;
  gap: 12px;
`;

const MyMenuList = styled.li`
  position: relative;

  padding: 8px;
  width: fit-content;
  min-width: 80px;
  border-radius: 4px;
  text-align: center;
  white-space: nowrap;
  box-shadow: var(--shadow2);

  color: #4c1249;
  background-color: rgba(76, 18, 73, 0.1);
  font-size: var(--s-caption);
  cursor: pointer;

  &:first-child {
    color: #e31616;
    background-color: rgba(227, 22, 22, 0.1);
  }

  &:last-child {
    color: #549496;
    background-color: rgba(84, 148, 150, 0.1);
  }

  &::after {
    content: "";
    position: absolute;
    top: -7px;
    right: -8px;
    background: url(/assets/svg/my_menu.svg);
    width: 18px;
    height: 18px;
    z-index: 1;
  }
`;
const CenteredRow = styled(Row)`
  position: relative;
  justify-content: center;
  align-items: center;
`;
const PositionedIconLink = styled(IconLink)`
  position: absolute;
  left: 25%;
  transform: translateX(-50%);
`;

const CenteredTextBox = styled(TextBox)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--s-caption);
`;

// cursorPinterText 컴포넌트 정의
const CursorPointerText = styled(TextBox)`
  cursor: pointer;
`;

// HiddenAudioPlayer 컴포넌트 정의
const HiddenAudioPlayer = styled(AudioPlayer)`
  display: none;
`;

const AdminHeader = ({ addToFavorites, movePage }) => {
  const { FlexBox, TextBox } = useStyle();
  const { siteName, alarmConfig } = useCommonState();
  const [authNickname, setAuthNickname] = useState("");
  const { isAdmin } = useCommonState();
  const { favorites, deleteFavorites } = useMenuFavorites();
  const { t } = useTranslation();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  const formatTime = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const weekDays = [t("days.sun"), t("days.mon"), t("days.tue"), t("days.wed"), t("days.thu"), t("days.fri"), t("days.sat")];
    const weekDay = weekDays[date.getDay()];

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");

    return `${year}-${month}-${day}(${weekDay}) ${ampm} ${formattedHours}:${minutes}`;
  };

  const [notifications, setNotifications] = useState({});
  const [notiSound, setNotiSound] = useState(() => {
    const localNotiSound = localStorage.getItem("notiSound");
    return localNotiSound
      ? JSON.parse(localNotiSound)
      : {
          moneyIn: true,
          moneyOut: true,
          userJoin: true,
          qna: true,
          agent_qna: true,
        };
  });

  const playerRef1 = useRef(null);
  const playerRef2 = useRef(null);
  const playerRef3 = useRef(null);
  const playerRef4 = useRef(null);

  const { openAlert } = useAlert();
  const [firstClick, setFirstClick] = useState(false);

  const playAudio = (type) => {
    const alarmType = alarmConfig[`alarm_${type}_type`] ?? alarmConfig.alarm_in_type;
    let selectedPlayerRef;

    switch (alarmType) {
      case 1:
        selectedPlayerRef = playerRef1;
        break;
      case 2:
        selectedPlayerRef = playerRef2;
        break;
      case 3:
        selectedPlayerRef = playerRef3;
        break;
      case 4:
        selectedPlayerRef = playerRef4;
        break;
      default:
        selectedPlayerRef = playerRef1;
    }

    if (selectedPlayerRef && selectedPlayerRef.current.audio.current.paused) {
      selectedPlayerRef.current.audio.current.play().catch((error) => {
        if (!firstClick) {
          setFirstClick(true);
          openAlert({
            title: t("header.alert_title"),
            message: t("header.alert_message"),
          });
        }
      });
    }
  };

  const handleChange = (event) => {
    const { id } = event.target;
    setNotiSound((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getAuthNickname = () => {
    api
      .get("/v1/site/header/login-auth-nickname")
      .then((res) => {
        setAuthNickname(res.data.content.nickname);
      })
      .catch((error) => {
        console.error("getAuthNickname error", error);
      });
  };

  useEffect(() => {
    getAuthNickname();
  }, []);

  const audioIntervals = useRef({});
  useEffect(() => {
    const token = localStorage.getItem("access-token") ?? localStorage.getItem("refresh-token");

    const createEventSource = () => {
      return new EventSourcePolyfill(`${process.env.REACT_APP_BASE_URL}/v1/site/header/header-sse`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    };

    let eventSource = createEventSource();
    let retryTimeout = null; // 재연결을 위한 타임아웃
    let retryCount = 0; // 재연결 시도 횟수

    const clearAudioIntervals = () => {
      Object.values(audioIntervals.current).forEach(clearInterval);
      audioIntervals.current = {};
    };

    const setAudioInterval = (type, condition, delay) => {
      if (condition && !audioIntervals.current[type]) {
        playAudio(type);
        audioIntervals.current[type] = setInterval(() => playAudio(type), delay);
      } else if (!condition && audioIntervals.current[type]) {
        clearInterval(audioIntervals.current[type]);
        delete audioIntervals.current[type];
      }
    };

    const handleUpdate = (event) => {
      const userData = JSON.parse(event.data);
      setNotifications((prevNotifications) => {
        if (!isEqual(userData, prevNotifications) || isEqual(audioIntervals.current, {})) {
          setAudioInterval(
            "in",
            userData.user_in_d_count_alarm > 0 && notiSound.moneyIn,
            alarmConfig[`alarm_in_delay_time`] * 1000 ?? 5000
          );
          setAudioInterval(
            "out",
            userData.user_out_d_count_alarm > 0 && notiSound.moneyOut,
            alarmConfig[`alarm_out_delay_time`] * 1000 ?? 5000
          );
          setAudioInterval(
            "join",
            userData.user_join_d_alarm > 0 && notiSound.userJoin,
            alarmConfig[`alarm_join_delay_time`] * 1000 ?? 5000
          );
          setAudioInterval(
            "qna",
            userData.user_qna_count_alarm > 0 && notiSound.qna,
            alarmConfig[`alarm_qna_delay_time`] * 1000 ?? 5000
          );
          setAudioInterval(
            "agent_qna",
            userData.agent_qna_count_alarm > 0 && notiSound.agent_qna,
            alarmConfig[`alarm_agent_qna_delay_time`] * 1000 ?? 5000
          );
          return userData;
        }
        return prevNotifications;
      });

      // 성공적으로 이벤트를 수신했으므로 재연결 시도 횟수를 초기화합니다.
      retryCount = 0;
      clearTimeout(retryTimeout);
    };

    const handleError = () => {
      eventSource.close();
      console.error("EventSource failed.");

      // 지수적 백오프를 사용하여 재연결 시도를 합니다.
      retryCount += 1;
      const retryDelay = Math.min(30000, 1000 * 2 ** retryCount); // 최대 30초까지 대기 시간 증가

      retryTimeout = setTimeout(() => {
        console.log(`Reconnecting... Attempt #${retryCount}`);
        eventSource = createEventSource();
        eventSource.addEventListener("update", handleUpdate);
        eventSource.onerror = handleError;
      }, retryDelay);
    };

    eventSource.addEventListener("update", handleUpdate);
    eventSource.onerror = handleError;

    return () => {
      clearAudioIntervals();
      eventSource.close();
      clearTimeout(retryTimeout);
    };
  }, [notiSound, alarmConfig]);

  // String 으로 오는 값과 int 로 오는 값이 섞여 있어서 사용합니다.
  const formatNumber = (numString) => {
    if (numString === null || numString === undefined) return null;

    // 문자열을 숫자로 변환
    const num = Number(numString);

    // 숫자가 유효한지 확인
    if (isNaN(num)) return numString;

    // 천 단위마다 쉼표를 추가하여 문자열로 변환
    return num.toLocaleString();
  };

  useEffect(() => {
    localStorage.setItem("notiSound", JSON.stringify(notiSound));
  }, [notiSound]);

  const logout = () => {
    const apiUrl = `/v1/admin/logout`;
    api
      .put(apiUrl, {})
      .then((res) => {})
      .finally((err) => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("refresh-token");
        localStorage.removeItem("auth-admin");
        window.location.href = "/login";
      });
  };

  const allEnums = [
    userManagerEnum,
    statsManagerEnum,
    statesAgentEnum,
    depositAndwithdrawalManageEnum,
    gameManagerEnum,
    operateManagerEnum,
    dummyMenuEnum,
    siteConfigManagerEnum,
    menu,
  ];

  function getEnumBycode(searchEnum) {
    for (const enumGroup of allEnums) {
      for (const key in enumGroup) {
        if (enumGroup[key].enum === searchEnum) {
          return enumGroup[key];
        }
      }
    }
    return null;
  }

  return (
    <HeaderWrap>
      <TableHeaderContainer>
        <HeaderInfo>
          <FlexBox $gap="20px" $align="center">
            {/* <IconLink
              as="button"
              className={!toggleAside && "active"}
              onClick={asideToggle}
              $trans="rotate(-180deg)"
              $url="/assets/svg/maincon_toggle_w24.svg"
              $width="24px"
              $height="24px"
            ></IconLink> */}
            <TextBox $size="22px">
              [
              <TextBox $size="22px" $color="#e5e5e5">
                {siteName}
              </TextBox>
              ] {t("header.manager")}
            </TextBox>
            <Column>
              <TextBox $size="12px">Date: {formatTime(currentTime)}</TextBox>
              <Row $align="center" $gap="8px">
                <Row $align="center" $gap="4px">
                  <TextBox>{t("header.agent_money_c")}: </TextBox>
                  <TextBox $color="var(--c-red)">{formatNumber(notifications?.agent_total_money)}</TextBox>
                  <TextBox>{t("header.won")}</TextBox>
                </Row>
                <TextBox> | </TextBox>
                <Row $align="center" $gap="4px">
                  <TextBox>{t("header.user_money_c")}: </TextBox>
                  <TextBox $color="var(--c-red)">{formatNumber(notifications?.user_total_money)}</TextBox>
                  <TextBox>{t("header.won")}</TextBox>
                </Row>
                <TextBox> | </TextBox>
                <Row $align="center" $gap="4px">
                  <TextBox>{t("header.user_points_p")}: </TextBox>
                  <TextBox $color="var(--c-mint-600)">{formatNumber(notifications?.user_total_points)}</TextBox>
                  <TextBox>{t("header.points")}</TextBox>
                </Row>
              </Row>
            </Column>
          </FlexBox>
          <FlexBox $gap="12px" $align="center">
            {LngValue.map((lng) => {
              const isActive = i18n.language === lng.enum;
              return (
                <button key={lng.enum} onClick={() => changeLanguage(lng.enum)}>
                  <TextBox $font={isActive && "var(--f-subB)"}>{lng.title}</TextBox>
                </button>
              );
            })}
            {/* 
            <MyMenuBox>
              {favorites &&
                favorites.map((menu) => (
                  <MyMenuList
                    key={menu.enum}
                    onClick={() => {
                      movePage(`/${menu.route}`);
                      addToFavorites(menu);
                    }}
                  >
                    {menu.title}
                  </MyMenuList>
                ))}
            </MyMenuBox>
            <FlexBox $align="flex-end">
              <IconLink $url="/assets/svg/my_profile_w22.svg" $width="22px" $height="22px"></IconLink>
              <ProfileAlert>0</ProfileAlert>
            </FlexBox> */}
            <FlexBox $align="center">
              <IconLink $url="/assets/svg/proflie_w28.svg" $width="32px" $height="32px"></IconLink>
              <FlexBox $align="center">
                <TextBox $size="15px">{authNickname ?? ""}</TextBox>
                <IconLink
                  $url="/assets/svg/bottom_arrow_w22.svg"
                  $width="22px"
                  $height="22px"
                  onClick={() => logout()}
                ></IconLink>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </HeaderInfo>
        <HeaderTable className="allinfo-table">
          <thead>
            <tr className="table-title">
              <TableHeader>{t("header.total_members")}</TableHeader>
              <TableHeader>{t("header.today_join")}</TableHeader>
              <TableHeader>{t("header.today_deposit")}</TableHeader>
              <TableHeader>{t("header.today_withdrawal")}</TableHeader>
              <TableHeader>{t("header.deposit_withdrawal_amount")}</TableHeader>
              <TableHeader>
                <CenteredRow>
                  <PositionedIconLink
                    as="button"
                    id="moneyIn"
                    onClick={handleChange}
                    $url={notiSound.moneyIn ? "/assets/svg/noti_on.svg" : "/assets/svg/noti_off.svg"}
                    $width="24px"
                    $height="24px"
                  ></PositionedIconLink>
                  <CenteredTextBox>{t("header.deposit_request")}</CenteredTextBox>
                </CenteredRow>
              </TableHeader>
              <TableHeader>
                <CenteredRow>
                  <PositionedIconLink
                    as="button"
                    id="moneyOut"
                    onClick={handleChange}
                    $url={notiSound.moneyOut ? "/assets/svg/noti_on.svg" : "/assets/svg/noti_off.svg"}
                    $width="24px"
                    $height="24px"
                  ></PositionedIconLink>
                  <CenteredTextBox $size="var(--s-caption)">{t("header.withdrawal_request")}</CenteredTextBox>
                </CenteredRow>
              </TableHeader>
              <TableHeader>
                <CenteredRow>
                  <PositionedIconLink
                    as="button"
                    id="userJoin"
                    onClick={handleChange}
                    $url={notiSound.userJoin ? "/assets/svg/noti_on.svg" : "/assets/svg/noti_off.svg"}
                    $width="24px"
                    $height="24px"
                  ></PositionedIconLink>
                  <CenteredTextBox>{t("header.join_request")}</CenteredTextBox>
                </CenteredRow>
              </TableHeader>
              <TableHeader>
                <CenteredRow>
                  <PositionedIconLink
                    as="button"
                    id="qna"
                    onClick={handleChange}
                    $url={notiSound.qna ? "/assets/svg/noti_on.svg" : "/assets/svg/noti_off.svg"}
                    $width="24px"
                    $height="24px"
                  ></PositionedIconLink>
                  <CenteredTextBox>{t("header.inquiry")}</CenteredTextBox>
                </CenteredRow>
              </TableHeader>
              <TableHeader>
                <CenteredRow>
                  <PositionedIconLink
                    as="button"
                    id="agent_qna"
                    onClick={handleChange}
                    $url={notiSound.agent_qna ? "/assets/svg/noti_on.svg" : "/assets/svg/noti_off.svg"}
                    $width="24px"
                    $height="24px"
                  ></PositionedIconLink>
                  <CenteredTextBox>{t("header.agent_inquiry")}</CenteredTextBox>
                </CenteredRow>
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            <tr className="table-content">
              <TableDeps>
                {formatNumber(notifications?.user_total_count) ?? "-"}
                {t("header.people")}
              </TableDeps>{" "}
              <TableDeps>
                {formatNumber(notifications?.user_today_join) ?? "-"}
                {t("header.people")}
              </TableDeps>
              <TableDeps>
                {formatNumber(notifications?.user_in_count) ?? "-"}
                {t("header.case")}/{formatNumber(notifications?.user_in_money) ?? "-"}(₩)
              </TableDeps>
              <TableDeps>
                {formatNumber(notifications?.user_out_count) ?? "-"}
                {t("header.case")}/{formatNumber(notifications?.user_out_money) ?? "-"}(₩)
              </TableDeps>
              <TableDeps>{formatNumber(notifications?.user_inout_money) ?? "-"}(₩)</TableDeps>
              <TableDeps $underline={isAdmin} $background={notifications.user_in_d_count_alarm ? "var(--c-red)" : null}>
                {isAdmin ? (
                  <CursorPointerText
                    onClick={() => {
                      movePage(`/deposit`);
                      addToFavorites(depositAndwithdrawalManageEnum.DEPOSIT_MANAGE);
                    }}
                  >
                    {formatNumber(notifications?.user_in_d_count) ?? "-"}
                    {t("header.case")}
                  </CursorPointerText>
                ) : (
                  (formatNumber(notifications?.user_in_d_count) ?? "-") + t("header.case")
                )}
              </TableDeps>
              <TableDeps $underline={isAdmin} $background={notifications.user_out_d_count_alarm ? "var(--c-red)" : null}>
                {isAdmin ? (
                  <CursorPointerText
                    onClick={() => {
                      movePage(`/withdrawal`);
                      addToFavorites(depositAndwithdrawalManageEnum.WITHDRAWAL_MANAGE);
                    }}
                  >
                    {formatNumber(notifications?.user_out_d_count) ?? "-"}
                    {t("header.case")}
                  </CursorPointerText>
                ) : (
                  (formatNumber(notifications?.user_out_d_count) ?? "-") + t("header.case")
                )}
              </TableDeps>
              <TableDeps $underline={isAdmin} $background={notifications.user_join_d_alarm ? "var(--c-red)" : null}>
                {isAdmin ? (
                  <CursorPointerText
                    onClick={() => {
                      movePage(`/u.join`);
                      addToFavorites(userManagerEnum.JOIN_REQUEST);
                    }}
                  >
                    {formatNumber(notifications?.user_join_d) ?? "-"}
                    {t("header.case")}
                  </CursorPointerText>
                ) : (
                  (formatNumber(notifications?.user_join_d) ?? "-") + t("header.case")
                )}
              </TableDeps>
              <TableDeps $underline={isAdmin} $background={notifications.user_qna_count_alarm ? "var(--c-red)" : null}>
                {isAdmin ? (
                  <CursorPointerText
                    onClick={() => {
                      movePage(`/csmanagement/userqna`);
                      addToFavorites(operateManagerEnum.UserQnA);
                    }}
                  >
                    {formatNumber(notifications?.user_qna_count) ?? "-"}
                    {t("header.case")}
                  </CursorPointerText>
                ) : (
                  (formatNumber(notifications?.user_qna_count) ?? "-") + t("header.case")
                )}
              </TableDeps>
              <TableDeps $underline={isAdmin} $background={notifications.agent_qna_count_alarm ? "var(--c-red)" : null}>
                {isAdmin ? (
                  <CursorPointerText
                    onClick={() => {
                      movePage(`/agent/qna`);
                    }}
                  >
                    {formatNumber(notifications?.agent_qna_count) ?? "-"}
                    {t("header.case")}
                  </CursorPointerText>
                ) : (
                  (formatNumber(notifications?.agent_qna_count) ?? "-") + t("header.case")
                )}
              </TableDeps>
            </tr>
          </tbody>
        </HeaderTable>
        {favorites.length > 0 && (
          <HeaderTable className="allinfo-table">
            <tbody>
              <tr className="table-content">
                {(favorites?.slice(0, 9) || []).map((item, index) => {
                  const menuEnum = getEnumBycode(item?.menu_code);
                  return (
                    <TableDeps $underline key={index}>
                      <Row $align="center" $jus="center">
                        <IconLink
                          $trans="rotate(-180deg)"
                          $url="/assets/svg/star.svg"
                          $width="22px"
                          $height="22px"
                          onClick={() => deleteFavorites(menuEnum?.enum)}
                        ></IconLink>
                        <CursorPointerText
                          $font="var(--f-caption)"
                          $size="var(--s-table)"
                          onClick={() => {
                            movePage(`/${menuEnum?.route}`);
                          }}
                        >
                          {menuEnum?.title}
                        </CursorPointerText>
                      </Row>
                    </TableDeps>
                  );
                })}

                {9 - (favorites?.slice(0, 9) || []).length > 0 &&
                  Array.from({ length: 9 - (favorites?.slice(0, 9) || []).length }).map((_, index) => (
                    <TableDeps key={9 - (favorites?.slice(0, 9) || []).length + index}>
                      <TextBox $font="var(--f-caption)" $size="var(--s-table)" $color="var(--c-gray-500)">
                        {t("header.click_star_to_delete")}
                      </TextBox>
                    </TableDeps>
                  ))}
              </tr>
            </tbody>
          </HeaderTable>
        )}
      </TableHeaderContainer>
      <HiddenAudioPlayer
        src="/assets/sound/tmpalarmsound1.wav"
        ref={playerRef1}
        // 자동 재생과 기본 컨트롤을 비활성화합니다.
        autoPlay={false}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        layout="horizontal-reverse"
        customProgressBarSection={[]}
        showDownloadProgress={false}
      />
      <HiddenAudioPlayer
        src="/assets/sound/tmpalarmsound2.wav"
        ref={playerRef2}
        // 자동 재생과 기본 컨트롤을 비활성화합니다.
        autoPlay={false}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        layout="horizontal-reverse"
        customProgressBarSection={[]}
        showDownloadProgress={false}
      />
      <HiddenAudioPlayer
        src="/assets/sound/tmpalarmsound3.wav"
        ref={playerRef3}
        // 자동 재생과 기본 컨트롤을 비활성화합니다.
        autoPlay={false}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        layout="horizontal-reverse"
        customProgressBarSection={[]}
        showDownloadProgress={false}
      />
      <HiddenAudioPlayer
        src="/assets/sound/tmpalarmsound4.wav"
        ref={playerRef4}
        // 자동 재생과 기본 컨트롤을 비활성화합니다.
        autoPlay={false}
        showJumpControls={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        layout="horizontal-reverse"
        customProgressBarSection={[]}
        showDownloadProgress={false}
      />
    </HeaderWrap>
  );
};

export default AdminHeader;
