import React, { useEffect, useState } from "react";

import useFormData from "common/useFormData";
import { AreaTitle } from "style/useTextStyles";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { LineSection, PositionBox } from "style/useLayoutStyles";
import { InputForm, SelectForm, ToggleButtonForm } from "components/common/CommonForm";
import { Column, FilterBox, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { useAlert, usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import CalendarFilter from "components/common/CalendarFilter";
import { getFormatDatetime } from "utils/date/DateUtil";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import LogCommission from "components/logTable/LogCommission";
import { NormalStatus, WarnStatus } from "components/common/TableStatus";
import { dbLogLogin } from "enum/db/useLogEnum";
import { dbAgentConfig } from "enum/db/useAgentEnum";
import { useCountPerPage } from "constants/select/useValue";
import { addAllFromEnum } from "utils/common/EnumUtil";
import useStateData from "common/useStateData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import { t } from "i18next";

const AgentInfo = ({ agentNo }) => {
  const useStated = useStateData();

  const { setLoading, isAdmin } = useCommonState();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { closePopup } = usePopup();
  const [agentUserStatisticsTotal, setAgentUserStatisticsTotal] = useState(null);

  useEffect(() => {
    useStated.setErrorData([], true);
    getAgentUserStatisticsTotal();

    getAgentInfo();
  }, []);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showRejectWithdrawalRequestFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showRejectWithdrawalRequestFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getAgentUserStatisticsTotal = () => {
    const apiUrl = `/v1/agent/user/statistics-total/${agentNo}`;
    api
      .get(apiUrl, {})
      .then((res) => {
        setAgentUserStatisticsTotal(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const showLogCommissionPopup = () => {
    openPopup({
      title: t("agent.commission_update_history"),
      content: LogCommission,
      props: {
        agentNo,
      },
    });
  };

  const getAgentInfo = () => {
    setLoading(true);

    const apiUrl = `v1/agent/info/detail/${agentNo}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue(res.data.content);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const showSuccessAlert = () => {
    openAlert({
      title: t("common.save_msg"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showFailAlert = () => {
    openAlert({
      title: t("common.save_fail"),
      message: t("common.try_later"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const updateCommission = () => {
    setLoading(true);

    const apiUrl = `v1/agent/info/agent-config/${agentNo}`;
    api
      .put(apiUrl, {
        ...useForm.formValue,
      })
      .then((res) => {
        showSuccessAlert();
        getTableItem();
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        useStated.setErrorData(err.response.data.errors, true);
        showFailAlert();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({});

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/info/log-login/${agentNo}`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "no-desc",
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_status: "",
      search_message: "",
      start_date: "",
      end_date: "",
      date_period: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column $gap="48px" $width="1500px">
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.code")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.code}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.parent_agent")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.parent_code}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.money ? useForm.formValue.money?.toLocaleString() : 0} (C)
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.name")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.name}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.join_code")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.join_code}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.total_payments")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.total_payments ? useForm.formValue.total_payments?.toLocaleString() : 0} (C)
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.type")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.type_name}
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center"></OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.sub_agent")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.children_data || "-"}
              </TextBox>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.user_info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.direct_user_count")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(agentUserStatisticsTotal?.direct_user?.user_count || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_bet_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.direct_user?.bet_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_win_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.direct_user?.win_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_bet_win_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(
                  parseInt(agentUserStatisticsTotal?.direct_user?.bet_money || 0) -
                    parseInt(agentUserStatisticsTotal?.direct_user?.win_money) || 0
                ).toLocaleString(undefined, { signDisplay: "exceptZero" })}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center"></OptionBox>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_account_in_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.direct_user?.account_in_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_account_out_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.direct_user?.account_out_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_account_in_out_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(
                  parseInt(agentUserStatisticsTotal?.direct_user?.account_in_money || 0) -
                    parseInt(agentUserStatisticsTotal?.direct_user?.account_out_money) || 0
                ).toLocaleString(undefined, { signDisplay: "exceptZero" })}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.sub_user_count")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(agentUserStatisticsTotal?.sub_user?.user_count || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_bet_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.sub_user?.bet_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_win_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.sub_user?.win_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_bet_win_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(
                  parseInt(agentUserStatisticsTotal?.sub_user?.bet_money || 0) -
                    parseInt(agentUserStatisticsTotal?.sub_user?.win_money) || 0
                ).toLocaleString(undefined, { signDisplay: "exceptZero" })}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center"></OptionBox>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_account_in_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.sub_user?.account_in_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_account_out_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {parseInt(agentUserStatisticsTotal?.sub_user?.account_out_money || 0).toLocaleString()}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_account_in_out_money")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(
                  parseInt(agentUserStatisticsTotal?.sub_user?.account_in_money || 0) -
                    parseInt(agentUserStatisticsTotal?.sub_user?.account_out_money) || 0
                ).toLocaleString(undefined, { signDisplay: "exceptZero" })}
              </TextBox>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.commission_info")}</AreaTitle>
            </Row>
            <Row $gap="6px" $align="center">
              <MainBtn $pad="6px 12px" onClick={showLogCommissionPopup}>
                {t("agent.update_history")}
              </MainBtn>
              {(isAdmin || useForm.formValue?.isDirectAgent) && (
                <MainBtn $pad="6px 12px" onClick={updateCommission}>
                  {t("common.go_save")}
                </MainBtn>
              )}
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1">
              <Row $gap="16px" $align="center">
                <TextBox $width="160px" $line="var(--l-sub)">
                  {t("agent.is_commission_use")}
                </TextBox>
                {isAdmin || useForm.formValue?.isDirectAgent ? (
                  <ToggleButtonForm
                    name="agent_commission_yn"
                    options={dbAgentConfig.agent_commission_yn}
                    {...useForm}
                    {...useStated}
                  />
                ) : (
                  <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                    {dbAgentConfig.agent_commission_yn[useForm?.formValue?.agent_commission_yn]?.label}
                  </TextBox>
                )}
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="160px" $line="var(--l-sub)">
                {t("agent.commission_type")}
              </TextBox>
              {isAdmin || useForm.formValue?.isDirectAgent ? (
                <SelectForm
                  name="agent_commission_type"
                  placeholder="Total"
                  options={dbAgentConfig.agent_commission_type}
                  {...useForm}
                />
              ) : (
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {dbAgentConfig.agent_commission_type[useForm?.formValue?.agent_commission_type]?.label}
                </TextBox>
              )}
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.casino_rolling_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="casino_rolling_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.slot_rolling_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="slot_rolling_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.mini_game_rolling_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="mini_game_rolling_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.sports_rolling_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="sports_rolling_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.casino_losing_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="casino_losing_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.slot_losing_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="slot_losing_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.mini_game_losing_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="mini_game_losing_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1">
              <Row $gap="16px" $align="flex-start">
                <TextBox $width="160px" $line="var(--l-sub)" $pad="4px">
                  {t("defaultPath.sports_losing_rate")}
                </TextBox>
                <InputForm
                  maxWidth="100%"
                  type="number"
                  name="sports_losing_rate"
                  tAlign="right"
                  disabled={!(isAdmin || useForm.formValue?.isDirectAgent)}
                  {...useForm}
                  {...useStated}
                />
                <TextBox $line="var(--l-sub)">%</TextBox>
              </Row>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle> {t("agent.access_list")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Column $width="100%">
            <FilterBox $pad="12px">
              <Row $gap="12px" $align="center">
                <Column $gap="4px">
                  <FilterTitle> {t("agent.access_at")}</FilterTitle>
                  <Row $gap="4px">
                    <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle> {t("agent.access_status")}</FilterTitle>
                  <Row $gap="4px">
                    <SelectForm
                      name="search_status"
                      placeholder={t("common.all")}
                      options={addAllFromEnum(dbLogLogin.status)}
                      {...useSearch}
                      {...useStated}
                    />
                  </Row>
                </Column>

                <Column $gap="4px">
                  <FilterTitle>{t("agent.message")}</FilterTitle>
                  <Row $gap="4px">
                    <InputForm type="text" name="search_message" placeholder={t("common.input")} {...useSearch} {...useStated} />
                  </Row>
                </Column>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                  {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-count" $gap="4px">
                  <TextBox $font="var(--f-subB)"> {t("common.total")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
                  </TextBox>
                  <TextBox $font="var(--f-subB)"> {t("common.count")}</TextBox>
                </Row>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>

            {useTG.error ? (
              <span>error</span>
            ) : (
              <>
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px"> {t("common.no")}</TableHeader>
                      <TableHeader> {t("admin.admin")}</TableHeader>
                      <TableHeader $width="300px"> {t("agent.access_at")}</TableHeader>
                      <TableHeader $width="300px"> {t("agent.logout_at")}</TableHeader>
                      <TableHeader> {t("agent.access_ip")}</TableHeader>
                      <TableHeader $width="80px"> {t("common.state")}</TableHeader>
                      <TableHeader> {t("agent.message")}</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>
                          <TableDeps $tAlign="left">{`${item.agent_admin_name}(${item.agent_admin_no})`}</TableDeps>
                          <TableDeps>
                            <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                              {item.login_time ? getFormatDatetime(item.login_time) : "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps>
                            <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                              {item.logout_time ? getFormatDatetime(item.logout_time) : "-"}
                            </TextBox>
                          </TableDeps>
                          <TableDeps>{item.access_ip}</TableDeps>

                          <TableDeps>
                            <Row $width="100%" $jus="center">
                              {item.status === dbLogLogin.status.S.value ? (
                                <NormalStatus name={dbLogLogin.status[item.status].label} />
                              ) : (
                                <WarnStatus name={dbLogLogin.status[item.status].label} />
                              )}
                            </Row>
                          </TableDeps>
                          <TableDeps $tAlign="left">{item.message}</TableDeps>
                        </TableRow>
                      ))}
                  </tbody>
                </Table>
                {useTG && useTG.data?.length === 0 && <NoDataTable />}
              </>
            )}
            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
              <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
              </PositionBox>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                  top="top"
                />
              </Row>
            </Row>
          </Column>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentInfo;
