import api from "interceptor/api";
import { useEffect, useState } from "react";
import { FormBox } from "style/custom/useCustomLayout";
import { TextBox } from "style/useTextStyles";
import { SelectForm } from "./CommonForm";

const UserLevelForm = ({ useForm, useStated, width, isRequired }) => {
  const [userLevelList, setUserLevelList] = useState([]);

  useEffect(() => {
    getUserLevelList();
  }, []);

  const getUserLevelList = () => {
    const apiUrl = "/v1/site/config/user/max-levels";
    api
      .get(apiUrl, {})
      .then((res) => {
        setUserLevelList(res.data?.content && convertLevels(res.data.content));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertLevels = (levels) => {
    const convertedLevels = levels.map((item) => ({
      label: `${item.level_name} (LV${item.level})`,
      value: item.level,
      key: item.level,
    }));

    return [...convertedLevels];
  };

  return (
    <FormBox $flex="1" $align="center">
      <TextBox $width={width} $minWidth="120px" $line="var(--l-sub)">
        회원 등급 {isRequired && <TextBox $color="red">*</TextBox>}
      </TextBox>
      {userLevelList && (
        <SelectForm name="lv" options={userLevelList} {...useForm} {...useStated} />
      )}
    </FormBox>
  );
};

export default UserLevelForm;
