import { useEffect } from "react";

import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import UserInOutHistory from "components/admin/user/info/UserInOutHistory";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import { dbUser } from "enum/db/useUserEnum";
import api from "interceptor/api";
import { useTranslation } from "react-i18next";
import { Column, OptionBox, Row, SubTitleBox } from "style/custom/useCustomLayout";
import { MainBtn, RedBtn } from "style/useButtonStyles";
import { LineSection } from "style/useLayoutStyles";
import { AreaTitle, TextBox } from "style/useTextStyles";
import WithdrawalRejectForm from "./WithdrawalRejectForm";

const WithdrawalManagementForm = ({ historyNo, userNo, userInfo, getTableItem }) => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();
  const { t } = useTranslation();

  const { closePopup } = usePopup();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  useEffect(() => {
    useStated.setErrorData([], true);

    getWithdrawalRequestInfo();
  }, []);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showApproveWithdrawalRequestFail(item.msg.code);
        if (item.msg.values === "notExist") {
          closePopup();
          getTableItem();
        }
      }
    });
  }, [useStated.errors]);

  const showApproveWithdrawalRequestFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showApproveWithdrawalRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("withdrawal.withdrawal_complete") : t("withdrawal.withdrawal_fail"),
      message: isSuccess ? t("withdrawal.withdrawal_complete_msg") : t("withdrawal.withdrawal_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 출금 완료처리
   */
  const approveWithdrawalRequest = () => {
    setLoading(true);
    const apiUrl = `/v1/inout/withdrawal/approve`;
    api
      .put(apiUrl, {
        history_arr: [historyNo],
        user_arr: [userInfo],
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showApproveWithdrawalRequestAlert(true);
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showApproveWithdrawalRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * 출금 불가처리
   */
  const showRejectWithdrawalRequestPopup = (nickName) => {
    openPopup({
      title: t("withdrawal.withdrawal_reject"),
      content: WithdrawalRejectForm,
      props: {
        historyNo,
        nickName,
        userNo,
        userInfo,
        getTableItem,
      },
    });
  };

  const getWithdrawalRequestInfo = () => {
    setLoading(true);

    const apiUrl = `/v1/inout/withdrawal/info/${historyNo}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue(res.data.content);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({});

  return (
    <>
      <Column $gap="48px" $align="center">
        <Column className="area-box" $width="800px">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("user.user_info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.user_state1")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {dbUser.state1[useForm.formValue?.state1]?.label || "-"}
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.account_bank_name")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.account_bank || "-"}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.user")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {`${useForm.formValue.nickname || "-"} (${useForm.formValue.no || "-"})`}
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.account_name")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.account_name || "-"}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.user_lv")}
              </TextBox>
              <img
                src={useForm.formValue.img_uuid ? process.env.REACT_APP_IMG_URL + useForm.formValue.img_uuid : ""}
                alt=""
                style={{ width: "20px", height: "20px" }}
              />
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {`${useForm.formValue.level_name || "-"} (${useForm.formValue.lv !== undefined && useForm.formValue.lv !== null ? useForm.formValue.lv : "-"})`}
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.account_number")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.account_no || "-"}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center"></OptionBox>
            <OptionBox $flex="1" $align="center"></OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("user.money_balance")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(useForm.formValue.money?.toLocaleString() || "0") + "(C)"}
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="130px" $line="var(--l-sub)">
                {t("withdrawal.withdrawal_request_amount")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(useForm.formValue.amount?.toLocaleString() || "-") + "(P)"}
              </TextBox>
            </OptionBox>
          </Row>

          <Row $align="center" $gap="8px" $pad="24px 0px" $jus="center">
            <MainBtn $pad="6px 12px" onClick={() => approveWithdrawalRequest()}>
              {t("withdrawal.approve")}
            </MainBtn>
            <RedBtn $pad="6px 12px" onClick={() => showRejectWithdrawalRequestPopup(useForm.formValue.nickname)}>
              {t("withdrawal.reject")}
            </RedBtn>
          </Row>
        </Column>
        <UserInOutHistory userNo={userNo} />
      </Column>
    </>
  );
};

export default WithdrawalManagementForm;
