import { useEffect, useState } from "react";

function useFormData(initialValue, onSearch, useTableData) {
  const [formValue, setFormValue] = useState(initialValue);
  const [searchValue, setSearchValue] = useState(null);
  if (useTableData && onSearch) {
    useTableData.onSearchFunc(() => formSearch(false));
  }

  function handleChange(name, chgVal, index, groupNm) {
    if (index !== undefined && index !== null) {
      if (groupNm) {
        const updated = [...formValue[groupNm]];
        updated[index] = { ...updated[index], [name]: chgVal };
        setFormValue((prev) => ({
          ...prev,
          [groupNm]: updated,
        }));
      } else {
        if (Array.isArray(formValue)) {
          const updated = [...formValue];
          updated[index] = { ...updated[index], [name]: chgVal };
          setFormValue((prev) => ({
            ...prev,
            updated,
          }));
        } else {
          let updated = [...formValue[name]];
          updated[index] = chgVal;
          setFormValue((prev) => ({
            ...prev,
            [name]: updated.join(""),
          }));
        }
      }
    } else {
      setFormValue((prev) => ({
        ...prev,
        [name]: chgVal,
      }));
    }
  }

  function handelChecked(name, status) {
    if (status) {
      setFormValue((prev) => ({
        ...prev,
        [name]: null,
      }));
    } else {
      setFormValue((prev) => ({
        ...prev,
        [name]: name,
      }));
    }
  }

  // 초기화 메소드
  function resetForm() {
    setFormValue(initialValue);
  }

  // 조회 메소드
  function formSearch(b = true, page) {
    if (page) {
      useTableData.onPageChange(page, false);
    }
    if (b) {
      useTableData.onPageChange(1, false);
      setSearchValue((prev) => ({
        ...prev,
        ...formValue,
      }));
    } else {
      if (searchValue) {
        setFormValue((prev) => ({
          ...prev,
          ...searchValue,
        }));
        onSearch();
      } else {
        useTableData.onPageChange(1, false);
        setSearchValue((prev) => ({
          ...prev,
          ...formValue,
        }));
      }
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      if (useTableData) {
        formSearch();
      } else {
        onSearch();
      }
    }
  }

  useEffect(() => {
    if (searchValue) {
      onSearch();
    }
  }, [searchValue]);

  if (onSearch) {
    return {
      formValue,
      searchValue,
      setFormValue,

      resetForm,
      formSearch,
      onChange: handleChange,
      onKeyDown: handleKeyDown,
      onChecked: handelChecked,
    };
  } else {
    return {
      formValue,
      searchValue,
      setFormValue,

      resetForm,
      formSearch,
      onChange: handleChange,
      onChecked: handelChecked,
    };
  }
}

export default useFormData;
