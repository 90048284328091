import React, { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";

import useTableData from "common/useTableData";

import { useCommonState } from "contexts/useCommonState";
// style import
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { InputForm, SelectForm } from "components/common/CommonForm";
import useFormData from "common/useFormData";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import { usePopup } from "contexts/useWindow";
import { useCountPerPage } from "constants/select/useValue";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";
import useStateData from "common/useStateData";
import { BlockBtn } from "components/common/TableStatus";

const GameList = ({ setGame }) => {
  const { setLoading } = useCommonState();
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const [vendorList, setVendorList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    getVendorAndTypeList();
  }, []);

  const getVendorAndTypeList = () => {
    api
      .get(`/v1/site/menu-category/vendor-type-list`)
      .then((res) => {
        setVendorList(res.data.content.vendorList?.map((item) => ({ label: item.name, value: item.name })));
        setTypeList(res.data.content.typeList?.map((item) => ({ label: item.type, value: item.type })));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/site/menu-category/game-list`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
        useStated.setErrorData([], true);
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_vendor: "",
      search_type: "",
      search_name: "",
    },
    getTableItem,
    useTG
  );

  const clickSelect = (game) => {
    setGame(game);
    closePopup();
  };

  return (
    <>
      <Column $width="900px">
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <Column $gap="4px">
              <FilterTitle>벤더</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_vendor"
                  placeholder={t("common.all")}
                  options={vendorList}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>
            <Column $gap="4px">
              <FilterTitle>타입</FilterTitle>
              <Row $gap="4px">
                <SelectForm name="search_type" placeholder={t("common.all")} options={typeList} {...useSearch} {...useStated} />
              </Row>
            </Column>
            <Column $gap="4px">
              <FilterTitle>게임명</FilterTitle>
              <Row $gap="4px">
                <InputForm name="search_name" placeholder={t("common.input")} {...useSearch} {...useStated} />
              </Row>
            </Column>
          </Row>
          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              검색
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              초기화
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="150px">게임사</TableHeader>
                  <TableHeader>{t("common.type")}</TableHeader>
                  <TableHeader>게임 명</TableHeader>
                  <TableHeader $width="60px">선택</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>
                      <TableDeps>
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {item?.vendor}
                        </TextBox>
                      </TableDeps>
                      <TableDeps>{item?.type}</TableDeps>
                      <TableDeps $tAlign="left">{item?.title}</TableDeps>
                      <TableDeps>
                        <BlockBtn name={"선택하기"} eventProps={() => clickSelect(item)} />
                      </TableDeps>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
              top="top"
            />
          </Row>
        </Row>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default GameList;
