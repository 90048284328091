import React from "react";

import { Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { dbAuth } from "enum/db/useAuthEnum";
import { t } from "i18next";

function PrivateRoute() {
  let isLogin = false;

  const accessToken = localStorage.getItem("access-token");
  try {
    const decoded = jwtDecode(accessToken);
    const envProfileType = process.env.REACT_APP_TYPE?.trim();

    const hasPermission = () => {
      const authType = decoded.type[0];
      switch (envProfileType) {
        case "ADMIN":
          return authType === dbAuth.type.A.value;
        case "AGENT":
          return authType === dbAuth.type.G.value || authType === dbAuth.type.D.value;
        case "ALL":
          return authType === dbAuth.type.A.value || authType === dbAuth.type.G.value || authType === dbAuth.type.D.value;
        default:
          // TODO  envProfile 없으면 접근 못하게 막기
          return authType === dbAuth.type.A.value || authType === dbAuth.type.G.value;
        // return false;
      }
    };

    if (!hasPermission()) {
      alert(t("error.noAdminAccess"));
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      isLogin = false;
    } else {
      isLogin = true;
    }
  } catch (error) {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    isLogin = false;
    // 토큰이 올바르지 않거나 해독 중 오류가 발생한 경우
    return <Navigate to='/login' />;
  }

  return isLogin ? <Outlet /> : <Navigate to='/login' />;
}

export default PrivateRoute;
