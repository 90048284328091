import React, { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";

// style import
import { Column } from "style/custom/useCustomLayout";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import JSONPretty from "react-json-pretty";
import NoDataTable from "components/common/NoDataTable";

const BettingRawData = ({ transactionId }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const [rowData, setRowData] = useState();

  useEffect(() => {
    setLoading(true);
    const apiUrl = `/v1/user/all/betting/info/${transactionId}`;
    api
      .get(apiUrl, {
        params: {},
      })
      .then((res) => {
        setRowData(res.data?.content?.result_json_data);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Column $width="1600px" $height="600px">
        <JSONPretty id="json-pretty" data={rowData}></JSONPretty>
        {!rowData && <NoDataTable />}
      </Column>
    </>
  );
};

export default BettingRawData;
