/** @format */
import { useState } from "react";

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

// 셀렉트 벨류 constant
import { useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import { InputForm, NoLabelCheckForm, SelectForm, SortForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, LineSection, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { ModifyBtn } from "components/common/TableStatus";
import UserLevelFilter from "components/common/UserLevelFilter";
import UserNameTab from "components/common/UserNameTab";
import UserState2Hover from "components/common/UserState2Hover";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, useConfirm } from "contexts/useWindow";
import { dbAuth } from "enum/db/useAuthEnum";
import { dbUser } from "enum/db/useUserEnum";
import { validationTypeEnum } from "enum/useCommonEnum";
import { addAllFromEnum } from "utils/common/EnumUtil";
import { getFormatDatetime } from "utils/date/DateUtil";
import { t } from "i18next";

const AccessUser = () => {
  const useStated = useStateData();
  const { setLoading, isAdmin } = useCommonState();

  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  const [userTotalCount, setUserTotalCount] = useState(0);

  const showConditionFailAlert = () => {
    openAlert({
      title: t("user.user_not_select"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showLogOutAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("accessUser.logout_complete") : t("accessUser.logout_fail"),
      message: isSuccess ? t("accessUser.logout_complete_msg") : t("accessUser.logout_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 로그아웃 처리 Confirm
   */
  const showLogOutConfirm = () => {
    const userArr = getCheckedUserArr();

    if (userArr.length > 0) {
      openConfirm({
        title: t("accessUser.logout"),
        message: t("accessUser.logout_confirm", { number: userArr.length }),
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () => Logout(userArr.map((item) => item.no)),
      });
    } else {
      showConditionFailAlert();
    }
  };
  const showSingleLogOutConfirm = (userNo, nickName) => {
    openConfirm({
      title: t("accessUser.logout"),
      message: t("accessUser.logout_single_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => Logout([userNo]),
    });
  };

  const Logout = (checkedUserNo) => {
    setLoading(true);
    const apiUrl = `/v1/admin/force-logout`;
    api
      .post(apiUrl, {
        no_arr: checkedUserNo,
      })
      .then((res) => {
        showLogOutAlert(true);
        getTableItem();
      })
      .catch((err) => {
        showLogOutAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCheckedUserArr = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no }));
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/user/access/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setUserTotalCount(res.data.userTotalCount);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_nickname: "",
      search_user_refer_nickname: "",
      search_user_no: "",
      search_user_refer_no: "",
      search_user_lv: "",
      search_user_last_login_device: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.user_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.access_user")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.refer")}</FilterTitle>
              <InputForm type="text" name="search_user_refer_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.refer_no")}</FilterTitle>
              <InputForm
                validationType={validationTypeEnum.NUMBER}
                name="search_user_refer_no"
                placeholder={t("common.input")}
                {...useSearch}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />

            <Column $gap="4px">
              <FilterTitle>{t("accessUser.access_device")}</FilterTitle>
              <SelectForm
                width="100px"
                name="search_user_last_login_device"
                placeholder={t("common.all")}
                options={addAllFromEnum(dbAuth.last_login_device)}
                {...useSearch}
                {...useStated}
              />
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        {isAdmin ? (
          <>
            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-money" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("user.total_user_count")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG.error ? <span>error</span> : <>{userTotalCount?.toLocaleString() || "0"}</>}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                </Row>

                <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

                <Row $gap="10px" $align="center">
                  <Row className="total-partner" $gap="4px">
                    <TextBox $font="var(--f-subB)">{t("accessUser.access_user_count")}</TextBox>
                    <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                      {useTG.error ? <span>error</span> : <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords : 0}</>}
                    </TextBox>
                    <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                  </Row>
                </Row>
              </Row>
            </Row>
            <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
              <Row $gap="8px">
                <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showLogOutConfirm()}>
                  {t("accessUser.logout_btn")}
                </MainBtn>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>
          </>
        ) : (
          <>
            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-money" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("user.total_user_count")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG.error ? <span>error</span> : <>{userTotalCount?.toLocaleString() || "0"}</>}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                </Row>

                <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

                <Row $gap="10px" $align="center">
                  <Row className="total-partner" $gap="4px">
                    <TextBox $font="var(--f-subB)">{t("accessUser.access_user_count")}</TextBox>
                    <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                      {useTG.error ? <span>error</span> : <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords : 0}</>}
                    </TextBox>
                    <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                  </Row>
                </Row>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>
          </>
        )}

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  {isAdmin ? (
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                  ) : null}
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader>
                    <Row $gap="2px" $jus="center" $align="center">
                      <TextBox $font="PreSB">{t("user.user")}</TextBox>
                      <SortForm colNm="nickName" sortNm={useTG.extendData.sortNm} onSortClick={useTG.onSortClick} />
                    </Row>
                  </TableHeader>
                  <TableHeader>{t("user.user_level")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_state")}</TableHeader>
                  <TableHeader>{t("accessUser.access_ip")}</TableHeader>
                  <TableHeader $width="130px">{t("accessUser.last_login_date")}</TableHeader>
                  <TableHeader $width="130px">{t("accessUser.access_device")}</TableHeader>

                  {isAdmin ? <TableHeader $width="130px">{t("common.manage")}</TableHeader> : null}
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          {isAdmin ? (
                            <TableDeps>
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                          ) : null}

                          <TableDeps $width="60px">{number || "-"}</TableDeps>

                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left">
                            <Row $gap="6px" $align="center">
                              <UserState2Hover state2List={item.state2_arr} />
                              <UserNameTab no={item.no} nickname={item.nickname} refresh={getTableItem} />
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <Row $gap="6px" $align="center" $jus="center">
                              <img
                                src={item.img_uuid ? process.env.REACT_APP_IMG_URL + item.img_uuid : ""}
                                alt=""
                                style={{ width: "20px", height: "20px" }}
                              />
                              <TextBox>{`${item.level_name || "-"} (${item.lv !== undefined && item.lv !== null ? item.lv : "-"
                                })`}</TextBox>
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <TextBox
                              className={
                                item.state1 === dbUser.state1.S.value
                                  ? "complete"
                                  : item.state1 === dbUser.state1.B.value
                                    ? "warn"
                                    : item.state1 === dbUser.state1.O.value
                                      ? "withdraw"
                                      : item.state1 === dbUser.state1.W.value
                                        ? "normal"
                                        : item.state1 === dbUser.state1.T.value
                                          ? "test"
                                          : ""
                              }
                            >
                              {dbUser.state1[item.state1]?.label || "-"}
                            </TextBox>
                          </TableDeps>

                          <TableDeps>{item.last_login_ip || "-"}</TableDeps>
                          <TableDeps>{getFormatDatetime(item.last_login_at) || "-"}</TableDeps>
                          <TableDeps>{dbAuth.last_login_device[item.last_login_device]?.label || "-"}</TableDeps>

                          {isAdmin ? (
                            <TableDeps>
                              <Row $width="100%" $jus="center" $gap="6px">
                                <ModifyBtn name={t("accessUser.logout")} eventProps={() => showSingleLogOutConfirm(item.no, item.nickname)} />
                              </Row>
                            </TableDeps>
                          ) : null}
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        {isAdmin ? (
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showLogOutConfirm()}>
              {t("accessUser.logout_btn")}
            </MainBtn>
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>
            <SelectForm
              top="top"
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        ) : (
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>

            <Row $gap="8px">
              <SelectForm
                {...useTG}
                name="countPerPage"
                placeholder={useCountPerPage[0].label}
                size={"var(--s-table)"}
                line={"var(--l-table)"}
                options={useCountPerPage}
                formValue={useCountPerPage.value}
                top="top"
              />
            </Row>
          </Row>
        )}
      </Column>
    </>
  );
};

export default AccessUser;
