import { useEffect } from "react";

import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import { SelectForm } from "components/common/CommonForm";
import Loading from "components/common/Loading";
import { BlockBtn } from "components/common/TableStatus";
import { useCountPerPage, userPointType } from "constants/select/useValue";
import { useCommonState } from "contexts/useCommonState";
import { useAlert } from "contexts/useWindow";
import { dbUserHistoryPoint } from "enum/db/useUserEnum";
import api from "interceptor/api";
import { isEmpty } from "lodash";
import { Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { TextBox } from "style/useTextStyles";
import Pagination from "utils/Pagination";
import { getFormatDatetime } from "utils/date/DateUtil";
import { valuePointLog } from "enum/user/useUserValueEnum";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";

const PointLog = ({ userNo }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { openAlert } = useAlert();

  useEffect(() => {
    useStated.setErrorData([], true);
  }, []);

  const showMessageAlert = (message) => {
    openAlert({
      title: t("point.reject_reason"),
      message: message || "-",
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = `/v1/inout/point-paid/log/${userNo}`;

    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        console.log(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      point_type: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column className="area-box" $gap="48px" $width="800px">
        <Column $width="100%">
          <FilterBox $pad="12px">
            <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
              <Column $gap="4px">
                <FilterTitle>{t("point.category")}</FilterTitle>
                <SelectForm name="point_type" placeholder={t("common.all")} options={userPointType} {...useSearch} {...useStated} />
              </Column>
            </Row>

            <Row $gap="8px">
              <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                {t("filter.search")}
              </MainBtn>
              <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                {t("filter.refresh")}
              </WhiteBtn>
            </Row>
          </FilterBox>

          <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
            <Row $gap="10px" $align="center">
              <Row className="total-count" $gap="4px">
                <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                  {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
                </TextBox>
                <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
              </Row>
            </Row>

            <SelectForm
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>

          {useTG.error ? (
            <span>error</span>
          ) : (
            <>
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="60px">{t("common.no")}</TableHeader>
                    <TableHeader $width="60px">{t("point.category")}</TableHeader>
                    <TableHeader $width="120px">{t("userInfo.occurrence_processing_date")}</TableHeader>
                    <TableHeader>{t("userInfo.before_amount")}</TableHeader>
                    <TableHeader>{t("userInfo.request_amount")}</TableHeader>
                    <TableHeader>{t("userInfo.after_amount")}</TableHeader>
                    <TableHeader $width="60px">{t("common.state")}</TableHeader>
                    <TableHeader $width="60px">{t("point.reason")}</TableHeader>
                    <TableHeader>{t("point.processor")}</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $width="60px">
                            {item.point_type === dbUserHistoryPoint.type.P.value
                              ? dbUserHistoryPoint.type[item.point_type]?.label ?? "-"
                              : valuePointLog.type[item.inout]?.label ?? "-"}
                          </TableDeps>
                          <TableDeps $width="120px">{getFormatDatetime(item.action_at) || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{(item.before_point?.toLocaleString() || "-") + "(C)"}</TableDeps>
                          <TableDeps $tAlign="right">{(item.amount?.toLocaleString() || "-") + "(C)"}</TableDeps>
                          <TableDeps $tAlign="right">{(item.after_point?.toLocaleString() || "-") + "(C)"}</TableDeps>
                          <TableDeps $width="60px">{dbUserHistoryPoint.state[item.state]?.label || "-"}</TableDeps>
                          <TableDeps $width="60px">
                            {item.message
                              ? <BlockBtn name={t("point.reason")} eventProps={() => showMessageAlert(item.message)} />
                              : "-"
                            }
                          </TableDeps>
                          <TableDeps $tAlign="left">{item.process_no ? `${item.nickname || "-"} (${item.process_no || "-"})` : "-"}</TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              </Table>
              {useTG && useTG.data?.length === 0 && <NoDataTable />}
            </>
          )}
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%" $position="relative">
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>
            <SelectForm
              top="top"
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Column>
      </Column>
    </>
  );
};

export default PointLog;
