import useFormData from "common/useFormData";
import { SelectForm, TextareaForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

import api from "interceptor/api";
import { useAlert, usePopup } from "contexts/useWindow";
import useStateData from "common/useStateData";
import { useCommonState } from "contexts/useCommonState";
import { useTranslation } from "react-i18next";
import { dbAgentCashChargeHistory } from "enum/db/useAgentEnum";

const AgentChargeStateBlockForm = ({ historyNoArr, getTableItem }) => {
  const { setLoading } = useCommonState();

  const useStated = useStateData();
  const { openAlert } = useAlert();

  const { closePopup } = usePopup();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const updateAgentChargeBlockState = () => {
    setLoading(true);
    const apiUrl = `v1/agent/cash-charge/states`;
    api
      .put(apiUrl, {
        history_no: historyNoArr,
        state: dbAgentCashChargeHistory.state.B.value,
        memo: useForm.formValue.block_reason ? useBlockReason[useForm.formValue.block_reason].label : useForm.formValue.memo,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showBlockStateSuccessAlert();
        closePopup();
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showBlockStateFailAlert();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showBlockStateSuccessAlert = () => {
    openAlert({
      title: t("charge.update_block_complete") ,
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn:  t("common.check")
    });
  };

  const showBlockStateFailAlert = () => {
    openAlert({
      title:  t("charge.update_block_fail"), 
      message: t("common.try_later"), 
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn:  t("common.check")
    });
  };

  const useForm = useFormData({
    memo: "",
    block_reason: "",
  });

  const useBlockReason = {
    DIRECT: {
      label: t("common.direct_enter"),
      value: "",
      key: "DIRECT",
    },
    ERROR: {
      label: t("common.apply_error"),
      value: "ERROR",
      key: "ERROR",
    },
    ETC: {
      label:t("common.etc"),
      value: "ETC",
      key: "ETC",
    },
  };
  
  return (
    <>
      <Column className="area-box" $gap="20px" $height="300px">
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("charge.block_reason")}
          </TextBox>
          <Column $width="100%" $gap="4px">
            <SelectForm
              name="block_reason"
              placeholder={useBlockReason.label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useBlockReason}
              {...useForm}
            />

            {!useForm.formValue.block_reason ? (
              <TextareaForm
                type="text"
                name="memo"
                placeholder={t("charge.enter_block_reason_plh")} 
                height="200px"
                {...useForm}
                {...useStated}
              />
            ) : (
              ""
            )}
          </Column>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={updateAgentChargeBlockState}>
            {t("common.check")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};



export default AgentChargeStateBlockForm;
