import React, { useEffect } from "react";

import useFormData from "common/useFormData";
import { AreaTitle } from "style/useTextStyles";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { TextBox } from "style/useTextStyles";
import { IconLink, LineSection, PositionBox } from "style/useLayoutStyles";
import { SelectForm } from "components/common/CommonForm";
import { Column, FilterBox, OptionBox, Row, RowLankAgent, SubTitleBox } from "style/custom/useCustomLayout";
import api from "interceptor/api";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { usePopup } from "contexts/useWindow";
import useTableData from "common/useTableData";
import { FilterTitle } from "style/custom/useCustomText";
import CalendarFilter from "components/common/CalendarFilter";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";

import useStateData from "common/useStateData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "contexts/useCommonState";
import { useFiveCountPerPage } from "constants/select/useValue";
import { t } from "i18next";
import { yyyymmddToDateFormat } from "utils/date/DateUtil";
import styled from "styled-components";

const PaymentTableRow = styled(TableRow)`
  overflow-y: auto;
  &:nth-child(even) {
    background-color: var(--c-white);
  }
  &:hover {
    background-color: var(--c-white);
  }
  ${(props) => props.$isSelect && `background-color: var(--c-tableDeps) !important;`}
`;

const AgentPayment = ({ agentNo }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();
  const { closePopup } = usePopup();

  useEffect(() => {
    useStated.setErrorData([], true);

    getAgentPaymentInfo();
  }, []);

  const getAgentPaymentInfo = () => {
    setLoading(true);

    const apiUrl = `v1/agent/payment/info/${agentNo}`;
    api
      .get(apiUrl)
      .then((res) => {
        useForm.setFormValue(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({});

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/payment/${agentNo}`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true, useFiveCountPerPage[0].value);
  const useSearch = useFormData(
    {
      start_date: "",
      end_date: "",
      date_period: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <Column $gap="48px" $width="1500px">
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.code")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.code}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.name")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.name}
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.type")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.type_name}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_rolling")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(useForm.formValue.total_rolling_payments || 0)?.toLocaleString()} (C)
              </TextBox>
            </OptionBox>
            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_losing")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(useForm.formValue.total_losing_payments || 0)?.toLocaleString()} (C)
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $line="var(--l-sub)">
                {t("agent.accumulated_payment")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(useForm.formValue.total_payments || 0)?.toLocaleString()} (C)
              </TextBox>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.payment_detail_info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Column $width="100%">
            <FilterBox $pad="12px">
              <Row $gap="12px" $align="center">
                <Column $gap="4px">
                  <FilterTitle>{t("agent.date")}</FilterTitle>
                  <Row $gap="4px">
                    <CalendarFilter endName="end_date" startName="start_date" {...useSearch} end />
                  </Row>
                </Column>
              </Row>

              <Row $gap="8px">
                <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                  {t("filter.search")}
                </MainBtn>
                <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                  {t("filter.refresh")}
                </WhiteBtn>
              </Row>
            </FilterBox>

            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-count" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG && useTG.pagingInfo ? useTG.pagingInfo?.totalRecords?.toLocaleString() : 0}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
                </Row>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useFiveCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useFiveCountPerPage}
                  formValue={useFiveCountPerPage.value}
                />
              </Row>
            </Row>
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">{t("common.no")}</TableHeader>
                      <TableHeader $width="100px">{t("agent.date")}</TableHeader>
                      <TableHeader $width="200px">{t("agent.code")}</TableHeader>
                      <TableHeader $width="150px">{t("agent.agent")}</TableHeader>
                      <TableHeader $width="150px">{t("agent.bet_amount")}</TableHeader>
                      <TableHeader $width="150px">{t("agent.sum_rolling_amount")} (P)</TableHeader>
                      <TableHeader $width="150px">{t("agent.win_amount")}</TableHeader>
                      <TableHeader $width="150px">{t("agent.sum_losing_amount")} (P)</TableHeader>
                      <TableHeader $width="150px">{t("agent.payment_commission")} (P)</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {useTG.data &&
                      (() => {
                        let offsetCounter = useTG?.pagingInfo?.offset;
                        return useTG.data.map((item, index) => {
                          const isFirstOfRank = index === 0 || item.rank !== useTG.data[index - 1].rank;
                          const filteredDataOfRank = useTG.data.filter((data) => data.rank === item.rank);

                          let total_sum_bet_amount = 0;
                          let total_sum_rolling_amount = 0;
                          let total_sum_win_amount = 0;
                          let total_sum_losing_amount = 0;
                          let total_sum_rolling_losing_amount = 0;

                          filteredDataOfRank.forEach((data) => {
                            total_sum_bet_amount += data.sum_bet_amount || 0;
                            total_sum_rolling_amount += data.sum_rolling_amount || 0;
                            total_sum_win_amount += data.sum_win_amount || 0;
                            total_sum_losing_amount += data.sum_losing_amount || 0;
                            total_sum_rolling_losing_amount += (item?.sum_rolling_amount || 0) + (item?.sum_losing_amount || 0);
                          });

                          const rankOffset = isFirstOfRank ? offsetCounter-- : null;

                          return (
                            <React.Fragment key={item.code + item.yyyymmdd}>
                              <PaymentTableRow>
                                {isFirstOfRank && <TableDeps rowSpan={filteredDataOfRank.length + 1}>{rankOffset}</TableDeps>}
                                {isFirstOfRank && (
                                  <TableDeps rowSpan={filteredDataOfRank.length + 1}>
                                    {yyyymmddToDateFormat(item.yyyymmdd)}
                                  </TableDeps>
                                )}

                                <TableDeps $tAlign="left">
                                  <RowLankAgent $pad={`0 0 0 ${(item.depth - 1) * 12}px`}>
                                    {item.depth !== 0 && (
                                      <IconLink $url="/assets/svg/rowlank_agent.svg" $width="16px" $height="16px"></IconLink>
                                    )}
                                    <TextBox $size="var(--s-table)" $line="var(--l-table)">
                                      {item.code || "-"}
                                    </TextBox>
                                  </RowLankAgent>
                                </TableDeps>
                                <TableDeps $tAlign="left">{`${item.name}(${item.type_name})`}</TableDeps>
                                <TableDeps $tAlign="right">{(item?.sum_bet_amount || 0).toLocaleString()} </TableDeps>
                                <TableDeps $tAlign="right">{(item?.sum_rolling_amount || 0).toLocaleString()} </TableDeps>
                                <TableDeps $tAlign="right">{(item?.sum_win_amount || 0).toLocaleString()} </TableDeps>
                                <TableDeps $tAlign="right">{(item?.sum_losing_amount || 0).toLocaleString()} </TableDeps>

                                <TableDeps $tAlign="right">
                                  {((item?.sum_rolling_amount || 0) + (item?.sum_losing_amount || 0)).toLocaleString()}
                                </TableDeps>
                              </PaymentTableRow>
                              {(index === useTG.data.length - 1 || item.rank !== useTG.data[index + 1].rank) && (
                                <TableRow className="table-content" style={{ background: "var(--c-tableDeps)" }}>
                                  <TableDeps colSpan="2">
                                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)" $size=" var(--s-table)">
                                      {t("agent.payment_amount")}
                                    </TextBox>
                                  </TableDeps>
                                  <TableDeps $tAlign="right">
                                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)" $size=" var(--s-table)">
                                      {total_sum_bet_amount.toLocaleString()}
                                    </TextBox>
                                  </TableDeps>
                                  <TableDeps $tAlign="right">
                                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)" $size=" var(--s-table)">
                                      {total_sum_rolling_amount.toLocaleString()}
                                    </TextBox>
                                  </TableDeps>
                                  <TableDeps $tAlign="right">
                                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)" $size=" var(--s-table)">
                                      {total_sum_win_amount.toLocaleString()}
                                    </TextBox>
                                  </TableDeps>
                                  <TableDeps $tAlign="right">
                                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)" $size=" var(--s-table)">
                                      {total_sum_losing_amount.toLocaleString()}
                                    </TextBox>
                                  </TableDeps>
                                  <TableDeps $tAlign="right">
                                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)" $size=" var(--s-table)">
                                      {total_sum_rolling_losing_amount.toLocaleString()}
                                    </TextBox>
                                  </TableDeps>
                                </TableRow>
                              )}
                            </React.Fragment>
                          );
                        });
                      })()}
                  </tbody>
                </Table>
                {useTG && useTG.data?.length === 0 && <NoDataTable />}
              </>
            )}

            <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
              <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
              </PositionBox>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useFiveCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useFiveCountPerPage}
                  top="top"
                  formValue={useFiveCountPerPage.value}
                />
              </Row>
            </Row>
          </Column>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentPayment;
