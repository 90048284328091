import React from "react";
import styled from "styled-components";

const Loader = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1000;
  width: 100%;
  height: 100%;
`;

const LoadingIMG = styled.img`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loading = () => {
  return (
    <Loader>
      <LoadingIMG src='/assets/img/spinner.gif'></LoadingIMG>
    </Loader>
  );
};

export default Loading;
