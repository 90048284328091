import styled, { css } from "styled-components";

const createTable = ({ $maxWidth, $tLayout }) => css`
  max-width: ${$maxWidth};
  width: 100%;
  text-align: center;
  white-space: nowrap;
  table-layout: ${$tLayout};
  min-height: 34px;

  border-spacing: 0;
  border-style: hidden;
  border-collapse: collapse;
  border: 1px solid var(--c-gray-300);
`;

const createTableHeader = ({ $pad, $bor, $width, $height, $minWidth, $maxWidth, $minHeight, $backColor }) => css`
  height: ${$height ? $height : "34px"};
  min-height: ${$minHeight ? $minHeight : "34px"};

  width: ${$width};
  min-width: ${$minWidth};
  max-width: ${$maxWidth};

  padding: ${$pad};

  vertical-align: middle;
  border: ${$bor ? $bor : "none"};
  font-size: var(--s-table);
  line-height: var(--l-table);
  font-family: var(--f-tableB);
  background-color: ${$backColor ? $backColor : "var(--c-tableHead)"};

  input {
    vertical-align: middle; // 추가된 코드
  }
`;

const createTableDeps = ({
  $pad,
  $bor,
  $width,
  $height,
  $textDeco,
  $line,
  $maxWidth,
  $tAlign,
  $white,
  $overflow,
  $textOverflow,
}) => css`
  height: ${$height};

  width: ${$width};
  max-width: ${$maxWidth};

  padding: ${$pad ? $pad : "0px 12px"};

  vertical-align: middle;
  border: ${$bor ? $bor : "none"};
  font-size: var(--s-table);
  overflow: ${$overflow};

  text-decoration: ${$textDeco};
  text-align: ${$tAlign};
  border: 1px solid var(--c-gray-300);
  display: table-cell;
  line-height: ${$line ? $line : "33px"};
  white-space: ${$white};
  text-overflow: ${$textOverflow};

  input {
    vertical-align: middle; // 추가된 코드
  }
`;

const Table = styled.table`
  ${createTable}
`;

const TableHeader = styled.th`
  ${createTableHeader}
`;

const TableDeps = styled.td`
  ${createTableDeps}
`;

const TableRow = styled.tr`
  background-color: var(--c-white);
  &:nth-child(even) {
    background-color: var(--c-tableDeps);
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    `}

  &.complete {
    background: var(--c-blueOp);
  }
  &.warn {
    background: var(--c-redOp);
  }
  &.normal {
    background: var(--c-brownOp);
  }
  &.wait {
    background: var(--c-white);
  }
  &:hover {
    background: var(--c-gray-300);
  }
`;

export { Table, TableRow, TableDeps, TableHeader };
