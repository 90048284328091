import React from "react";
import Select from "react-select";
import styled, { css } from "styled-components";
import InputMask from "react-input-mask";

const createInputTextBox = ({ $height, $border, $backColor, $width, $minWidth, $maxWidth, $tAlign }) => css`
  width: ${$width ? $width : "100%"};
  padding: 0 12px;
  text-align: ${$tAlign};
  height: ${$height ? $height : "34px"};
  max-width: ${$maxWidth ? $maxWidth : "120px"};
  min-width: ${$minWidth ? $minWidth : "95px"};
  border: ${$border ? $border : "1px solid var(--c-gray-300)"};
  background-color: ${$backColor ? $backColor : "var(--c-white)"};

  font-size: var(--s-sub);
  line-height: var(--l-sub);

  &:focus {
    border: 1px solid var(--c-mint-600);
  }
  &[disabled] {
    background-color: var(--c-gray-100);
  }
`;
const StyledInputMask = styled(InputMask)`
  ${({ $height, $border, $backColor, $width, $minWidth, $maxWidth, $tAlign }) =>
    createInputTextBox({ $height, $border, $backColor, $width, $minWidth, $maxWidth, $tAlign })}
`;

const InputTextBox = ({ mask, maskChar, ...rest }) => {
  return <StyledInputMask mask={mask} maskChar={maskChar} autoComplete="off" {...rest} />;
};

const CheckInput = styled.input.attrs({ type: "checkbox" })`
  cursor: pointer;
  margin: ${(props) => props.$margin || "0px 0 4px !important"};
  width: 22px !important;
  height: 22px !important;
  background: url(/assets/svg/not_check.svg) no-repeat center;

  &:checked {
    background: url(/assets/svg/check.svg) no-repeat center;
  }
  &[disabled] {
    opacity: 0.5;
  }
  &[disabled] + label {
    opacity: 0.5;
  }
`;

const RadioInput = styled.input.attrs({ type: "radio" })`
  cursor: pointer;
  width: 16px !important;
  height: 16px !important;
  background: url(/assets/svg/not_select_radio.svg) no-repeat center;

  &:checked {
    background: url(/assets/svg/select_radio.svg) no-repeat center;
  }
  &[disabled] {
    opacity: 0.5;
  }
  &[disabled] + label {
    opacity: 0.5;
  }
`;

const Textarea = styled.textarea`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "80px")};
  min-height: 80px;
  padding: 4px 12px;
  text-align: start;
  border: 1px solid var(--c-gray-300);

  resize: none;
  line-height: var(--l-sub);

  &:focus {
    border: 1px solid var(--c-mint-600);
  }
`;

const Selects = styled(Select).attrs({ className: "select-box" })`
  height: 34px;
  min-height: 34px;

  .css-1fdsijx-ValueContainer {
    padding: 0;
  }
  .css-b62m3t-container {
    height: 34px;
  }

  .css-b62m3t-container .css-1s5swe7-control {
    border-color: none !important;
    box-shadow: none;
    border: none !important;
  }
  .css-b62m3t-container .css-1yxm7j6-control,
  .css-b62m3t-container .css-xhp73o-control {
    border-color: none !important;
    box-shadow: none;
    border: none !important;
  }
  .css-1jqq78o-placeholder,
  .css-1dimb5e-singleValue {
    font-size: ${(props) => props.size || "var(--s-sub)"};
    line-height: ${(props) => props.line || "var(--l-sub)"};
    font-family: ${(props) => props.font || "var(--f-sub)"};
    color: var(--c-gray-900) !important;
    white-space: nowrap;
    margin: 0;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-qbdosj-Input {
    margin: 0;
    padding: 0;
  }
`;

export { Selects, CheckInput, InputTextBox, Textarea, RadioInput };
