import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import useTableData from "common/useTableData";
import { InputForm, RadioForm, SelectForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import React, { useEffect, useState } from "react";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FlexBox, IconLink, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";
import styled from "styled-components";
import api from "interceptor/api";
import { MainBtn, MenuToggleBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
import CategoryAddPopup from "./CategoryAddPopup";
import CategoryModifyPopup from "./CategoryModifyPopup";
import CategorySortPopup from "./CategorySortPopup";
import GameAddPopup from "./GameAddPopup";
import { FilterTitle } from "style/custom/useCustomText";
import { useCountPerPage } from "constants/select/useValue";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import Pagination from "utils/Pagination";
import { t } from "i18next";

const SubTitleBox = styled(FlexBox).attrs({ className: "subtitle-box" })`
  padding: 10px 24px;
  background-color: var(--c-tableHead);
  align-items: center;
  justify-content: space-between;
`;

// 짝수 행 배경색 변경 없음 / 선택된 행 배경색 변경
const CategoryTableRow = styled(TableRow)`
  overflow-y: auto;
  &:nth-child(even) {
    background-color: var(--c-white);
  }
  &:hover {
    background-color: var(--c-white);
  }
  ${(props) => props.$isSelect && `background-color: var(--c-tableDeps) !important;`}
`;

const MenuCategory = () => {
  const useTG = useTableData(true);
  const useStated = useStateData();

  const { setLoading } = useCommonState();
  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  const [category, setCategory] = useState({
    lv1: [],
    lv2: [],
    lv3: [],
  });

  // 초기값
  const [initCategory, setInitCategory] = useState({
    lv1: [],
    lv2: [],
    lv3: [],
  });
  const [vendorList, setVendorList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const [dropdownState, setDropdownState] = useState({});

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true,
      2: true,
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false,
      2: false,
    });
  };

  // menu_lv 에 따른 parent_menu_code, lv1, lv2 에서 선택되어 있는 Item 가져오기
  const getParentMenuCode = (menu_lv) => {
    let parent_menu_code = null;
    let lv1SelectedItem = null;
    let lv2SelectedItem = null;

    if (menu_lv >= 2) {
      lv1SelectedItem = category.lv1?.find((item) => item.isSelect);
      parent_menu_code = lv1SelectedItem ? lv1SelectedItem.menu_code : null;
    }

    if (menu_lv >= 3) {
      lv2SelectedItem = category.lv2?.find((item) => item.isSelect);
      parent_menu_code = lv2SelectedItem ? lv2SelectedItem.menu_code : null;
    }

    return [parent_menu_code, lv1SelectedItem, lv2SelectedItem];
  };

  const showAddPopup = (menu_lv) => {
    const [parent_menu_code, lv1SelectedItem, lv2SelectedItem] = getParentMenuCode(menu_lv);

    if (menu_lv !== 1 && !parent_menu_code) {
      openAlert({
        title: t("common.fail"),
        message: t("menuCategory.select_upper_category"),
        iconURL: true,
      });
      return;
    }

    let titleDetail = "";

    if (menu_lv === 1) {
      titleDetail = t("menuCategory.category_level_1");
    }
    if (menu_lv === 2) {
      titleDetail = `(${lv1SelectedItem.name} > ${t("menuCategory.category_level_2")})`;
    }
    if (menu_lv === 3) {
      titleDetail = `(${lv1SelectedItem.name} > ${lv2SelectedItem.name} > ${t("menuCategory.category_level_3")})`;
    }

    openPopup({
      title: `${t("menuCategory.add_category")} ${titleDetail}`,
      content: CategoryAddPopup,
      props: {
        menu_lv: menu_lv,
        parent_menu_code,
        getCategoryList,
      },
    });
  };

  const showModifyPopup = (item) => {
    openPopup({
      title: t("menuCategory.modify_category"),
      content: CategoryModifyPopup,
      props: {
        item,
        getCategoryList,
      },
    });
  };

  const showSortPopup = (menu_lv) => {
    const [parent_menu_code] = getParentMenuCode(menu_lv);

    if (menu_lv !== 1 && !parent_menu_code) {
      openAlert({
        title: t("common.fail"),
        message: t("menuCategory.select_upper_category"),
        iconURL: true,
      });
      return;
    }

    openPopup({
      title: t("menuCategory.change_category_order"),
      content: CategorySortPopup,
      props: {
        menu_lv,
        categoryList: menu_lv === 1 ? category.lv1 : menu_lv === 2 ? category.lv2 : category.lv3,
        getCategoryList,
      },
    });
  };

  const showGameAddPopup = (item) => {
    const [, lv1SelectedItem, lv2SelectedItem] = getParentMenuCode(3);
    const titleDetail = `(${lv1SelectedItem.name} > ${lv2SelectedItem.name} > ${item.name})`;

    openPopup({
      title: `${t("menuCategory.register_game")} ${titleDetail}`,
      content: GameAddPopup,
      props: {
        categoryInfo: item,
        titleDetail,
      },
    });
  };

  useEffect(() => {
    getCategoryList({ isFirst: true });
    getVendorAndTypeList();
  }, []);

  const getCategoryList = ({ isFirst = false } = {}) => {
    setLoading(true);
    api
      .get("/v1/site/menu-category")
      .then((res) => {
        if (isFirst) {
          setCategory({
            lv1: res.data.content.lv1, // lv1 만 최초에 노출
          });
        } else {
          // 선택된 항목들 유지
          // 선택된 항목중 delete 된 항목이 있을 경우, 선택 해제
          let updatedContent = {
            lv1: res.data.content.lv1, // lv1 은 무조건 노출
          };

          // 선택 유지 로직
          const lv1SelectedItem = category.lv1?.find((item) => item.isSelect);
          const lv2SelectedItem = category.lv2?.find((item) => item.isSelect);

          if (lv1SelectedItem && res.data.content.lv1.find((item) => item.no === lv1SelectedItem.no)) {
            updatedContent = {
              ...updatedContent,
              lv1: res.data.content.lv1.map((item) => ({
                ...item,
                isSelect: item.no === lv1SelectedItem.no,
              })),
              lv2: res.data.content.lv2
                .filter((item) => item.parent_menu_code === lv1SelectedItem.menu_code)
                .map((item) => ({
                  ...item,
                  isSelect: false,
                })),
            };
          }

          if (lv2SelectedItem && res.data.content.lv2.find((item) => item.no === lv2SelectedItem.no)) {
            updatedContent = {
              ...updatedContent,
              lv2: res.data.content.lv2.map((item) => ({
                ...item,
                isSelect: item.no === lv2SelectedItem.no,
              })),
              lv3: res.data.content.lv3
                .filter((item) => item.parent_menu_code === lv2SelectedItem.menu_code)
                .map((item) => ({
                  ...item,
                  isSelect: false,
                })),
            };
          }

          setCategory(updatedContent);
        }

        setInitCategory(res.data.content);

        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((error) => {
        alert("error : ", error);
        setLoading(false);
        if (error.response?.status === 400) {
          useStated.setErrorData(error.response.data.errors);
        }
        console.error(error);
      });
  };

  const getVendorAndTypeList = () => {
    api
      .get(`/v1/site/menu-category/vendor-type-list`)
      .then((res) => {
        setVendorList(res.data.content.vendorList?.map((item) => ({ label: item.name, value: item.name })));
        setTypeList(res.data.content.typeList?.map((item) => ({ label: item.type, value: item.type })));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getGameList = () => {
    setLoading(true);
    const apiUrl = `/v1/site/menu-category/game-list`;
    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteCategory = (no) => {
    setLoading(true);
    api
      .delete(`/v1/site/menu-category/${no}`)
      .then((res) => {
        getCategoryList();
      })
      .catch((error) => {
        alert("error : ", error);
        setLoading(false);
        console.error(error);
      });
  };

  const onClickCategory = (lv, no) => {
    setCategory((prev) => {
      switch (lv) {
        case 1:
          const updatedLv1 = prev.lv1.map((item) => ({
            ...item,
            isSelect: item.no === no ? !item.isSelect : false,
          }));

          const lv1SelectedItem = updatedLv1.find((item) => item.no === no);
          const lv1ParentMenuCode = lv1SelectedItem ? lv1SelectedItem.menu_code : null;

          const updatedLv2 =
            lv1SelectedItem && lv1SelectedItem.isSelect
              ? initCategory.lv2.filter((item) => item.parent_menu_code === lv1ParentMenuCode)
              : [];

          return {
            ...prev,
            lv1: updatedLv1,
            lv2: updatedLv2,
            lv3: updatedLv2.length === 0 ? [] : prev.lv3,
          };

        case 2:
          const updatedLv2ForLv2 = prev.lv2.map((item) => ({
            ...item,
            isSelect: item.no === no ? !item.isSelect : false,
          }));

          const lv2SelectedItem = updatedLv2ForLv2.find((item) => item.no === no);
          const lv2ParentMenuCode = lv2SelectedItem ? lv2SelectedItem.menu_code : null;

          const updatedLv3 =
            lv2SelectedItem && lv2SelectedItem.isSelect
              ? initCategory.lv3.filter((item) => item.parent_menu_code === lv2ParentMenuCode)
              : [];

          return {
            ...prev,
            lv2: updatedLv2ForLv2,
            lv3: updatedLv3,
          };

        case 3:
          return prev;

        default:
          return prev;
      }
    });
  };

  const useSearch = useFormData(
    {
      search_registed: searchStateEnum.all.value,
      search_vendor: "",
      search_type: "",
      search_name: "",
    },
    getGameList,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menuCategory.site_settings")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menuCategory.menu_category_settings")}</AreaTitle>
        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}
          </MenuToggleBtn>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>
      <Column $gap="64px">
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(1)}>
            <AreaTitle>1. {t("menuCategory.game_type_category")}</AreaTitle>
            <IconLink
              $url="/assets/svg/site_config_menu_fold.svg"
              $width="32px"
              $height="32px"
              $bor="1px solid var(--c-gray-500)"
              $trans="rotate(180deg)"
              className={dropdownState[1] && "active"}
            ></IconLink>
          </SubTitleBox>
          {dropdownState[1] && (
            <Row $width="100%" $pad="8px 0" $gap="8px">
              <Column $flex="1">
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">
                        <Row $pad="8px" $align="center" $jus="space-between">
                          <WhiteBtn $minHeigh="34px" onClick={() => showSortPopup(1)}>
                            {t("menuCategory.exposure_order")}
                          </WhiteBtn>{" "}
                          <TextBox>{t("menuCategory.category_level_1")}</TextBox>
                          <MainBtn onClick={() => showAddPopup(1)}>{t("menuCategory.add_category_button")}</MainBtn>
                        </Row>
                      </TableHeader>
                    </tr>
                  </thead>
                  {category.lv1 && category.lv1?.length > 0 && (
                    <tbody>
                      {category.lv1?.map((item, index) => {
                        return (
                          <CategoryTableRow
                            $isSelect={item.isSelect}
                            key={item.no}
                            className="table-content"
                            onClick={() => onClickCategory(1, item.no)}
                          >
                            <TableDeps $width="100%" $tAlign="left">
                              <Row $align="center" $pad="6px" $jus="space-between">
                                <Row $align="center" $gap="8px">
                                  {item.isSelect ? (
                                    <IconLink $url="/assets/svg/folder_open_icon.svg" $width="24px" $height="24px"></IconLink>
                                  ) : (
                                    <IconLink $url="/assets/svg/folder_icon.svg" $width="24px" $height="24px"></IconLink>
                                  )}
                                  {item.name ?? "-"}
                                </Row>
                                <Row $align="center" $gap="6px">
                                  {item.no !== 1 ? (
                                    <RedBtn
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deleteCategory(item.no);
                                      }}
                                    >
                                      {t("common.delete")}
                                    </RedBtn>
                                  ) : null}
                                  <WhiteBtn
                                    $minHeight="36px"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      showModifyPopup(item);
                                    }}
                                  >
                                    {t("common.modify")}
                                  </WhiteBtn>
                                </Row>
                              </Row>
                            </TableDeps>
                          </CategoryTableRow>
                        );
                      })}
                    </tbody>
                  )}
                </Table>
              </Column>
              <Column $flex="1">
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">
                        <Row $pad="8px" $align="center" $jus="space-between">
                          <WhiteBtn $minHeigh="34px" onClick={() => showSortPopup(2)}>
                            {t("menuCategory.exposure_order")}
                          </WhiteBtn>
                          <TextBox>{t("menuCategory.category_level_2")}</TextBox>
                          <MainBtn onClick={() => showAddPopup(2)}>{t("menuCategory.add_category_button")}</MainBtn>
                        </Row>
                      </TableHeader>
                    </tr>
                  </thead>
                  {category.lv2 && category.lv2?.length > 0 && (
                    <tbody>
                      {category.lv2 &&
                        category.lv2.map((item, index) => {
                          return (
                            <CategoryTableRow
                              $isSelect={item.isSelect}
                              key={item.no}
                              className="table-content"
                              onClick={() => onClickCategory(2, item.no)}
                            >
                              <TableDeps $width="100%" $tAlign="left">
                                <Row $align="center" $pad="6px" $jus="space-between">
                                  <Row $align="center" $gap="8px">
                                    {item.isSelect ? (
                                      <IconLink $url="/assets/svg/folder_open_icon.svg" $width="24px" $height="24px"></IconLink>
                                    ) : (
                                      <IconLink $url="/assets/svg/folder_icon.svg" $width="24px" $height="24px"></IconLink>
                                    )}
                                    {item.name ?? "-"}
                                  </Row>
                                  <Row $align="center" $gap="6px">
                                    <RedBtn
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deleteCategory(item.no);
                                      }}
                                    >
                                      {t("common.delete")}
                                    </RedBtn>
                                    <WhiteBtn
                                      $minHeight="36px"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        showModifyPopup(item);
                                      }}
                                    >
                                      {t("common.modify")}
                                    </WhiteBtn>
                                  </Row>
                                </Row>
                              </TableDeps>
                            </CategoryTableRow>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </Column>
              <Column $flex="1">
                <Table className="table-box">
                  <thead>
                    <tr className="table-title">
                      <TableHeader $width="60px">
                        <Row $pad="8px" $align="center" $jus="space-between">
                          <WhiteBtn $minHeigh="34px" onClick={() => showSortPopup(3)}>
                            {t("menuCategory.exposure_order")}
                          </WhiteBtn>
                          <TextBox>{t("menuCategory.category_level_3")}</TextBox>
                          <MainBtn onClick={() => showAddPopup(3)}>{t("menuCategory.add_category_button")}</MainBtn>
                        </Row>
                      </TableHeader>
                    </tr>
                  </thead>
                  {category.lv3 && category.lv3?.length > 0 && (
                    <tbody>
                      {category.lv3 &&
                        category.lv3?.map((item, index) => {
                          return (
                            <CategoryTableRow $isSelect={item.isSelect} key={item.no} className="table-content">
                              <TableDeps $width="100%" $tAlign="left">
                                <Row $align="center" $pad="6px" $jus="space-between">
                                  <Row $align="center" $gap="8px">
                                    <IconLink $url="/assets/svg/rowlank_agent.svg" $width="24px" $height="24px"></IconLink>
                                    {item.name ?? "-"}
                                  </Row>
                                  <Row $align="center" $gap="6px">
                                    <MainBtn $minHeight="36px" onClick={() => showGameAddPopup(item)}>
                                      {t("menuCategory.register_game_button")}
                                    </MainBtn>
                                    <RedBtn
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        deleteCategory(item.no);
                                      }}
                                    >
                                      {t("common.delete")}
                                    </RedBtn>
                                    <WhiteBtn
                                      $minHeight="36px"
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        showModifyPopup(item);
                                      }}
                                    >
                                      {t("common.modify")}
                                    </WhiteBtn>
                                  </Row>
                                </Row>
                              </TableDeps>
                            </CategoryTableRow>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </Column>
            </Row>
          )}
        </Column>
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(2)}>
            <AreaTitle>2. {t("menuCategory.game_registration_list")}</AreaTitle>
            <IconLink
              $url="/assets/svg/site_config_menu_fold.svg"
              $width="32px"
              $height="32px"
              $bor="1px solid var(--c-gray-500)"
              $trans="rotate(180deg)"
              className={dropdownState[2] && "active"}
            ></IconLink>
          </SubTitleBox>
          {dropdownState[2] && (
            <Column $pad="8px 0">
              <FilterBox $pad="12px">
                <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
                  <Column $gap="4px">
                    <FilterTitle>{t("menuCategory.search_filter")}</FilterTitle>
                    <Row $gap="12px" $align="center">
                      <RadioForm name="search_registed" options={searchStateEnum} {...useSearch} {...useStated} />
                      <SelectForm
                        name="search_vendor"
                        placeholder={t("menuCategory.select_vendor")}
                        options={vendorList}
                        {...useSearch}
                        {...useStated}
                      />
                      <SelectForm
                        name="search_type"
                        placeholder={t("menuCategory.select_type")}
                        options={typeList}
                        {...useSearch}
                        {...useStated}
                      />
                      <InputForm name="search_name" placeholder={t("menuCategory.game_name")} {...useSearch} {...useStated} />
                    </Row>
                  </Column>
                </Row>
                <Row $gap="8px">
                  <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                    {t("filter.search")}
                  </MainBtn>
                  <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                    {t("filter.refresh")}
                  </WhiteBtn>
                </Row>
              </FilterBox>

              <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
                <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
                  <TextBox $font="var(--f-subB)">{t("menuCategory.total_game_count")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG.error ? (
                      <span>error</span>
                    ) : (
                      <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
                    )}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("menuCategory.unit_count")}</TextBox>
                </Row>
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useTG.extendData}
                />
              </Row>

              {useTG.error ? (
                <span>error</span>
              ) : (
                <>
                  {
                    <Table className="table-box">
                      <thead>
                        <tr className="table-title">
                          <TableHeader $minWidth="60px" $width="60px">
                            <TextBox>{t("menuCategory.no")}</TextBox>
                          </TableHeader>
                          <TableHeader $minWidth="60px" $width="60px">
                            {t("menuCategory.registration_status")}
                          </TableHeader>
                          <TableHeader $minWidth="130px" $width="130px">
                            {t("menuCategory.vendor")}
                          </TableHeader>
                          <TableHeader $minWidth="80px" $width="80px">
                            {t("menuCategory.type")}
                          </TableHeader>
                          <TableHeader $minWidth="280px">{t("menuCategory.game_name")}</TableHeader>
                          <TableHeader $minWidth="280px" $width="280px">
                            {t("menuCategory.registration_category")}
                          </TableHeader>
                        </tr>
                      </thead>
                      {useTG && useTG.data?.length > 0 && (
                        <tbody>
                          {useTG.data &&
                            useTG.data.map((item, index) => {
                              const number = useTG.pagingInfo?.offset - index;
                              return (
                                <CategoryTableRow $isSelect={item.registed === "N"} key={number} className="table-content">
                                  <TableDeps $width="60px">{number || "-"}</TableDeps>
                                  <TableDeps $width="60px">
                                    {item.registed === "Y" ? t("menuCategory.registered") : t("menuCategory.not_registered")}
                                  </TableDeps>
                                  <TableDeps $width="130px" $tAlign="left">
                                    {item.vendor}
                                  </TableDeps>
                                  <TableDeps $width="80px">{item?.type}</TableDeps>
                                  <TableDeps $width="280px" $tAlign="left">
                                    {item?.title}
                                  </TableDeps>
                                  <TableDeps $width="280px">
                                    <Column>
                                      {item?.menu_paths
                                        ?.split(",")
                                        .map((menu_path, index) => <TextBox key={index}>{"(" + menu_path + ")"}</TextBox>) ?? "-"}
                                    </Column>
                                  </TableDeps>
                                </CategoryTableRow>
                              );
                            })}
                        </tbody>
                      )}
                    </Table>
                  }
                  {useTG && useTG.data?.length === 0 && <NoDataTable />}
                </>
              )}
              <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
                <Row />
                <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                  {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
                </PositionBox>
                <Row $gap="8px">
                  <SelectForm
                    {...useTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    top="top"
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                    formValue={useTG.extendData}
                  />
                </Row>
              </Row>
            </Column>
          )}
        </Column>
      </Column>
    </>
  );
};

const searchStateEnum = {
  all: {
    label: t("menuCategory.all_games"),
    value: "all",
    key: 0,
  },
  registed: {
    label: t("menuCategory.registered_games"),
    value: "registed",
    key: 1,
  },
  unregisted: {
    label: t("menuCategory.not_registered_games"),
    value: "unregisted",
    key: 2,
  },
};

export default MenuCategory;
