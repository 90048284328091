import React, { useState } from "react";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import api from "interceptor/api";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { useAlert, usePopup } from "contexts/useWindow";
import { CheckInput } from "style/useInputStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { t } from "i18next";

const CategorySortPopup = ({ menu_lv, categoryList, getCategoryList }) => {
  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const [thisCategoryList, setCategoryList] = useState(categoryList);

  const handleMoveLeft = (index) => {
    if (index > 0) {
      const updateCategoryList = [...thisCategoryList];
      [updateCategoryList[index], updateCategoryList[index - 1]] = [updateCategoryList[index - 1], updateCategoryList[index]];
      setCategoryList(updateCategoryList);
    }
  };

  const handleMoveRight = (index) => {
    if (index < thisCategoryList.length - 1) {
      const updateCategoryList = [...thisCategoryList];
      [updateCategoryList[index], updateCategoryList[index + 1]] = [updateCategoryList[index + 1], updateCategoryList[index]];
      setCategoryList(updateCategoryList);
    }
  };

  const handleCheck = (index) => {
    setCategoryList((prev) => {
      return prev.map((item, i) => ({
        ...item,
        use_yn: i === index ? (item.use_yn === "Y" ? "N" : "Y") : item.use_yn,
      }));
    });
  };

  const putCategoryList = () => {
    const updateCategoryList = thisCategoryList.map((item, index) => ({
      ...item,
      sort: index + 1,
    }));

    api
      .put(`/v1/site/menu-category/sort`, {
        menu_lv: menu_lv,
        categoryList: updateCategoryList,
      })
      .then((res) => {
        getCategoryList();
        closePopup();
      })
      .catch((error) => {
        openAlert({
          title: t(`error.${error.response.data.code}`),
          iconURL: true,
          mainBtn: t("common.check"),
        });
        console.error(error);
      });
  };

  return (
    <Column $gap="30px" $pad="20px">
      <Table className="table-box">
        <thead>
          <tr className="table-title">
            <TableHeader $width="60px">{t("menuCategory.exposure")}</TableHeader>
            <TableHeader $width="60px">{t("menuCategory.order")}</TableHeader>
            <TableHeader $width="60px">{t("menuCategory.adjust_order")}</TableHeader>
            <TableHeader $width="230px">{t("menuCategory.category_name")}</TableHeader>
          </tr>
        </thead>
        {thisCategoryList && thisCategoryList?.length > 0 && (
          <tbody>
            {thisCategoryList &&
              thisCategoryList.map((item, index) => {
                return (
                  <TableRow key={item.no} className="table-content">
                    <TableDeps>
                      <CheckInput checked={item.use_yn === "Y"} onChange={() => handleCheck(index)}></CheckInput>
                    </TableDeps>
                    <TableDeps>{index + 1}</TableDeps>
                    <TableDeps>
                      <Row $align="center" $jus="align-center">
                        {index === 0 ? null : (
                          <IconLink
                            $url="/assets/svg/sort_icon.svg"
                            $width="24px"
                            $height="24px"
                            onClick={() => handleMoveLeft(index)}
                          ></IconLink>
                        )}
                        {index === thisCategoryList.length - 1 ? null : (
                          <IconLink
                            $url="/assets/svg/sort_icon.svg"
                            $width="24px"
                            $height="24px"
                            $trans="rotate(180deg)"
                            className="active"
                            onClick={() => handleMoveRight(index)}
                          ></IconLink>
                        )}
                      </Row>
                    </TableDeps>
                    <TableDeps $tAlign="left">
                      <Row $align="center" $gap="8px">
                        {item.name ?? "-"}
                      </Row>
                    </TableDeps>
                  </TableRow>
                );
              })}
          </tbody>
        )}
      </Table>

      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <FormBox $gap="10px" $jus="right" $width="100%">
          <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={putCategoryList}>
            {t("common.save")}
          </MainBtn>
          <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </WhiteBtn>
        </FormBox>
      </PositionBox>
    </Column>
  );
};

export default CategorySortPopup;
