import useFormData from "common/useFormData";
import useStateData from "common/useStateData";
import { TextareaForm } from "components/common/CommonForm";
import { useCommonState } from "contexts/useCommonState";
import { useAlert, usePopup } from "contexts/useWindow";
import api from "interceptor/api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Column, FormBox, Row } from "style/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, PositionBox } from "style/useLayoutStyles";
import { TextBox } from "style/useTextStyles";

const PointChangeRejectForm = ({ transactionNoList, userList, nickName, userNo, getTableItem }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showErrorAlert(item.msg.code);
        if (item.msg.values === "notExist") {
          closePopup();
          getTableItem();
        }
      }
    });
  }, [useStated.errors]);

  const showErrorAlert = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showRejectPointChangeRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("pointChange.reject_complete") : t("pointChange.reject_fail"),
      message: isSuccess ? t("pointChange.reject_complete_msg") : t("pointChange.reject_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 포인트 전환 불가처리
   */
  const rejectPointChangeRequest = () => {
    setLoading(true);
    const apiUrl = `/v1/inout/point-change/reject`;
    api
      .put(apiUrl, {
        transactions_arr: transactionNoList,
        user_arr: userList,
        memo: useForm.formValue.memo,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showRejectPointChangeRequestAlert(true);
        closePopup();
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showRejectPointChangeRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({
    memo: "",
  });

  return (
    <Column className="area-box" $gap="20px" $height="300px">
      <FormBox $align="flex-start">
        <IconLink $url={"/assets/svg/warnicon.svg"} $width="24px" $height="24px"></IconLink>
        <TextBox>
          {userNo
            ? t("pointChange.point_change_reject_single_confirm", { nickName: nickName || "-", userNo: userNo || "-" })
            : t("pointChange.point_change_reject_confirm", { number: transactionNoList.length })}
        </TextBox>
      </FormBox>
      <FormBox $align="flex-start">
        <Column $width="100%" $gap="4px">
          <TextareaForm
            type="text"
            name="memo"
            placeholder={t("pointChange.point_change_reject_reason_placeholder")}
            height="200px"
            {...useForm}
            {...useStated}
          />
        </Column>
      </FormBox>

      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <Row $gap="10px" $jus="right" $width="100%">
          <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </WhiteBtn>
          <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={rejectPointChangeRequest}>
            {t("common.check")}
          </MainBtn>
        </Row>
      </PositionBox>
    </Column>
  );
};

export default PointChangeRejectForm;
