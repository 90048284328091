/** @format */
import { useEffect, useState } from "react";

// 공용
import api from "interceptor/api";
import Pagination from "utils/Pagination";

import useFormData from "common/useFormData";
import useTableData from "common/useTableData";

import { useAlert, useConfirm, usePopup } from "contexts/useWindow";
// 셀렉트 벨류 constant
import { useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import { InputForm, NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "style/custom/useCustomLayout";
import { FilterTitle } from "style/custom/useCustomText";
import { MainBtn, RedBtn, WhiteBtn } from "style/useButtonStyles";
import { IconLink, InfoTabList, LineSection, PositionBox } from "style/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "style/useTableStyles";
import { AreaTitle, SubText, TextBox } from "style/useTextStyles";

import useStateData from "common/useStateData";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import UserNameTab from "components/common/UserNameTab";
import { useCommonState } from "contexts/useCommonState";
import { validationTypeEnum } from "enum/useCommonEnum";
import { useTranslation } from "react-i18next";
import { useAlarmCheck } from "utils/ApiUtil";
import { getFormatDatetime } from "utils/date/DateUtil";
import UserInfo from "../info/UserInfo";

const JoinRequest = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  const [userTotalCount, setUserTotalCount] = useState(0);

  const { t } = useTranslation();

  useAlarmCheck("join-request");

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showRejectWithdrawalRequestFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showRejectWithdrawalRequestFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showConditionFailAlert = () => {
    openAlert({
      title: t("user.user_not_select"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showApproveJoinRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("user.join_approve_complete") : t("user.join_approve_fail"),
      message: isSuccess ? t("user.join_approve_complete_msg") : t("user.join_approve_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  const showRejectJoinRequestAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("user.join_reject_complete") : t("user.join_reject_fail"),
      message: isSuccess ? t("user.join_reject_complete_msg") : t("user.join_reject_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 가입 완료처리 Confirm
   */
  const showApproveJoinRequestConfirm = () => {
    const userArr = getCheckedUserArr();

    if (userArr.length > 0) {
      openConfirm({
        title: t("user.join_approve"),
        message: t("userJoinRequest.join_approve_confirm", { number: userArr.length }),
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () => approveJoinRequest(userArr.map((item) => item.no)),
      });
    } else {
      showConditionFailAlert();
    }
  };
  const showApproveSingleJoinRequestConfirm = (userNo, nickName) => {
    openConfirm({
      title: t("user.join_approve"),
      message: t("user.join_approve_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
      iconURL: false,
      mainBtn: t("common.check"),
      Func: () => approveJoinRequest([userNo]),
    });
  };

  /**
   * 가입 완료처리
   */
  const approveJoinRequest = (checkedUserNo) => {
    setLoading(true);
    const apiUrl = `/v1/user/join-request/approve`;
    api
      .put(apiUrl, {
        user_arr: checkedUserNo,
      })
      .then((res) => {
        showApproveJoinRequestAlert(true);
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showApproveJoinRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * 가입 불가처리 Confirm
   */
  const showRejectJoinRequestConfirm = () => {
    const userArr = getCheckedUserArr();

    if (userArr.length > 0) {
      openConfirm({
        title: t("user.join_reject"),
        message: t("userJoinRequest.join_reject_confirm", { number: userArr.length }),
        iconURL: true,
        mainBtn: t("common.check"),
        Func: () => rejectJoinRequest(userArr.map((item) => item.no)),
      });
    } else {
      showConditionFailAlert();
    }
  };
  const showRejectSingleJoinRequestConfirm = (userNo, nickName) => {
    openConfirm({
      title: t("user.join_reject"),
      message: t("user.join_reject_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
      iconURL: true,
      mainBtn: t("common.check"),
      Func: () => rejectJoinRequest([userNo]),
    });
  };

  /**
   * 가입 불가처리
   */
  const rejectJoinRequest = (checkedUserNo) => {
    setLoading(true);
    const apiUrl = `/v1/user/join-request/reject`;
    api
      .put(apiUrl, {
        user_arr: checkedUserNo,
      })
      .then((res) => {
        showRejectJoinRequestAlert(true);
        getTableItem();
      })
      .catch((err) => {
        showRejectJoinRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCheckedUserArr = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no }));
  };

  const showUserInfoPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("user.tab_user_info")} (${nickName})` : t("user.tab_user_info"),
      content: UserInfo,
      props: {
        userNo,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/user/join-request/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setUserTotalCount(res.data.userTotalCount);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_user_nickname: "",
      search_user_refer_nickname: "",
      search_user_no: "",
      search_user_refer_no: "",

      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",
    },
    getTableItem,
    useTG
  );

  /**
   * tabList
   * @param {*} item
   * @returns
   */
  const customTabList = (item) => {
    return (
      <>
        <InfoTabList onClick={() => showUserInfoPopup(item.no, item.nickname)}>{t("user.tab_user_info")}</InfoTabList>
        <InfoTabList onClick={() => showApproveSingleJoinRequestConfirm(item.no, item.nickname)}>{t("user.join_approve")}</InfoTabList>
        <InfoTabList onClick={() => showRejectSingleJoinRequestConfirm(item.no, item.nickname)}>{t("user.join_reject")}</InfoTabList>
      </>
    );
  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.user_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.join_request")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.refer")}</FilterTitle>
              <InputForm type="text" name="search_user_refer_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.refer_no")}</FilterTitle>
              <InputForm
                validationType={validationTypeEnum.NUMBER}
                name="search_user_refer_no"
                placeholder={t("common.input")}
                {...useSearch}
              />
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("user.total_user_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{userTotalCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row $gap="10px" $align="center">
              <Row className="total-partner" $gap="4px">
                <TextBox $font="var(--f-subB)">{t("userJoinRequest.total_join_request")}</TextBox>
                <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                  {useTG.error ? <span>error</span> : <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords : 0}</>}
                </TextBox>
                <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
              </Row>
            </Row>
          </Row>
        </Row>
        <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showApproveJoinRequestConfirm()}>
              {t("user.join_approve")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showRejectJoinRequestConfirm()}>
              {t("user.join_reject")}
            </RedBtn>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader>{t("user.user")}</TableHeader>
                  <TableHeader $width="60px">{t("user.join_code")}</TableHeader>
                  <TableHeader $width="130px">{t("user.join_request_at")}</TableHeader>
                  <TableHeader>{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>
                            <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                          </TableDeps>
                          <TableDeps $width="60px">{number || "-"}</TableDeps>

                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left">
                            <UserNameTab
                              no={item.no}
                              nickname={item.nickname}
                              refresh={getTableItem}
                              tabList={customTabList(item)}
                            />
                          </TableDeps>

                          <TableDeps>{item.join_code || "-"}</TableDeps>
                          <TableDeps>{getFormatDatetime(item.join_request_at) || "-"}</TableDeps>
                          <TableDeps $width="120px">
                            <Row $width="100%" $jus="center" $gap="6px">
                              {item.no ? (
                                <>
                                  <ModifyBtn
                                    name={t("user.join_approve")}
                                    eventProps={() =>
                                      showApproveSingleJoinRequestConfirm(item.no, item.nickname)
                                    }
                                  />
                                  <DeleteBtn
                                    name={t("user.join_reject")}
                                    eventProps={() =>
                                      showRejectSingleJoinRequestConfirm(item.no, item.nickname)
                                    }
                                  />
                                </>
                              ) : (
                                "-"
                              )}
                            </Row>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showApproveJoinRequestConfirm()}>
              {t("user.join_approve")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showRejectJoinRequestConfirm()}>
              {t("user.join_reject")}
            </RedBtn>
          </Row>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default JoinRequest;
