import React from "react";
import Routers from "routes/Routers";
import { RecoilRoot } from "recoil";

import { CommonWindowProvider } from "contexts/useWindow";
import { CommonStateProvider } from "contexts/useCommonState";

import GlobalStyle from "style/GlobalStyle";
import SiteNameSetter from "components/common/SiteNameSetter";
import AlarmConfigSetter from "components/common/AlarmConfigSetter";

function App() {
  return (
    <>
      <GlobalStyle />
      <CommonStateProvider>
        <SiteNameSetter /> {/* Call API and set site name */}
        <AlarmConfigSetter /> {/* Call API and set alarm config */}
        <CommonWindowProvider>
          <RecoilRoot>
            <Routers />
          </RecoilRoot>
        </CommonWindowProvider>
      </CommonStateProvider>
    </>
  );
}

export default App;
